import React, { Component } from "react";
import AsunEstudiantiles from "../../../assets/images/Logo_Asuntos_Estudiantiles.png";
import Diversidad from "../../../assets/images/diversidad_ae.jpg";
import RelacionesInsAe from "../../../assets/images/relaciones_institucionales_ae.jpg";
import QualityEducationAe from "../../../assets/images/quality_education_ae.jpg";
import vinculosAe from "../../../assets/images/vinculos.jpg";
import MisionAe from "../../../assets/images/mision_ae.jpg";
import VisionAe from "../../../assets/images/vision_ae.jpg";
import MdlIntegradoRelacion from "../../../assets/images/Modelo_Integrado_Relacion.jpg";
import Compromiso from "../../../assets/images/compromiso.jpg";
import Pasion from "../../../assets/images/pasion.jpg";
import Generosidad from "../../../assets/images/generosidad.jpg";
import Humildad from "../../../assets/images/humildad.jpg";
import Empatia from "../../../assets/images/empatia.jpg";
import Autenticidad from "../../../assets/images/autenticidad.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const AsuntosEstudiantiles = () => {
  const options = {
    margin: 30,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Asuntos Estudiantiles
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider pt-4 pb-0 mb-3">
            <div className="card-body card-body-cascade px-0">
              <div className="row">
                <div className="col-md-8">
                  <div className="row mx-3 justificado">
                    La Unidad de Asuntos Estudiantiles, es creada con el fin de
                    prestar servicios al estudiante, en la cual se podrá
                    conseguir información asociada a: información sobre el
                    régimen académico, servicios sociales, asistenciales,
                    información general, orientación profesional, becas, ayudas
                    al estudio, empleabilidad, actividades culturales,
                    deportivas, orientación con el desarrollo de propuestas y
                    recomendaciones generales ante organismos del ámbito de
                    actuación en relación a la carrera del estudiante,
                    beneficios sobre Talleres internos y externos, bienestar
                    estudiantil, vinculación familiar, entre otros.
                  </div>
                  <div className="row mx-3 mt-3 justificado">
                    Dentro del alcance establecido, se busca contribuir al
                    desarrollo integral de nuestros estudiantes adscritos a las
                    diferentes Direcciones de Escuela de la Institución, así
                    como al fortalecimiento de la convivencia y promoción de un
                    ambiente colaborativo a través de la aplicación de diversas
                    estrategias que complementan su formación académica.
                  </div>
                  <div className="row mx-3 mt-3 justificado">
                    Esta Unidad, coordinada todo su Plan de Acción con las
                    Direcciones de Escuela, Dirección Académica y la Dirección
                    de Administración de la Institución, buscando afianzar los
                    vínculos entre el IPLA y nuestros estudiantes.
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <img className="w-100" src={AsunEstudiantiles} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_ae"></section>

      <section className="container ">
        <div className="card card-cascade wider py-4 mb-3">
          <div className="card-body card-body-cascade">
            <div className="row">
              <p className="display-5 fw-bolder">Objetivo General</p>
            </div>
            <div className="row">
              <p className="justificado">
                Coordinar la recopilación y difusión de información en el ámbito
                estudiantil, relacionados con los asuntos que perturban a los
                estudiantes a fin de promover acciones conjuntas en materia de:
                su régimen académico, servicios sociales, asistenciales,
                información, orientación profesional, becas, ayudas al estudio,
                empleabilidad, actividades culturales, deportivas, así como el
                desarrollo de propuestas​ y recomendaciones generales ante los
                organismos que tengan disposiciones en su ámbito de actuación.​
              </p>
              <p className="justificado">
                Entre los principales objetivos estratégicos destacan:
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-4">
            <OwlCarousel items={3} className="owl-theme" {...options}>
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Diversidad}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder sombra_texto2 text-center">
                    Diversidad
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Diversidad</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Orientar un estilo de convivencia y un sentido de proyecto
                      compartido, cuyo fundamento es la integración basada en la
                      diversidad
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={RelacionesInsAe}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">
                    Relaciones Institucionales
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Relaciones Institucionales</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Gestionar realaciones institucionales entre empresas, IPLA
                      y sus estudiantes, proponiendo politicas de trabajo y
                      participación estudiantil que fortalezcan su protagonismo
                      en la vida academica mediante un dialogo efectivo y
                      abierto.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={QualityEducationAe}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">
                    Calidad Educativa
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Calidad Educativa</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Producir servicios para mejorar la calidad de vida de los
                      estudiantes, en perspectiva de procurar la igualdad de
                      oportunidades en el acceso a satisfactores de necesidades
                      basicas de tipo economico, social y de salud, y colaborar
                      al otrogamiento de oportunidades para el desarrollo
                      personal de los estudiantes en los ámbitos sociales y
                      cultural.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={vinculosAe}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">Vinculos</h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Vinculos</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Generar las instancias necesrias entre los estudiantes,
                      profesores y comunidad IPLA en general, buscando afianzar
                      vinculos que nos unen para el logro de los objetivos.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-4">
          <div className="row">
            <div className="col-md-6">
              <h2 className="fw-bolder pt-md-3">Misión</h2>
              <br />
              <p className="justificado ms-auto">
                Promover el desarrollo en la formación integral de la comunidad
                estudiantil IPLA, prestar servicios al estudiante y ser impulsor
                en el desarrollo de competencias claves, que ayuden a fortalecer
                sus conocimientos para una proyección profesional acorde a las
                exigencias del mercado laboral.
              </p>
            </div>
            <div className="col-md-6 px-0">
              <div className="zoom d-flex align-items-end">
                <img
                  src={MisionAe}
                  alt=""
                  className="img-fluid w-100  img-mision"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 px-0">
              <div className="zoom d-flex align-items-start">
                <img
                  src={VisionAe}
                  alt=""
                  className="img-fluid w-100  img-mision"
                />
              </div>
            </div>
            <div className="col-md-6 mx-0">
              <h2 className="fw-bolder mx-md-0 pt-md-3">Visión</h2>
              <br />
              <p className="justificado">
                Ser una Unidad reconocida por su aporte al desarrollo integral
                de los estudiantes y soporte de las diferentes Direcciones de
                Escuelas, que promueve una sana convivencia y ambiente
                colaborativo como base esencial en la formación académica de los
                mismos.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_valores_ae"></section>

      <section>
        <div className="container">
          <div className="row pt-4">
            <p className="display-5 fw-bolder">Valores de la Unidad</p>
          </div>
          <div className="row py-4">
            <OwlCarousel items={3} className="owl-theme" {...options}>
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Autenticidad}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder sombra_texto2 text-center">
                    Autenticidad
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title fw-bolder my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Autenticidad</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Trabajamos nuestro día a día sobre la verdad, la
                      honestidad y la transparencia, ofreciendo una ardua labor
                      y de calidad, basada en los pilares sólidos de nuestro
                      equipo.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Humildad}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">Humildad</h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Humildad</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Sabemos que siempre se puede mejorar, luchamos por crecer,
                      aprendemos paso a paso para ofrecer el mejor servicio
                      posible al estudiante.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Empatia}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">Empatía</h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Empatía</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Ofrecemos cercanía y entera dedicación hacia las
                      necesidades de las personas que requieren nuestros
                      servicios estudiantiles.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Pasion}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">Pasión</h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title fw-bolder my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Pasión</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Trabajamos por lo que realmente sentimos, con la ilusión y
                      motivación, perseverando por alcanzar los objetivos y
                      metas que nos unen.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Compromiso}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">
                    Compromiso
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title fw-bolder my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Compromiso</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Nos comprometemos con nuestros colaboradores y
                      estudiantes, para brindar un apoyo ágil y de calidad.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={Generosidad}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder text-center">
                    Generosidad
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title fw-bolder my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Generosidad</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Realizamos nuestro trabajo de la mejor manera posible,
                      para satisfacer a nuestra comunidad estudiantil y
                      colaboradores.
                    </p>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <p className="display-5 fw-bolder">Modelo Integrado de Relación</p>
          </div>
          <div className="row">
            <p className="display-7 fw-bolder">
              (Admisión/Académico – Estudiantil – Vinculación con el Medio)
            </p>
          </div>
          <div className="row">
            <img
              src={MdlIntegradoRelacion}
              alt=""
              className="img-fluid w-100"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default AsuntosEstudiantiles;
