import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { Button, Col, Form, Row } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Select from "react-select";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import "./css/preinscripcion.css";

const PreInscripcion = () => {
  const baseURL = "http://51.81.213.64:3005/preinscripcion";
  const navigate = useNavigate();
  const [show, setShow] = useState(false); //asi se llama al modal

  const [files, setFiles] = useState([]);
  const [validated, setValidated] = useState(false);
  const [paises, setPaises] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [regiones, setRegiones] = useState("");
  const [comunas, setComunas] = useState("");

  /* configuracion para el arrastre de archivos 
  const dropArea = document.querySelector(".drop-area");
  const dragText = dropArea.querySelector("h2");
  const buttonDrop = dropArea.querySelector("button");
  const input = dropArea.querySelector("#input-file");

  buttonDrop.addEventListener("click", (e) => {
    input.onClick();
  });*/

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    let newFiles = e.dataTransfer.files;
    setFiles([...files, ...newFiles]);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const anios = [{ label: "1900", value: "1900" }];

  const carreras = [
    {
      label: "Ingeniería en Administración de Empresas Gestión RRHH",
      value: "Ingeniería en Administración de Empresas Gestión RRHH",
    },
    {
      label: "TNS en Administración de Empresas Gestión RRHH",
      value: "TNS en Administración de Empresas Gestión RRHH",
    },
    {
      label: "Psicopedagogía",
      value: "Psicopedagogía",
    },
    {
      label: "Servicio Social",
      value: "Servicio Social",
    },
    {
      label: "TNS en Educación Parvularia",
      value: "TNS en Educación Parvularia",
    },
    {
      label: "TNS en Trabajo Social",
      value: "TNS en Trabajo Social",
    },
    {
      label: "Ingeniería en Prevención de Riesgos y Medio Ambiente",
      value: "Ingeniería en Prevención de Riesgos y Medio Ambiente",
    },
    {
      label: "TNS en Prevención de Riesgos y Medio Ambiente",
      value: "TNS en Prevención de Riesgos y Medio Ambiente",
    },
  ];

  const parentesco = [
    { label: "Padre", value: "Padre" },
    { label: "Madre", value: "Madre" },
    { label: "Hermano(a)", value: "Hermano(a)" },
    { label: "Esposo(a)", value: "Esposo(as)" },
  ];

  useEffect(() => {
    //getPaises();
    //console.log(paises);
  }, []);

  const enviar = handleSubmit(async (data) => {
    console.log(data);
    //setFile(data.archivos);
    console.log(files);
    try {
      //console.log("entro en el try");
      await axios
        .get(`${baseURL}`, {
          params: {
            data,
            subject: "Preinscripción",
            attachments: files,
          },
        })
        .finally((response) => {
          Swal.fire({
            icon: "success",
            title: "Registro Enviado!!",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/sobre-admision");
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  });
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-4 text-white fw-bolder mx-5 ">
              Pre Inscripcion
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="row py-3 px-5">
            <h2 className="display-6 fw-bolder titulo">
              CONSIDERACIONES GENERALES:
            </h2>
          </div>

          <div className="card card-cascade wider mb-3 pt-3">
            <div className="card-body card-body-cascade">
              <div className="row">
                <p className="justificado">
                  Queremos darte la más cordial bienvenida a esta tu Casa de
                  Estudios Superiores;
                </p>
              </div>
              <div className="row">
                <p className="justificado">
                  En esta oportunidad, queremos tengas en consideración que como
                  nuevo alumno es necesario consideres lo siguiente:
                  <br />
                  <ul className="list-group list-group-flush mx-2">
                    <li className="list-group-item">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Lee detenidamente cada sección del formulario.
                      </span>
                    </li>
                    <li className="list-group-item">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Completa todos los campos con la información requerida,
                        de esta forma podrás tener un proceso de admisión
                        completo y evitarás problemas más adelante.
                      </span>
                    </li>
                    <li className="list-group-item py-3">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        La documentación requerida en esta primera etapa es
                        necesaria, por lo que te pedimos puedas conseguir
                        digitalmente antes de dar inicio el proceso, estos
                        documentos son:
                      </span>
                      <ul className="list-group list-group-flush mx-2">
                        <li className="list-group-item pb-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Licencia de enseñanza media.
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">Concentración de notas.</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Dos fotos tamaño carnet con nombres, apellidos y RUT
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Certificado de nacimiento (vigente).
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Fotocopia carnet de identidad (vigente).
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Certificado de residencia (vigente).
                          </span>
                        </li>
                      </ul>

                      <p className="justificado pt-2">
                        Todos estos documentos debes subirlos a la plataforma
                        digital en formato pdf, jpg, png.
                      </p>
                    </li>
                    <li className="list-group-item">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Si tienes dudas, puedes contactarnos a través de los
                        números telefónicos: +56 34 259 5600 o por el +56 9 9680
                        8457 (llamada y/o WhatsApp). También puedes enviar
                        correo solicitando ayuda a: admisión@ipla.cl
                      </span>
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 pb-3">
            <div className="card-body card-body-cascade">
              <form onSubmit={enviar}>
                <div className="row mx-1 pb-3">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="apellidoPaterno">Apellido Paterno</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("apellidoPaterno", { required: true })}
                        placeholder="Apellido Paterno"
                      />
                      {errors.apellidoPaterno && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="apellidoMaterno">Apellido Materno</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("apellidoMaterno", { required: true })}
                        placeholder="Apellido Materno"
                      />
                      {errors.apellidoPaterno && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="primerNombre">Primer Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("primerNombre", { required: true })}
                        placeholder="Primer Nombre"
                      />
                      {errors.apellidoPaterno && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="segundoNombre">Segundo Nombre</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("segundoNombre", { required: true })}
                        placeholder="Segundo Nombre"
                      />
                      {errors.apellidoPaterno && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mx-1 pb-3">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="apellidoPaterno">
                        Cedula de Identidad
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        {...register("cedula", { required: true })}
                        placeholder="Cedula de Identidad"
                      />
                      {errors.cedula && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="feNacimiento">Fecha de Nacimiento</label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("feNacimiento", { required: true })}
                        placeholder="Fecha de Nacimiento"
                      />
                      {errors.feNacimiento && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="pais">Pais de Nacimiento</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("pais", { required: true })}
                        placeholder="Pais"
                      />
                      {errors.pais && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="region">Region</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("region", { required: true })}
                        placeholder="Region"
                      />
                      {errors.region && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mx-1 pb-3">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="comuna">Comuna</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("comuna", { required: true })}
                        placeholder="Comuna"
                      />
                      {errors.comuna && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="direccion">Direccion Actual</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("direccion")}
                        placeholder="Direccion Actual"
                      />
                      {errors.direccion && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="correo">Correo Electronico</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("correo", { required: true })}
                        placeholder="Correo"
                      />
                      {errors.correo && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mx-1 pb-3">
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="telefono">Telefono de Contacto</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("telefono", { required: true })}
                        placeholder="Telefono de Contacto"
                      />
                      {errors.telefono && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="celular">Celular</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("celular", { required: true })}
                        placeholder="9 1234 5678"
                      />
                      {errors.carrera && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="familiar">
                        Indica un Familiar Directo
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("familiar", { required: true })}
                        placeholder="Apellido y Nombre"
                      />
                      {errors.familiar && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <div className="form-group">
                      <label htmlFor="parentesco">Parentesto</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("parentesco", { required: true })}
                        placeholder="Apellido y Nombre"
                      />
                      {errors.parentesco && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mx-1 pb-4">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label htmlFor="liceo">Liceo de Enseñanza media</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("liceo", { required: true })}
                        placeholder="Liceo de Enseñanza media"
                      />
                      {errors.liceo && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-2">
                    <div className="form-group">
                      <label htmlFor="anioegreso">Año de Egreso</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("anioegreso", { required: true })}
                        placeholder="1900"
                      />
                      {errors.anioegreso && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="comosupo">
                        ¿Cómo se enteró de Nosotros?
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("comosupo", { required: true })}
                        placeholder="¿Cómo se enteró de Nosotros?"
                      />
                      {errors.comosupo && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mx-1 pb-3">
                  <div className="col-lg-7">
                    <div className="form-group">
                      <label htmlFor="carreraelige">Carrera a Optar</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("carreraelige", { required: true })}
                      />
                      {errors.carreraelige && (
                        <span className="text-danger text-bold">
                          Campo requerido
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-4 offset-lg-1 pt-4">
                    <input
                      className="form-check-input pt-4"
                      type="checkbox"
                      id="recibir"
                      {...register("recibir")}
                    />
                    <label className="form-check-label" htmlFor="recibir">
                      ¿Solicita Beca IPLA?
                    </label>
                  </div>
                </div>

                <div className="row mx-1 pb-3">
                  <div className="col-lg-3 pt-4">
                    <input
                      className="form-check-input pt-4"
                      type="checkbox"
                      id="trabaja"
                      {...register("trabaja")}
                    />
                    <label className="form-check-label" htmlFor="trabaja">
                      ¿Actualmente Trabajas?
                    </label>
                  </div>

                  <div className="col-lg-5">
                    <div className="form-group">
                      <label htmlFor="empresa">Nombre de la Empresa</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("empresa")}
                        placeholder="Nombre de la Empresa"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2"></div>
                  <div className="col-lg-2 pt-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Cargar Documentos"
                      onClick={() => setShow(true)}
                    >
                      <i className="fas fa-paperclip"></i>
                    </button>
                  </div>
                </div>

                <div className="row mx-1 pb-4">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-4">
                    {/* <input
                      type="file"
                      class="form-control-file"
                      id="archivos"
                      {...register("archivos", { required: true })}
                      onChange={(e) => setFiles(e.target.files[0])}
                      multiple
                      hidden
                    /> */}
                  </div>
                  <div className="col-lg-2">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Enviar Información"
                    >
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        centered="true"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title name="example-custom-modal-styling-title">
            CARGA DE DOCUMENTACIÓN REQUERIDA
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <p>
                La documentación requerida en esta primera etapa es necesaria,
                por lo que te pedimos puedas conseguir digitalmente antes de dar
                inicio el proceso, estos documentos son:
              </p>

              <div className="row pb-3">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush mx-2">
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">
                        Licencia de enseñanza media
                      </small>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">Concentración de notas</small>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">
                        Dos fotos tamaño carnet con nombres, apellidos y RUT
                      </small>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul className="list-group list-group-flush mx-2">
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">
                        Certificado de nacimiento (vigente)
                      </small>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">
                        Fotocopia carnet de identidad (vigente)
                      </small>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <small className="ms-2">
                        Certificado de residencia (vigente)
                      </small>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="row">
                <p>
                  Todos estos documentos debes subirlos a la plataforma digital
                  en formato pdf, jpg, png
                </p>
              </div>

              <div className="row d-flex justify-content-center">
                {/* <div className="drog-area">
                  <h2>Arrastra y suelta los archivos</h2>
                  <span>o</span>
                  <button>Selecciona tus Archivos</button>
                  <input
                    type="file"
                    id="input-file"
                    className="input-file"
                    hidden
                    multiple
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  />
                </div> */}
              </div>
              <div className="preview"></div>
              <div className="row pt-2">
                <div className="col-md-2"></div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreInscripcion;
