import React from 'react'
import Banner from './layout/Banner'
import Programas from './Programas'
import Carreras from './Carreras'
import Testimonios from './Testimonios'
import Asociados from './Asociados'
import Preloader from '../components/loader/Preloader.jsx';

const Inicio = () => {
  return (
    <>
        <Preloader/>
        <main>
          <Banner/>
          <section className='pt-4'>
            <Programas/>
          </section>
          
          <section className='pt-4'>
            <Carreras/>     
          </section>

          <section className='pt-4'>
            <Testimonios/>
          </section>
          
          <section className='bg-light'>
            <Asociados/>
          </section>

        </main>
        
    </>
  )
}

export default Inicio