import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MapaEstrategico from "../../../assets/images/mapa-ESTRATEGICO.png";
import MapaProcesos1 from "../../../assets/images/procesos-ipla-001.png";
import MapaProcesos2 from "../../../assets/images/procesos-ipla-002.png";
import MapaProcesos3 from "../../../assets/images/procesos-ipla-003.png";
import ACalidad1 from "../../../assets/images/aseguramiento-calidad-001.png";
import ACalidad2 from "../../../assets/images/aseguramiento-calidad-002.png";
import ACalidad3 from "../../../assets/images/aseguramiento-calidad-003.png";
import ACalidad4 from "../../../assets/images/aseguramiento-calidad-004.png";
import ACalidad5 from "../../../assets/images/aseguramiento-calidad-005.png";
import ACalidad6 from "../../../assets/images/aseguramiento-calidad-006.png";
import PoliticasCalidad from "../../../assets/images/politicas_calidad.jpg";

const AseguramientoCalidad = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  const options2 = {
    margin: 10,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const options3 = {
    margin: 10,
    responsiveClass: true,
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  const DescargaPdf = () => {
    window.open("/data/Politica_de_Aseguramiento_de_la_Calidad.pdf", "_blank");
  };

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Aseguramiento de la Calidad
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-4">
        <div className="container">
          <OwlCarousel items={4} className="owl-theme" {...options}>
            <div class="card h-100">
              <iframe
                className="card-img-top"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/0cmA_bFqvG8?si=mitVkXqsUcMIWkxW"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="true"
              ></iframe>
              <div class="card-body">
                <h5 class="card-title fw-bolder titulo">
                  Politicas de Calidad
                </h5>
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <iframe
                className="card-img-top"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/NzKxxqDId54?si=95ybB42vJ8rdPY_I"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="true"
              ></iframe>
              <div class="card-body">
                <h5 class="card-title fw-bolder titulo">La Acreditación</h5>
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <iframe
                className="card-img-top"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/tg5IHjMIfDA?si=-EtWh00w2DfewBW-"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen="true"
              ></iframe>
              <div class="card-body">
                <h5 class="card-title fw-bolder titulo">
                  Sociabilización Acreditación
                </h5>
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src={MapaEstrategico} alt="" className="w-100" />
            </div>
            <div className="col-lg-6 d-flex align-items-end">
              <OwlCarousel items={3} className="owl-theme" {...options2}>
                <div class="card h-100">
                  <img src={MapaProcesos1} alt="" />
                  <div class="card-body">
                    <h5 class="card-title fw-bolder titulo">
                      Diagrama de Procesos
                    </h5>
                    {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
                  </div>
                </div>
                <div class="card h-100">
                  <img src={MapaProcesos2} alt="" />
                  <div class="card-body">
                    <h5 class="card-title fw-bolder titulo">
                      Control y Mejora Continua
                    </h5>
                    {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
                  </div>
                </div>
                <div class="card h-100">
                  <img src={MapaProcesos3} alt="" />
                  <div class="card-body">
                    <h5 class="card-title fw-bolder titulo">
                      Mapa de Contribución
                    </h5>
                    {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_adc"></section>

      <section className="pt-4">
        <div className="container">
          <OwlCarousel items={4} className="owl-theme" {...options3}>
            <div class="card w-100">
              <img src={ACalidad1} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <img src={ACalidad2} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <img src={ACalidad3} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <img src={ACalidad4} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <img src={ACalidad5} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>

            <div class="card h-100">
              <img src={ACalidad6} alt="" />
              <div class="card-body">
                {/*<p class="card-text">
                  <small class="fw-bolder subtitulo">
                    Last updated 3 mins ago
                  </small>
                </p>*/}
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="col-lg-3">
            <div class="row d-flex justify-content-center">
              <div class="view overlay rounded z-depth-2 px-0">
                <img
                  class="img-fluid"
                  src={PoliticasCalidad}
                  alt="Sample image"
                />
                <a>
                  <div class="mask rgba-white-slight"></div>
                </a>
              </div>
              <div class="row d-flex justify-content-center">
                <a
                  type="button"
                  class="btn-floating blue-gradient"
                  data-toggle="tooltip"
                  title=""
                  onClick={DescargaPdf}
                  data-original-title="Descargar"
                >
                  <i class="far fa-eye" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AseguramientoCalidad;
