import React from "react";
import SocialEducation from "../assets/images/social-education.jpg";
import AdminComercio from "../assets/images/administracion-comercio.jpg";
import ProcIndustriales from "../assets/images/procesos-industriales.jpg";
import CiberSecurity from "../assets/images/ciber-security.jpg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Carreras = () => {
  return (
    <>
      <div className="row text-center py-5">
        <dir className="display-6 fw-bolder text-uppercase">
          nuestras escuelas de formación
        </dir>
      </div>

      <div className="container">
        <div className="row pb-3">
          <div className="col-lg-3 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <img
                src={SocialEducation}
                className="card-img-top"
                alt="card-grid-image"
              />
              <div className="card-img"></div>
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <NavLink
                    to="/escuela-educacion"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <img
                src={AdminComercio}
                className="card-img-top"
                alt="card-grid-image"
              />
              <div className="card-img"></div>
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <NavLink
                    to="/escuela-administracion"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <img
                src={ProcIndustriales}
                className="card-img-top"
                alt="card-grid-image"
              />
              <div className="card-img"></div>
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <NavLink
                    to="escuela-procesos-industriales"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <img
                src={CiberSecurity}
                className="card-img-top"
                alt="card-grid-image"
              />
              <div className="card-img"></div>
              <div className="card-body">
                <div className="d-flex justify-content-end">
                  <NavLink
                    to="/escuela-tecnologia"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const cortarPalabras = styled.p`
  max-width: 150px;
`;

export default Carreras;
