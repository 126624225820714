import React from 'react'
import TechnologyApply from '../../../assets/images/Cards_technology.jpg'

const EscuelaTecnologia = () => {
  return (
    <>
        <section className='d-flex banner_pages banner_nuestro_instituto'>
            <div className="row">
                <div className="col-md-2 align-self-center">
                    <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
                </div>    
                <div className="col-md-10 align-self-center">
                    <div className=" display-2 text-white fw-bolder mx-5 ">Escuela de Tecnología</div>
                </div>
            </div>
            <div className="row"></div>
        </section> 

        <section className='py-5'>
            <div className="container">
                <div className="row">
                    <h2 className="display-5 fw-bolder titulo">Escuela de Tecnología Aplicada</h2>
                </div>

                <div className="row pt-2">
                    <p className="justificado">La Escuela de Tecnología Aplicada forma a profesionales en tecnología de la información, mediante diversas 
                        herramientas que buscan desarrollar e implementar proyectos a través del conocimiento y la capacitación. Se enfoca principalmente, 
                        en entregar a los estudiantes capacidades que apuntan de forma directa hacia el campo laboral en: Informática, Computación, 
                        Ciberseguridad, entre otros.
                    </p>
                </div>

                <div className="row py-5">
                    <div className="col-md-6">
                        <div className="card card-cascade narrower h-100">
                            <div className="view view-cascade overlay">
                                <img className="card-img-top" src={TechnologyApply} alt="Card image cap" />
                                <a>
                                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                                </a>
                            </div>
                            <div className="card-body card-body-cascade">
                                <h4 className="card-title fw-bolder subtitulo text-center">Carreras Técnicas</h4>
                                <hr/>
                                <div className="d-flex justify-content-end">
                                    <a type="button" className="btn-floating teal btn-primary"><i className="activator fas fa-angle-double-up" aria-hidden="true" title="Ver Mas"></i></a>
                                </div>
                            </div>
                            <div className="card-reveal white overlay">

                                {/*<!-- Content -->*/}
                                <div className="content">
                                    
                                    <h4 className="card-title fw-bolder my-4 text-center"><i className="fas fa-times text-muted"></i><small>Carreras Técnicas</small></h4>
                                    <hr/>
                                    <ul className="list-group list-group-flush mx-2">
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Informática</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Computación</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Analista Programador Computacional</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Ciberseguridad</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Administración de Redes</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Automatización y Control</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>TNS en Diseño Gráfico</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 py-xs-4">
                        <div className="card card-cascade narrower h-100">
                            <div className="view view-cascade overlay">
                                <img className="card-img-top" src={TechnologyApply} alt="Card image cap" />
                                <a>
                                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                                </a>
                            </div>
                            <div className="card-body card-body-cascade">
                                <h4 className="card-title fw-bolder subtitulo text-center">Carreras Profesionales</h4>
                                <hr/>
                                <div className="d-flex justify-content-end">
                                    <a type="button" className="btn-floating teal btn-primary"><i className="activator fas fa-angle-double-up" aria-hidden="true" title="Ver Mas"></i></a>
                                </div>
                            </div>
                            <div className="card-reveal white overlay">

                                {/*<!-- Content -->*/}
                                <div className="content">
                                    
                                    <h4 className="card-title fw-bolder my-4 text-center"><i className="fas fa-times text-muted"></i><small>Carreras Profesionales</small></h4>
                                    <hr/>
                                    <ul className="list-group list-group-flush mx-2">
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>ING en Informática</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>ING en Computación</span></li>
                                        <li className="list-group-item"><i className="fas fa-check mr-2"></i><span className='ms-2'>ING en Sistemas</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
  )
}

export default EscuelaTecnologia