import React from "react";
import { NavLink } from "react-router-dom";

const PlanCovid = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Plan de Accion Covid
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider pt-4 pb-0 mb-3">
            <div className="card-body card-body-cascade px-0">
              <div className="row mx-3 justificado">
                <p>
                  El SARS-CoV-2 es una cepa de la familia de coronavirus que no
                  se había identificado previamente en humanos. Es el nombre
                  definitivo otorgado por la OMS.
                </p>
              </div>
              <div className="row mx-3 justificado">
                <p>
                  Los coronavirus son causantes de enfermedades que van desde el
                  resfriado común hasta enfermedades más graves, como
                  Insuficiencia Respiratoria Aguda Grave.
                </p>
              </div>
              <div className="row mx-3 justificado titulo">
                <h4 className="fw-bolder pt-md-3">¿Cómo se contagia?</h4>
              </div>

              <div className="row mx-3 justificado">
                <p>
                  El virus se transmite de persona a persona cuando tiene
                  contacto cercano con un enfermo. Por ejemplo, al vivir bajo el
                  mismo techo, compartir la misma sala en un hospital, viajar
                  por varias horas en un mismo medio de transporte, o cuidar a
                  un enfermo sin la debida medida de protección.
                </p>
              </div>

              <div className="row mx-3 justificado titulo">
                <h4 className="fw-bolder pt-md-3">¿Cuáles son los síntomas?</h4>
              </div>

              <div className="row mx-3 justificado">
                <ul className="list-group list-group-flush small">
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2 ">Fiebre sobre 37,8° grados</span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">Tos, congestión nasal</span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">Debilidad general o fatiga</span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">Dificultad respiratoria</span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">
                      Dolor corporal, de cabeza o garganta
                    </span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">Diarrea o vómitos</span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">
                      Aumento de frecuencia respiratoria
                    </span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">Pérdida del gusto u olfato</span>
                  </li>
                </ul>
              </div>

              <div className="row mx-3 justificado pt-3">
                <p>
                  Consulta antes de que pasen 24 horas desde que presentas
                  síntomas. Aíslate de inmediato, usa mascarillas y no vayas a
                  trabajar o al colegio. Acude al centro asistencial más cercano
                  y testéate.
                </p>
              </div>

              <div className="row mx-3 justificado titulo">
                <h4 className="fw-bolder pt-md-3">
                  ¿Existe tratamiento para COVID-19?
                </h4>
              </div>

              <div className="row mx-3 justificado">
                <p>
                  No existe en la actualidad tratamiento específico. El
                  tratamiento es solo de apoyo y depende del estado clínico del
                  paciente y está orientado a aliviar los síntomas.
                </p>
              </div>

              <div className="row mx-3 justificado">
                <p>
                  Para mayor información dirijase al sitio web del Ministerio de
                  Salud, haciendo click en el siguiente enlace{" "}
                  <NavLink to="https://www.minsal.cl/covid-19">
                    minsal.cl
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlanCovid;
