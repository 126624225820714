import React, { useState } from "react";
import imagenMDE from "../../../assets/images/logo_ministerio_e.png";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";

const SobreAdmision = () => {
  const [show, setShow] = useState(false); //asi se llama al modal

  const [formValues, setFormValues] = useState({
    Names: "",
    Apellidos: "",
    Telefono: "",
    Rut: "",
    Email: "",
  });

  //estado del boton, por defecto true
  const [habilitado, setHabilitado] = useState("Typing");

  //se optienen el valor de los input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //envio de informacion del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    setHabilitado("submitted");
  };

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-4 text-white fw-bolder mx-5 ">
              Admisión
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 py-4">
            <div className="card-body card-body-cascade">
              <div className="row">
                <p className="justificado">
                  El Instituto Profesional Libertador de Los Andes cuenta con
                  beca y crédito directo IPLA, además de convenios con
                  instituciones. Quienes quieran inscribirse en alguna de las
                  carreras ya sean profesionales o técnicas de nivel superior
                  deben presentar los siguientes documentos:
                </p>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <ul className="list-group list-group-flush mx-2">
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">Licencia de enseñanza media</span>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">Concentración de notas</span>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">
                        Dos fotos tamaño carnet con nombres, apellidos y RUT
                      </span>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">
                        Certificado de nacimiento (vigente)
                      </span>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">
                        Fotocopia carnet de identidad (vigente)
                      </span>
                    </li>
                    <li className="list-group-item">
                      <i className="fas fa-check mr-2"></i>
                      <span className="ms-2">
                        Certificado de residencia (vigente)
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="col-md-3">
                  <div className="row pt-2">
                    <h5 className="fw-bolder">Horarios de Atención:</h5>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">Lunes a Viernes</p>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">10:00 a 14:00 Horas</p>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">15:30 a 19:30 Horas</p>
                  </div>
                  <div className="row pt-2">
                    <h5 className="fw-bolder">Contacto:</h5>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">admisión@ipla.cl</p>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">+56 34 259 5600</p>
                  </div>
                  <div className="row py-0 my-0">
                    <p className="my-0">+56 9 9680 8457</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row d-flex justify-content-center">
                    <img className="w-50" src={imagenMDE} alt="" />
                  </div>
                  <div className="row d-flex justify-content-center pt-3">
                    <p className="text-center">
                      Modelo Educativo basado en competencias
                    </p>
                  </div>
                </div>
              </div>

              <div className="row py-3">
                <div className="col-md-6">
                  <h5 className="fw-bolder d-flex justify-content-center">
                    Preinscríbete así:
                  </h5>
                  <ul className="list-group list-group-flush mx-2">
                    <li className="list-group-item">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Llenar la planilla de Preinscripción vía web a través
                        del siguiente Link&nbsp;&nbsp;
                        <NavLink to="/sobre-admision/preinscripcion">
                          <span className="fas fa-external-link-alt fa-xs subtitulo">
                            &nbsp;Formulario de Preinscripción
                          </span>
                        </NavLink>
                      </span>
                    </li>
                    <li className="list-group-item py-3">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Realizar transferencia bancaria a nombre del IPLA, RUT
                        88.502.100-K, por concepto de preinscripción. Datos
                        Bancarios:
                      </span>
                      <ul className="list-group list-group-flush mx-2">
                        <li className="list-group-item pb-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">
                            Banco Santander (www.Santander.cl/)
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">Cuenta Corriente</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-square mr-2 fa-xs"></i>
                          <span className="ms-2">7389908-7 ó 9500494-6</span>
                        </li>
                      </ul>
                    </li>
                    <li className="list-group-item">
                      <i className="far fa-check-square mr-2"></i>
                      <span className="ms-2">
                        Luego de haber llenado planilla de preinscripción debe
                        consignar la misma junto con el váucher de pago, copia
                        de la cédula de identidad, enviando un correo
                        electrónico a admisión@ipla.cl y en un plazo de 48 horas
                        hábiles para su correspondiente validación.
                      </span>
                    </li>
                  </ul>
                </div>

                <div className="col-md-6">
                  <div className="card">
                    <div className="card-header color_header_cards">
                      <h5 className="titulo d-flex justify-content-center fw-bolder aling-items-center pt-2">
                        Períodos
                      </h5>
                    </div>
                    <div className="card-body mx-0 px-0">
                      <div className="row px-3">
                        <h5 className="fw-bolder d-flex justify-content-center">
                          Marzo - Agosto
                        </h5>
                      </div>
                      <div className="row px-3 pb-3">
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush mx-2">
                            <li className="list-group-item pb-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 1 - Marzo</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 2 - Abril</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 3 - Mayo</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush mx-2">
                            <li className="list-group-item pb-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 4 - Junio</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 5 - Julio</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 6 - Agosto</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <hr className="py-0 my-0" />

                      <div className="row px-3 pt-3">
                        <h5 className="fw-bolder d-flex justify-content-center">
                          Septiembre - Febrero
                        </h5>
                      </div>

                      <div className="row px-3 pb-3">
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush mx-2">
                            <li className="list-group-item pb-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 1 - Septiembre</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 2 - Octubre</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 3 - Noviembre</span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-md-6">
                          <ul className="list-group list-group-flush mx-2">
                            <li className="list-group-item pb-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 4 - Diciembre</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 5 - Enero</span>
                            </li>
                            <li className="list-group-item py-0">
                              <i className="fas fa-check mr-2 fa-xs"></i>
                              <span className="ms-2">Cuota 6 - Febrero</span>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row px-3">
                        <small className="justificado">
                          Los montos por cuota dependerán de la carrera, tiempo
                          de pago, entre otros.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-4 ">
                <h5 className="fw-bolder d-flex justify-content-center">
                  Formas de Pago
                </h5>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5 className="fw-bolder d-flex justify-content-center py-3">
                        De Contado
                      </h5>
                      <div className="row">
                        <p className="justificado">
                          Depositar en cualquiera de los bancos autorizados en
                          efectivo; tarjeta de débito o tarjeta de crédito (Visa
                          y MasterCard), el pago total del valor del Período
                          Académico de acuerdo a lo estipulado en el contrato.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="fw-bolder d-flex justify-content-center py-3">
                        Por Cuotas (Financiado sin interés)
                      </h5>
                      <div className="row">
                        <p className="justificado">
                          El IPLA, dispone para sus alumnos de un financiamiento
                          sin intereses del costo de los estudios, mediante el
                          pago de una inicial o Matrícula y un saldo restante:
                          dividido en 6 cuotas consecutivas (una cada mes), que
                          se cancelarán según el cronograma de pago estipulado
                          en el contrato.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SobreAdmision;
