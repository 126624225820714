import './App.css';
import { useEffect, useState } from 'react';
import { RouterPrincipal } from './routers/RouterPrincipal.jsx';


function App() {


  return (
    <>
      
      <RouterPrincipal/>
    </>
  );
}

export default App;
