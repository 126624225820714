import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Menu from "../../assets/dataMenu.js";
import "./css/header.css";

const HeaderNav = () => {
  const [headerClassName, setHeaderClassName] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // window.onscroll = handleScroll
  }, []);

  const handleScroll = () => {
    //console.log("scrolling", window.pageYOffset);
    //console.log("menu_scrolled", headerClassName);
    if (headerClassName !== "menu_scrolled" && window.pageYOffset >= 56) {
      //console.log("activa el color del nav");
      setHeaderClassName("menu_scrolled");
    } else if (headerClassName === "menu_scrolled" && window.pageYOffset < 56) {
      //console.log("aclara el color del nav");
      setHeaderClassName("");
    }
  };

  const DescargaPdf = () => {
    window.open(
      "/data/CALENDARIO_ACADEMICO_I_SEMESTRE_ANIO_2024.pdf",
      "_blank"
    );
  };

  useEffect(() => {
    window.onscroll = handleScroll;
    //console.log()
  }, [headerClassName]);

  return (
    <>
      <header className="nav_fijo">
        <div className="top-bar">
          <ul className="nav blue-grey darken-1 justify-content-end">
            <li className="nav-item">
              <a
                className="nav-link text-white py-1"
                aria-current="page"
                href="https://wa.me/56996808457"
              >
                <i class="fab fa-whatsapp"></i> Contacto WhatsApp
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white py-1"
                aria-current="page"
                href="https://iplacampusvirtual.cl/moodle/"
              >
                <i className="fas fa-user"></i> Campus Virtual
              </a>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-white py-1"
                aria-current="page"
                to="/red-egresados"
              >
                <i className="fas fa-graduation-cap"></i> Egresados
              </NavLink>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white py-1"
                href="https://www.bpdigital.cl/?locale=es"
              >
                <i className="fas fa-book"></i> Bibloteca
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white py-1"
                href="https://site.q10.com/login?aplentId=6eae143c-a569-41ca-926e-ca193ca85824"
              >
                <i className="fas fa-globe"></i> Intranet
              </a>
            </li>
          </ul>
        </div>

        <div className={`container__menu ${headerClassName}`}>
          <div className="menu">
            <input type="checkbox" name="check__menu" id="check__menu" />
            <label htmlFor="check__menu" id="label__check">
              <i className="fas fa-bars icon__menu"></i>
            </label>
            <nav>
              <ul>
                <li>
                  <a href="/" id="seleccionado"></a>
                </li>
                <li>
                  <a href="#">Instituto</a>
                  <ul>
                    <li>
                      <NavLink to="/sobre-nosotros">Sobre Nosotros</NavLink>
                    </li>
                    <li>
                      <NavLink to="/nuestra-historia">Nuestra historia</NavLink>
                    </li>
                    <li>
                      <NavLink to="/nuestros-docentes">Nuestra Gente</NavLink>
                    </li>
                    <li>
                      <NavLink to="/mision-vision-valores">
                        Mision, Visión y Valores
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/nuestra-gente">
                        Estructruta Jerarquica
                      </NavLink>
                    </li>
                    {/*<li><a href="">Nuestros Fundadores</a></li>*/}
                    <li>
                      <NavLink to="/aseguramiento-de-la-calidad">
                        Aseguramiento de la calidad
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Escuelas</a>
                  <ul>
                    <li>
                      <NavLink to="/escuela-educacion">
                        ESCUELA DE EDUCACIÓN Y CIENCIAS SOCIALES
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/escuela-procesos-industriales">
                        ESCUELA DE PROCESOS INDUSTRIALES
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/escuela-administracion">
                        ESCUELA DE ADMINISTRACIÓN Y COMERCIO
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="escuela-tecnologia">
                        ESCUELA DE TECNOLOGÍA APLICADA
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Estudiantes</a>
                  <ul>
                    <li>
                      <a href="https://iplacampusvirtual.cl/moodle/">
                        Aula Virtual
                      </a>
                    </li>
                    <li>
                      <NavLink to="/asuntos-estudiantiles">
                        Asuntos Estudiantiles
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/red-egresados">Red de Egresados</NavLink>
                    </li>
                    <li>
                      <NavLink onClick={DescargaPdf}>
                        Calendario Academico
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Admisión</a>
                  <ul>
                    <li>
                      <NavLink to="/sobre-admision"> sobre Admisión</NavLink>
                    </li>
                    <li>
                      <a href="">Matricula</a>
                    </li>
                    <li>
                      <NavLink to="/sobre-admision/becas-ipla">
                        Becas Ipla
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Mas</a>
                  <ul>
                    <li>
                      <a href="">Lo mas Actual</a>
                    </li>
                    <li>
                      <a href="">Noticias & Eventos</a>
                    </li>
                    <li>
                      <a href="">Tendencia</a>
                    </li>
                    <li>
                      <a href="">Vinculacion con el Medio</a>
                    </li>
                    <li>
                      <NavLink to="/covid19">Plan COVID-19</NavLink>
                    </li>
                    <li>
                      <NavLink to="/empleos">Empleos</NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderNav;
