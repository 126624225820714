import React from "react";
import Iso9000 from "../../../assets/images/certificacionISO.png";
import Rector from "../../../assets/images/rector.jpg";

const SobreNosotros = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto ">
        <div className="row">
          <div className="col-lg-2 align-self-lg-center ">
            <i className="fas fa-shapes fa-4x-lg text-white mx-5 "></i>
          </div>
          <div className="col-lg-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Nuestro Instituto
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 py-4">
            <div className="card-body card-body-cascade">
              <div className="row">
                <div className="col-md-8">
                  <div className="row mx-3 justificado">
                    El Instituto Profesional Libertador de Los Andes es una
                    institución de Educación Superior que fue autorizada a
                    funcionar el 22 de diciembre de 1981 por el Ministerio de
                    Educación mediante el Decreto Exento nº 292 el que fue
                    actualizado el 08 de junio de 1993. Siendo el primer
                    instituto de Educación Superior en ser fundado a nivel
                    regional y el segundo a nivel país.
                  </div>
                  <div className="row mx-3 mt-3 justificado">
                    El 27 de junio de 2003, el IPLA obtuvo la total autonomía
                    para otorgar toda clase de títulos profesionales conforme a
                    la legislación que lo rige.
                  </div>
                  <div className="row mx-3 mt-3">
                    A comienzos del 2013, el IPLA implementó acciones de
                    colaboración con universidades a nivel nacional.
                  </div>
                  <div className="row mx-3 mt-3">
                    En 2014, el IPLA obtuvo la Certificación a la Gestión
                    Institucional por las normas ISO 9001:2008.
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <img src={Iso9000} alt="" />
                </div>
              </div>
              <div className="row py-5">
                <hr />
              </div>
              <div className="row">
                <div className="col-md-4 ps-4">
                  <div>
                    <img
                      src={Rector}
                      className="row post_nuestra_gente w-100"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="row mx-3">Estimada comunidad educativa:</div>
                  <div className="row mx-3 mt-3 justificado">
                    Quisiera darles una especial bienvenida al Instituto
                    Profesional Libertador de los Andes a todos nuestros
                    alumnos, que integran las diferentes carreras que
                    impartimos, en el IPLA encontrarán el espacio necesario para
                    concretar su Formación Técnica y Profesional, a través de un
                    proceso dinámico, orientado al logro, bajo la supervisión y
                    tutoría de docentes con amplia experiencia en la empresa
                    pública y privada y también en nuestra institución.
                  </div>
                  <div className="row mx-3 mt-3 justificado">
                    Cada periodo que comienza representa un gran desafío para
                    todos nosotros, al inicio de un nuevo semestre académico en
                    modalidad semipresencial y online, en una etapa de
                    crecientes y grandes dificultades económicas por las que
                    atraviesa todo el país, queremos decirles, que igualmente
                    estamos comprometidos en desarrollar y mantener los canales
                    presenciales, virtuales y online en forma abierta, para que
                    puedan recibir una prestación de todos los servicios
                    educativos que tenemos en esta su casa de estudios, con el
                    fin único de lograr el anhelado objetivo de formación de
                    nuestros alumnos, y así, poder convertirlos en excelentes
                    profesionales y mejores seres humanos.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SobreNosotros;
