import React from "react";
import Fachada from "../../../assets/images/Fachada_instituto.jpg";
import Perfil from "../../../assets/images/perfil.jpg";
import PerfilLuto from "../../../assets/images/perfil_luto.jpg";

const NuestraHistoria = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-lg-center">
            <i className="fas fa-shapes fa-lg text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-lg-center">
            <div className=" display-2 text-white fw-bolder mx-lg-5 ms-sm-2">
              Nuestra Historia
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 py-4">
            <div className="card-body card-body-cascade mx-0">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={Fachada}
                    className="img-fluid shadow rounded"
                    alt=""
                  />
                  <p className="pt-1 fw-bolder text-center">
                    <small translate="">
                      Fundada el 22 de Diciembre de 1982
                    </small>
                  </p>
                </div>
                <div className="col-md-8">
                  <div className="row mx-3 justificado">
                    El IPLA, nace en los inicios de la década de los años 80,
                    siendo creado por la necesidad de contar con una institución
                    de formación profesional para las provincias de Los Andes,
                    San Felipe y Petorca.
                  </div>
                  <div className="row mx-3 justificado mt-3">
                    Abrió sus puertas atendiendo los requerimientos de la época
                    de calificación profesional de un conjunto de personas
                    autorizadas para ejercer la docencia en los establecimientos
                    educacionales, sin contar con el título profesional
                    respectivo, tanto en el nivel de la Educación Básica como en
                    la Educación Parvularia. Al mismo tiempo, se posibilitó a
                    los egresados de los cuartos medios de los establecimientos
                    educacionales de la zona, acceder a carreras profesionales
                    de nivel superior en su comunidad.
                  </div>

                  <div className="row mx-3 justificado mt-3">
                    El IPLA se convirtió así en 1981, en el segundo instituto en
                    impartir Educación Superior de todo el país y el primero en
                    la V Región. Todo un hito para el Valle de Aconcagua.
                  </div>

                  <div className="row mx-3 justificado mt-3">
                    La historia de cuarenta años en la formación de
                    profesionales ha marcado al IPLA y lo ha llevado a
                    consolidarse como una institución reconocida por sus
                    egresados y trayectoria, la que, siempre evolucionando con
                    los tiempos, ha logrado mantener su mirada puesta en el
                    futuro, asumiendo los cambios en el hoy, siempre con la
                    misma dirección “entregar profesionales y técnicos de nivel
                    superior de la más alta calidad para el servicio del país”.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="h1 fw-bolder">NUESTROS FUNDADORES</p>
          </div>
          <div className="row py-4">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card_sombra h-100">
                <img
                  src={Perfil}
                  className="card-img-top"
                  alt="card-grid-image"
                />
                <div className="card-img-overlay"></div>
                <div className="card-body">
                  <p className="card-text justificado h4">
                    D. José Luengo Sepúlveda
                  </p>
                  <p className="card-text">
                    Representante Legal – Socio Fundador
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card_sombra h-100">
                <img
                  src={Perfil}
                  className="card-img-top"
                  alt="card-grid-image"
                />
                <div className="card-img-overlay"></div>
                <div className="card-body">
                  <p className="card-text justificado h4">D. Oscar Soto</p>
                  <p className="card-text">
                    Representante Legal – Socio Fundador
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card_sombra h-100">
                <img
                  src={PerfilLuto}
                  className="card-img-top"
                  alt="card-grid-image"
                />
                <div className="card-img-overlay"></div>
                <div className="card-body">
                  <p className="card-text justificado h4">D. Luis Sepúlveda</p>
                  <p className="card-text">
                    Representante Legal – Socio Fundador
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NuestraHistoria;
