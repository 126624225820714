import React, { useEffect, useState } from "react";
import { colaboradores } from "./data/DataColaboradores";

const RedAsociados = () => {
  const [datos, setDatos] = useState("");

  /* const recibeDatos = async () => {
        let response = await fetch(DataRedAsociados)
        let data = await response.json()
        console.log(data)

    }*/

  useEffect(() => {
    //recibeDatos()
    console.log(colaboradores);
  }, []);
  //console.log(redColaboradores)

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Red de Colaboradores
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          {colaboradores.map((elementos) => (
            <div className="card card-cascade wider pt-4 pb-0 mb-3">
              <div className="card-body card-body-cascade px-0">
                <div className="container">
                  <div className="d-flex justify-content-start pb-2">
                    <img
                      className="imagenes_asociados"
                      src={require(`../../../assets/images/${elementos.imagen}`)}
                      alt=""
                    />
                  </div>
                  <div className="row">
                    <h5 className="fw-bolder">{elementos.beneficio}</h5>
                  </div>

                  <div className="row">
                    {elementos.informacion.map((items) => (
                      <p className="justificado">{items.parrafo}</p>
                    ))}
                  </div>

                  <div className="row">
                    <h7 className="fw-bolder">
                      Estado Actual del convenio:{" "}
                      <span className="text-success fw-bolder">
                        {elementos.estado}
                      </span>
                    </h7>
                  </div>
                  {elementos.contacto.map((contac) => (
                    <div className="row">
                      <p className="fw-bolder mb-0">
                        Contacto: {contac.nombre} <br />
                        <small>
                          <i class="far fa-building"></i> {contac.cargo}
                        </small>{" "}
                        <br />
                        <small>
                          <i class="fas fa-envelope"></i> {contac.correo}
                        </small>{" "}
                        <br />
                        <small>
                          <i class="fas fa-phone"></i> {contac.telefono}
                        </small>{" "}
                        <br />
                        <small>
                          <i class="fab fa-whatsapp"></i> {contac.whatsapp}
                        </small>
                        <br />
                      </p>
                    </div>
                  ))}

                  <div className="row">
                    <small className="fw-bolder text-primary">
                      Incluye Oportunidad de: {elementos.oportunidad}
                    </small>
                  </div>

                  <div className="row">
                    <small>
                      <i class="fas fa-globe"></i> {elementos.paginaweb}
                    </small>
                  </div>
                  {elementos.direccion.map((direcc) => (
                    <div className="row">
                      <small>
                        <i class="fas fa-map-marked-alt"></i> {direcc.lugar}
                      </small>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default RedAsociados;
