import React from "react";
import EscEducation from "../../../assets/images/Cards_Escuelas_educations.jpg";
import { NavLink } from "react-router-dom";

const EscuelaEducacion = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Escuela de Educación
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row">
            <h2 className="display-5 fw-bolder titulo">
              Escuela De Educación y Ciencias Sociales
            </h2>
          </div>

          <div className="row pt-2">
            <p className="justificado">
              La Escuela de Educación y Ciencias Sociales, tiene como propósito
              formar estudiantes en carreras técnicas en áreas de educación de
              párvulos y trabajo social, así como carreras a nivel profesional
              tales como Psicopedagogía y Asistente Social, con los estándares
              de calidad requeridos en las políticas educativas nacionales, y
              con los conocimientos y habilidades necesarias para desarrollarse
              profesionalmente.
            </p>
          </div>

          <div className="row py-5">
            <div className="col-md-6">
              <div className="card card-cascade narrower h-100">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={EscEducation}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder subtitulo text-center">
                    Carreras Técnicas
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content">
                    <h4 className="card-title fw-bolder my-4 text-center">
                      <i className="fas fa-times text-muted"></i>
                      <small>Carreras Técnicas</small>
                    </h4>
                    <hr />
                    <ul className="list-group list-group-flush mx-2">
                      <NavLink to="/escuela-educacion/TNS-educacion-parvularia">
                        <li className="list-group-item">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            TNS en Educación Parvularia
                          </span>
                        </li>
                      </NavLink>
                      <NavLink to="/escuela-educacion/TNS-asistente-educacion-administracion">
                        <li className="list-group-item">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            TNS en Asistente de la Educación en Aula y
                            Administración
                          </span>
                        </li>
                      </NavLink>

                      <NavLink to="/escuela-educacion/TNS-trabajo-social">
                        <li className="list-group-item">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            TNS en Trabajo Social
                          </span>
                        </li>
                      </NavLink>

                      <li className="list-group-item">
                        <i className="fas fa-check mr-2"></i>
                        <span className="ms-2">
                          TNS en Traductor Inglés – Español
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 py-xs-4">
              <div className="card card-cascade narrower h-100">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={EscEducation}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <h4 className="card-title fw-bolder subtitulo text-center">
                    Carreras Profesionales
                  </h4>
                  <hr />
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content">
                    <h4 className="card-title fw-bolder my-4 text-center">
                      <i className="fas fa-times text-muted"></i>
                      <small>Carreras Profesionales</small>
                    </h4>
                    <hr />
                    <ul className="list-group list-group-flush mx-2">
                      <NavLink to="/escuela-educacion/Psicopedagogia">
                        <li className="list-group-item">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            Psicopedagogía
                          </span>
                        </li>
                      </NavLink>
                      <NavLink to="/escuela-educacion/Servicio-Social">
                        <li className="list-group-item">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            Servicio Social
                          </span>
                        </li>
                      </NavLink>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EscuelaEducacion;
