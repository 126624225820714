import React from "react";
import PresencialIcon from "../../src/assets/images/presencial.svg";
import OnlineIcon from "../assets/images/online.svg";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Programas = () => {
  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="row text-center pb-5">
            <dir className="display-6 fw-bolder text-uppercase">
              Formamos el futuro, únete a nuestra comunidad ipla
            </dir>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <div className="card-header text-center">
                <strong className="">
                  Programa de Iniciación Académica (P.I.A.)
                </strong>
              </div>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <p className="card-text text-justify">
                  El estudiante nuevo ingreso que desee estudiar en el
                  Instituto, deberá haber aprobado el PIA (Programa de
                  Iniciación Académica) como un requisito indispensable antes de
                  realizar la inscripción formal del semestre.
                </p>
                <p className="card-text text-justify">
                  Es un programa de formación educativa, destinado a todos los
                  estudiantes que deseen estudiar en el IPLA. Con la finalidad
                  de refrescar y fortalecer sus conocimientos en las diversas
                  áreas académicas.
                </p>
                <div className="d-flex justify-content-end">
                  <NavLink
                    to="/iniciacion-academica"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 pb-md-3 pb-sm-3">
            <div className="card card_sombra h-100">
              <div className="card-header text-center">
                <strong className="">
                  Modalidad de Estudios Horarios de clases
                </strong>
              </div>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <div className="row">
                  <div className="col-md-6 pr-0">
                    <div className="row">
                      <div className="col-md-4">
                        <Presencial className="pr-0">
                          {/*<img className='' src={PresencialIcon} alt="" />*/}
                          <i className="fas fa-chalkboard-teacher fa-2x"></i>
                        </Presencial>
                      </div>
                      <div className="col-md-8 px-0 d-flex align-items-center">
                        <a
                          className="text-decoration-none text-dark px-0"
                          href=""
                        >
                          <span>Presencial / Virtual</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-4 d-flex justify-content-end">
                        <Presencial className="pr-0">
                          {/*<img className='' src={OnlineIcon} alt="" />*/}
                          <i className="fas fa-desktop fa-2x "></i>
                        </Presencial>
                      </div>
                      <div className="col-md-8 px-0 d-flex align-items-center">
                        <a
                          className="text-decoration-none text-dark px-0"
                          href=""
                        >
                          <span>Online</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row py-5">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-2">
                        <i className="far fa-clock fa-2x"></i>
                      </div>
                      <div className="col-md-10 px-0">
                        <span className="">
                          Horario Vespertino desde <br /> las 19:00 Hrs a 22:00
                          Hrs
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <a
                    href=""
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card card_sombra h-100">
              <div className="card-header text-center">
                <strong className="">Becas IPLA</strong>
              </div>
              <div className="card-body">
                <h5 className="card-title"></h5>
                <p className="card-text text-justify">
                  El Instituto Profesional Libertador de Los Andes cuenta con
                  beca y crédito directo IPLA, además de convenios con
                  instituciones.
                </p>
                <p className="card-text text-justify">
                  Contamos con Becas de 20%; 25%; 30%. Para postular a estas
                  oportunidades internas aplican algunas condiciones, las cuales
                  se encuentran definidas en el Reglamento Financiero.
                </p>
                <p className="card-text text-justify">
                  A la fecha contamos con más de 40 convenios, como apoyo y
                  beneficios a la Comunidad IPLA.
                </p>
                <div className="d-flex justify-content-end">
                  <a
                    href="/sobre-admision/becas-ipla"
                    className="text-decoration-none d-flex align-items-center"
                  >
                    Ver mas &nbsp;
                    <i className="fas fa-chevron-circle-right fa-2x"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Presencial = styled.div`
  /*width: 100%;
  padding: 2 0 0 0;

  img{
    width: 100%;
    padding: 0 0 0 0;
  }*/
`;

export default Programas;
