import React, { useEffect } from 'react'
import { preLoaderAnim } from '../animations';
import './preloader.css'  

function Preloader() {

  useEffect(() => {
    preLoaderAnim()
  }, []);


  return (
    <>
        <div className="preloader">
          <div className="texts-container">
            
            <span>Piensa en Grande, </span>
            <span>Piensa en tu Futuro, </span>
            <span>Piensa en IPLA</span>
          </div>
        </div>
    </>
  )
}

export default Preloader