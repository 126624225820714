import React, { useState } from "react";
import ActDatos from "../../../assets/images/actualiza_datos.jpg";
import Modal from "react-bootstrap/Modal";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { NavLink } from "react-router-dom";

const RedEgresados = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Red de Egresados
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container py-4">
          <div className="card card-cascade narrower">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top d-flex justify-content-end"
                    src={ActDatos}
                    alt="Card image cap"
                  />
                  <a className="">
                    <div className="mask rgba-white-slight"></div>
                  </a>
                </div>

                <div className="card-body card-body-cascade pb-0">
                  <div className="d-flex justify-content-between">
                    <h4
                      className="font-weight-bold card-title d-flex flex-wrap align-content-end"
                      translate=""
                    ></h4>
                  </div>

                  <p className="card-text"></p>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="container">
                  <h5 className="fw-bolder display-6 card-title pt-3 mb-0">
                    Actualiza tus datos
                  </h5>
                  <hr className="" />
                  <p className="card-text ml-1 justificado me-2">
                    <strong>IPLA</strong> y su{" "}
                    <strong>RED DE EGRESADOS </strong>
                    te invitan a mantener el contacto a través de la
                    actualización de tus datos. Así también podrás conocer las
                    diversas actividades, servicios y concursos organizados
                    durante el año.
                  </p>
                  <p className="justificador">
                    Para tu mayor seguridad te informamos que toda la
                    información aquí dispuesta será de carácter confidencial,
                    por lo que nunca serán entregados a entidades externas a la
                    institución.
                  </p>

                  <div className="row">
                    <div className="col-md-8 d-flex align-items-center">
                      <p className="justificado ">
                        Para ver mas informacion haga click en el boton:
                      </p>
                    </div>
                    <div className="col-md-4">
                      <NavLink className="" to="/red-egresados/actualizacion">
                        <button
                          type="button"
                          className="btn boton_personalizado"
                        >
                          Aqui
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_egresados"></section>

      <section>
        <div className="container">
          <div className="row py-4">
            <OwlCarousel items={3} className="owl-theme" {...options}>
              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid022e8B8suAqB9qz2tDrRxLBSaZFJvJYD6HLiJP6dTGb7w2BNfwo5mHMdeMgPLm5Rfjl&show_text=true&width=500"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">Oferta Laboral</h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid0vzPJmuacdoP2JjVpWvRVdbsdPqgzRF8XRZFNCQz7gkmMEP5UAMoaoGHuHZsLjJNrl&show_text=true&width=500"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">Taller Suspendido</h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid0ezs1kBLWJyfjLfYk5sBVdPUkdgbpTZwedDdqnqDbmxx7QpUdBgqrXJCzULDFEmfql&show_text=true&width=500"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">Aniversario n°42</h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default RedEgresados;
