import React, { useState } from "react";
import JefeCarrera from "../../../../assets/images/angelina_aragon.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const TnsCalidadMedioAmbiente = () => {
  const [show, setShow] = useState(false); //asi se llama al modal

  const [formValues, setFormValues] = useState({
    Names: "",
    Apellidos: "",
    Telefono: "",
    Rut: "",
    Email: "",
  });

  //estado del boton, por defecto true
  const [habilitado, setHabilitado] = useState("Typing");

  //se optienen el valor de los input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //envio de informacion del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    setHabilitado("submitted");
  };
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-4 text-white fw-bolder mx-5 ">
              Gestión de Calidad y Medio Ambiente
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row pb-3">
            <h2 className="display-6 fw-bolder titulo">
              TNS en Gestión de Calidad y Medio Ambiente
            </h2>
          </div>

          <div className="row pt-3">
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Modalidad</strong>
                </div>
                <div className="card-body align-self-center d-flex align-items-center px-0">
                  <div className="row">
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-chalkboard-teacher fa-2x"></i>
                    </div>
                    <div className="col-md-5 px-0 d-flex align-items-center">
                      <a
                        className="text-decoration-none text-dark px-0 small"
                        href=""
                      >
                        <span>Presencial / Virtual</span>
                      </a>
                    </div>
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-desktop fa-2x "></i>
                    </div>
                    <div className="col-md-3 d-flex align-items-center px-0">
                      <a
                        className="text-decoration-none text-dark px-0 small"
                        href=""
                      >
                        <span>Online</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Duración / Horarios</strong>
                </div>
                <div className="card-body align-self-center d-flex align-items-center px-0">
                  <div className="row">
                    <div className="col-md-3 align-self-center d-flex justify-content-center">
                      <i className="fas fa-calendar-alt fa-2x"></i>
                      <i className="far fa-clock fa-2x"></i>
                    </div>
                    <div className="col-md-9 d-flex align-items-center px-0">
                      <a
                        className="text-decoration-none text-dark px-0"
                        href=""
                      >
                        <span className="small">
                          Duración 5 Semestres (2,5 Años) <br />
                          <span
                            className="text-decoration-none text-dark px-0"
                            href=""
                          >
                            Horario de 19:00 Hrs a 22:00 Hrs
                          </span>{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Requisitos</strong>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-clipboard-list fa-2x"></i>
                    </div>
                    <div className="col-md-10 d-flex align-items-center px-0">
                      <ul className="list-group list-group-flush small">
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 ">Licencia de 4to Medio</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2">Notas Certificadas</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            Cédula de Identidad (ambos lados)
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2">
                            2 Fotografías tamaño carnet
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-4">
            <div className="col-md-6">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Perfil Profesional</strong>
                </div>
                <div className="card-body">
                  <p className="justificado">
                    Esta carrera está orientada a preparar profesionales capaces
                    de desenvolverse ejecutando y administrando sólidos
                    conocimientos y habilidades en ámbitos de la gestión de
                    procesos, herramientas y protocolos, además en legislación y
                    normativas vigentes tanto en áreas de calidad y medio
                    ambiente, en instituciones públicas y privadas.
                  </p>
                  <p className="justificado">
                    La carrera de TNS en Calidad y Medio Ambiente tiene como un
                    valor agregado el otorgar a los alumnos tres certificaciones
                    a lo largo de lo que dura la carrera, tales certificaciones
                    están integradas en la malla curricular de la carrera:
                    <br />
                    - Al término del primer año y habiendo aprobado todos las
                    asignaturas correspondientes el alumno recibirá la
                    Certificación de Auditor Interno en Calidad.
                    <br />- Al termino del segundo año y habiendo aprobado todas
                    las asignaturas correspondientes el alumno recibirá la
                    certificación de Auditor Interno Ambiental.
                    <br />- Al término de su carrera y habiendo quedado en
                    calidad de egresado el alumno recibirá su certificación de
                    Auditor Líder en Sistemas de Gestión Integrados en Calidad,
                    Medio Ambiente y Seguridad.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="card card_sombra h-100 px-0">
                  <div className="card-header text-center">
                    <strong className="subtitulo">Campo Laboral</strong>
                  </div>
                  <div className="card-body">
                    <p className="justificado">
                      Podrá Trabajar en diferentes tipos de organizaciones:
                      <br />- Empresas de servicios logísticos o de transportes
                      de carga
                      <br />- Cadenas de supermercados o multitiendas
                      <br />- Empresas mineras Empresas agrícolas Empresas
                      importadoras y exportadora
                      <br />- Puertos marítimos o terrestres
                      <br />- Pymes
                      <br />- Industrias
                      <br />- Empresas del sector público o privado
                      <br />- Empresas nacionales e internacionales
                      <br />- Su propia empresa de servicios
                    </p>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="card card_sombra h-100 px-0">
                  <div className="card-header text-center">
                    <strong className="subtitulo">Malla Curricular</strong>
                  </div>
                  <div className="card-body">
                    <p className="justificado">
                      Los estudiantes IPLA, deben solicitar la malla indicando
                      tu número RUT y formato de solicitud al correo
                      electrónico: mdiaz@ipla.cl.
                    </p>
                    <div className="row">
                      <div className="col-md-1 align-self-center d-flex justify-content-center">
                        <i className="fas fa-users fa-2x"></i>
                      </div>
                      <div className="col-md-9 d-flex align-items-center align-self-center">
                        <p className="justificado py-0 my-0">
                          Docentes de la Carrera
                        </p>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => setShow(true)}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <h3 className="justificado fw-bolder">
                  Certificados Intermedios:
                </h3>
                <ul className="list-group list-group-flush small">
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2 ">
                      Certificado Intermedio en Remuneraciones y Previsión
                    </span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">
                      Certificado Intermedio en Gestión Estratégica del Capital
                      Humano
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row py-4">
            <div className="container">
              <div className="card card-sombra py-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="view overlay rounded z-depth-2 mb-lg-0 mb-4">
                        <img
                          className="img-fluid w-100"
                          src={JefeCarrera}
                          alt="Sample image"
                        />
                        <a>
                          <div className="mask rgba-white-slight"></div>
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <a className="justificado">
                        <h6 className="font-weight-bold mb-3">
                          <span className="fw-bolder titulo">
                            {" "}
                            Jefe de Carrera
                          </span>
                        </h6>
                      </a>
                      <h3 className="fw-bolder mb-3 justificado">
                        <strong className="subtitulo">
                          Angelina del Carmen Aragón Rozas
                        </strong>
                      </h3>
                      <p className="justificado">
                        Ingeniera de Ejecución en Medio Ambiente
                      </p>
                      <p className="justificado">
                        Diplomado en Planificación Ambiental de Proyectos
                      </p>
                      <p className="justificado">
                        Diplomado en Educación Superior
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_docentes_procesos_industriales"></section>

      <section>
        <div className="container">
          <div className="row pt-5">
            <h2 className="display-5 fw-bolder titulo">
              Docentes de la Carrera
            </h2>
          </div>
          <div className="row pb-3">
            <div className="col-md-4">
              <ul className="list-group list-group-flush mx-2">
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alejandro Zagal G</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alex Márquez H</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alfonso Barahona A</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Álvaro Parraguirre</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Álvaro Valenzuela P</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Andrea Pinto P</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Ángela Quiroz U</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Villagra C</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero L</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero S</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carolina Bocaz V</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Cesar Machado M</span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-group list-group-flush mx-2">
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Cristian Cáceres V</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Daniela Martínez O</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">David Mejías P</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">David Baeza C</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Diego Soto T</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Diego Rey M</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Edith Ubilla B</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero L</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero S</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carolina Bocaz V</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Cesar Machado M</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Claudio López R</span>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul className="list-group list-group-flush mx-2">
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alejandro Zagal G</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alex Márquez H</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Alfonso Barahona A</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Álvaro Parraguirre</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Álvaro Valenzuela P</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Andrea Pinto P</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Ángela Quiroz U</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Villagra C</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero L</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carlos Montero S</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Carolina Bocaz V</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Cesar Machado M</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        centered="true"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title name="example-custom-modal-styling-title">
            Descargar Malla
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="was-validated" onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Names"
                      className="form-control"
                      placeholder="Nombres"
                      required
                      value={formValues.floatingNames}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Names">Nombres</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Apellidos"
                      className="form-control"
                      placeholder="Apellidos"
                      required
                      value={formValues.floatingApellidos}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Apellidos">Apellidos</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Telefono"
                      className="form-control"
                      placeholder="Telefono"
                      required
                      value={formValues.floatingTelefono}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Telefono">Telefono ej +569</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Rut"
                      className="form-control"
                      placeholder="Rut"
                      required
                      value={formValues.floatingRut}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Rut">Rut</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-check form-switch form-check-reverse">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pasaporteCheck"
                  />
                  <label className="form-check-label" htmlFor="pasaporteCheck">
                    Pasaporte
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="Pasaporte"
                    className="form-control"
                    placeholder="Pasaporte"
                    hidden
                  />
                  <label htmlFor="Pasaporte" className="">
                    Pasaporte
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="Email"
                    className="form-control"
                    placeholder="E-mail"
                    required
                    value={formValues.floatingEmail}
                    onChange={(e) => handleInput(e)}
                  />
                  <label className="ms-2" htmlFor="Email">
                    E-mail
                  </label>
                </div>
              </div>

              <div className="row px-3">
                <div className="alert alert-warning" role="alert">
                  Nos importa tu interés por esta carrera, en breve uno de
                  nuestros ejecutivos de Admisión te estará contactando y te
                  brindará mayor información
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={
                  formValues.Names.length === 0 ||
                  formValues.Apellidos.length === 0 ||
                  formValues.Telefono.length === 0 ||
                  formValues.Rut.length === 0 ||
                  formValues.Email.length === 0 ||
                  habilitado === "submitted"
                }
              >
                Enviar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TnsCalidadMedioAmbiente;
