import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const ActualizarDatosEgresados = () => {
  const [validated, setValidated] = useState(false);
  const [habilitado, setHabilitado] = useState("Typing"); //estado del boton, por defecto true

  //const URL = "http://localhost:3000/data/DataPaises.json";

  const baseURL = "https://sisweb.ipla.cl/actualiza";
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enviar = handleSubmit(async (data) => {
    //console.log(data);
    try {
      //console.log("entro en el try");
      await axios
        .get(`${baseURL}`, {
          params: {
            data,
            subject: "Actualizacion de Datos Egresado",
          },
        })
        .finally((response) => {
          Swal.fire({
            icon: "success",
            title: "Registro Actualizado!!",
            showConfirmButton: false,
            timer: 2000,
          });
          navigate("/red-egresados");
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  });

  const [formValues, setFormValues] = useState({});

  const anios = [{ label: "1900", value: "1900" }];

  const sedes = [
    { label: "Los Andes", value: "Los Andes" },
    { label: "Coyhaique", value: "Coyhaique" },
  ];

  //se optienen el valor de los input
  const handleInputChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Actualización de Datos
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container py-4">
          <div className="card card-cascade narrower">
            <div className="row py-4">
              <h2 className="display-6 fw-bolder titulo d-flex justify-content-center">
                Actualiza tus Datos
              </h2>
            </div>

            <form onSubmit={enviar}>
              <div className="row mx-1 pb-3">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="apellidoPaterno">Apellido Paterno</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("apellidoPaterno", { required: true })}
                      placeholder="Apellido Paterno"
                    />
                    {errors.apellidoPaterno && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="apellidoMaterno">Apellido Materno</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("apellidoMaterno", { required: true })}
                      placeholder="Apellido Materno"
                    />
                    {errors.apellidoPaterno && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="primerNombre">Primer Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("primerNombre", { required: true })}
                      placeholder="Primer Nombre"
                    />
                    {errors.apellidoPaterno && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="segundoNombre">Segundo Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("segundoNombre", { required: true })}
                      placeholder="Segundo Nombre"
                    />
                    {errors.apellidoPaterno && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="apellidoPaterno">Cedula de Identidad</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("cedula", { required: true })}
                      placeholder="Cedula de Identidad"
                    />
                    {errors.cedula && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="feNacimiento">Fecha de Nacimiento</label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("feNacimiento", { required: true })}
                      placeholder="Fecha de Nacimiento"
                    />
                    {errors.feNacimiento && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="anioEgreso">Año de Egreso</label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("anioEgreso", { required: true })}
                      placeholder="Ej 2007"
                    />
                    {errors.anioEgreso && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="sede">Sede</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("sede", { required: true })}
                      placeholder="Sede"
                    />
                    {errors.sede && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="direccion">Direccion Actual</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("direccion", { required: true })}
                      placeholder="Direccion Actual"
                    />
                    {errors.direccion && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="comuna">Comuna</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("comuna", { required: true })}
                      placeholder="Comuna"
                    />
                    {errors.comuna && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="correo">Correo Electronico</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("correo", { required: true })}
                      placeholder="Correo"
                    />
                    {errors.correo && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="celular">Numero Celular</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("celular", { required: true })}
                      placeholder="Numero Celular"
                    />
                    {errors.celular && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="carrera">Carrera Cursada</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("carrera", { required: true })}
                      placeholder="Carrera"
                    />
                    {errors.carrera && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-check pt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="trabaja"
                      {...register("trabaja", { required: true })}
                    />
                    <label className="form-check-label" htmlFor="trabaja">
                      ¿Actualmente Trabajas?
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="empresa">Nombre de la Empresa</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("empresa", { required: true })}
                      placeholder="Nombre de la Empresa"
                    />
                    {errors.empresa && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="cargo">Nivel del Cargo actual:</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("cargo", { required: true })}
                      placeholder="Nombre del Cargo actual"
                    />
                    {errors.cargo && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="form-group">
                    <label htmlFor="antiguedad">Antigüedad Laboral</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("antiguedad", { required: true })}
                      placeholder="Mes y/o Años"
                    />
                    {errors.antiguedad && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="estudios1">
                      Indícanos si posees otros estudios de nivel superior
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("estudios1", { required: true })}
                      placeholder="Titulo Obtenido"
                    />
                    {errors.estudios1 && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group">
                    <label htmlFor="aniotitulo1">Año de Titulación</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("aniotitulo1", { required: true })}
                      placeholder="Año"
                    />
                    {errors.aniotitulo1 && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="instituto1">Institución de Egreso</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("instituto1", { required: true })}
                      placeholder="Titulo Obtenido"
                    />
                    {errors.instituto1 && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="estudios2">
                      Indícanos si posees otros estudios de nivel superior
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("estudios2")}
                      placeholder="Titulo Obtenido"
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group">
                    <label htmlFor="aniotitulo2">Año de Titulación</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("aniotitulo2")}
                      placeholder="Año"
                    />
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="instituto2">Institución de Egreso</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("instituto2")}
                      placeholder="Titulo Obtenido"
                    />
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-3">
                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="estudios3">
                      Indícanos si posees otros estudios de nivel superior
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("estudios3")}
                      placeholder="Titulo Obtenido"
                    />
                  </div>
                </div>

                <div className="col-lg-2">
                  <div className="form-group">
                    <label htmlFor="aniotitulo3">Año de Titulación</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("aniotitulo3")}
                      placeholder="Año"
                    />
                  </div>
                </div>

                <div className="col-lg-5">
                  <div className="form-group">
                    <label htmlFor="instituto3">Institución de Egreso</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("instituto3")}
                      placeholder="Titulo Obtenido"
                    />
                  </div>
                </div>
              </div>

              <div className="row mx-1 pb-4">
                <div className="col-lg-10">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="recibir"
                    {...register("recibir", { required: true })}
                  />
                  <label className="form-check-label" htmlFor="recibir">
                    Deseas recibir a tu correo electrónico información
                    actualizada de tu casa de estudios superiores? <br />
                    (incluye ofertas de trabajo; actualización de convenios;
                    actividades recreacionales y mucho más).
                  </label>
                </div>
                <div className="col-lg-2">
                  <button type="submit" className="btn btn-primary">
                    Actualizar
                  </button>
                </div>
              </div>
            </form>

            {/* <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="mx-4"
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="ApellidoPaterno">
                  <Form.Label>Apellido Paterno</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="ApellidoPaterno"
                    placeholder="Apellido Paterno"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="ApellidoMaterno">
                  <Form.Label>Apellido Materno</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="ApellidoMaterno"
                    placeholder="Apellido Materno"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="PrimerNombre">
                  <Form.Label>Primer Nombre</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="PrimerNombre"
                    placeholder="Primer Nombre"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="SegundoNombre">
                  <Form.Label>Segundo Nombre</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="SegundoNombre"
                    placeholder="Segundo Nombre"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="Cedula">
                  <Form.Label>Cédula</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="Cedula"
                    placeholder="Cédula"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="FeNacimiento">
                  <Form.Label>Fecha Nacimiento</Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="FeNacimiento"
                    placeholder="Fecha Nacimiento"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="AnioEgreso">
                  <Form.Label>Año de Egreso</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="AnioEgreso"
                    placeholder="Ej 2007"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="Sede">
                  <Form.Label>Sede</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="Sede"
                    placeholder="Sede"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="Direccion">
                  <Form.Label>Dirección</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="Direccion"
                    placeholder="Dirección"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="Comuna">
                  <Form.Label>Comuna</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="Comuna"
                    placeholder="Sede"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="Email">
                  <Form.Label>Correo Electronico</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="correo"
                    placeholder="Correo Electronico"
                    defaultValue=""
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="Telefono">
                  <Form.Label>Número Celular</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="Telefono"
                    placeholder="Número Celular"
                    defaultValue=""
                    onChange={handleSubmit}
                  />
                </Form.Group>

                <Form.Group as={Col} md="6" controlId="CarreraCursada">
                  <Form.Label>Carrera Cursada</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="CarreraCursada"
                    placeholder="Carrera Cursada"
                    onChange={handleSubmit}
                  />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="IsTrabaja">
                  <Form.Label>
                    ¿Actualmente Trabajas?
                    <Form.Check
                      className="pt-3 pb-0"
                      label="Activo Si / Inactivo No"
                      name="IsTrabaja"
                      type="checkbox"
                    />
                  </Form.Label>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="5" controlId="NombreEmpresa">
                  <Form.Label>Nombre de la Empresa</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre de la Empresa"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="5" controlId="Cargo">
                  <Form.Label>Nivel del Cargo Actual</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nivel del Cargo Actual"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="Antiguedad">
                  <Form.Label>Atiguedad Laboral</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mes y/o años"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="5" controlId="Estudios1">
                  <Form.Label>
                    Indicanos si posee otros estudios a nivel superior
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo Obtenido"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="AnioEstudios1">
                  <Form.Label>Año Titulación</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Año"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="5" controlId="NombreInstituto1">
                  <Form.Label>Institución de Egreso</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre de la Institución"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="5" controlId="Estudios2">
                  <Form.Label>
                    Indicanos si posee otros estudios a nivel superior
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo Obtenido"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="AnioEstudios2">
                  <Form.Label>Año Titulación</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Año"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="5" controlId="NombreInstituto2">
                  <Form.Label>Institución de Egreso</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre de la Institución"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="5" controlId="Estudios3">
                  <Form.Label>
                    Indicanos si posee otros estudios a nivel superior
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo Obtenido"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="AnioEstudios3">
                  <Form.Label>Año Titulación</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Año"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="5" controlId="NombreInstituto3">
                  <Form.Label>Institución de Egreso</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre de la Institución"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="5" controlId="Estudios4">
                  <Form.Label>
                    Indicanos si posee otros estudios a nivel superior
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Titulo Obtenido"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="2" controlId="AnioEstudios4">
                  <Form.Label>Año Titulación</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Año"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} md="5" controlId="NombreInstituto4">
                  <Form.Label>Institución de Egreso</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre de la Institución"
                    defaultValue=""
                  />
                  <Form.Control.Feedback>Valido!</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="10" controlId="RecibeInfo">
                  <Form.Label>
                    ¿Deseas recibir a tu correo electrónico información
                    actualizada de tu casa de estudios superiores? (incluye
                    ofertas de trabajo; actualización de convenios; actividades
                    recreacionales y mucho más).
                  </Form.Label>
                  <Form.Check
                    className="mx-4"
                    inline
                    label="Si"
                    name="si"
                    type="checkbox"
                    id="recibe_si"
                  />
                  <Form.Check
                    inline
                    label="No"
                    name="no"
                    type="checkbox"
                    id="recibe_no"
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="2"
                  className="d-flex justify-content-center py-3"
                >
                  <Button type="Submit">Enviar</Button>
                </Form.Group>
              </Row>
            </Form> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ActualizarDatosEgresados;
