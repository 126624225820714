import React from "react";
import { NavLink } from "react-router-dom";

const Error404 = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-lg-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Error 404
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-3">
        <div className="card card-cascade wider mb-3 pt-3">
          <div className="card-body card-body-cascade">
            <div className="row">
              <h2 className="display-6 fw-bolder titulo">Pagina no existe!!</h2>
            </div>
            <div className="row">
              <NavLink to="/">
                <i class="fas fa-home"> </i>
                &nbsp;Ir a Inicio
              </NavLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error404;
