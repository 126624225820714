import React from "react";
import BecasIPLAImg from "../../../assets/images/becas_ipla.jpg";

const BecasIplas = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Becas IPLA
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container py-4">
          <div className="card card-cascade narrower">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top d-flex justify-content-end"
                    src={BecasIPLAImg}
                    alt="Card image cap"
                  />
                  <a className="">
                    <div className="mask rgba-white-slight"></div>
                  </a>
                </div>

                <div className="card-body card-body-cascade pb-0">
                  <div className="d-flex justify-content-between">
                    <h4
                      className="font-weight-bold card-title d-flex flex-wrap align-content-end"
                      translate=""
                    ></h4>
                  </div>

                  <p className="card-text"></p>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="container">
                  <h5 className="fw-bolder display-6 card-title pt-3 mb-0">
                    Apoyo y Estímulos
                  </h5>
                  <hr className="" />
                  <p className="card-text ml-1 justificado me-2">
                    El fondo de BECAS IPLA, es un beneficio que entrega la
                    institución a la comunidad, al cual los alumnos que cumplen
                    con los requisitos pueden acceder disminuyendo en su caso el
                    arancel determinado para cada semestre, hasta un máximo de
                    un 20% sin derecho a reembolso. Es facultad privativa del
                    Instituto determinar el número de becas a entregar, o bien,
                    fijar en general el monto que semestralmente alcanzará este
                    beneficio.
                  </p>
                  <h5 className="fw-bolder display-6 card-title mb-0">
                    Alumnos Nuevos
                  </h5>

                  <div className="row">
                    <div className="d-flex align-items-center pt-3">
                      <p className="justificado">
                        Los alumnos nuevos que ingresen al instituto para
                        postular al FONDO DE BECAS IPLA, deberán acreditar los
                        siguientes requisitos:
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Aprobar los requisitos de ingreso en alguna carrera
                          impartida por el Instituto Profesional Libertador de
                          Los Andes.
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Demostrar condiciones socioeconómicas del alumno y su
                          grupo familiar que ameriten la necesidad de este
                          beneficio de beca.
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Promedio de notas de enseñanza media igual o superior
                          a 5.0 puntos.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="row">
                    <p className="justificado pt-3 titulo fw-bolder">
                      Ver los detalles en el Reglamento Financiero vigente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_beca_ipla"></section>

      <section>
        <div className="container py-4">
          <div className="card card-cascade narrower">
            <div className="row">
              <div className="col-lg-5 col-md-12 col-sm-12">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top d-flex justify-content-end"
                    src={BecasIPLAImg}
                    alt="Card image cap"
                  />
                  <a className="">
                    <div className="mask rgba-white-slight"></div>
                  </a>
                </div>

                <div className="card-body card-body-cascade pb-0">
                  <div className="d-flex justify-content-between">
                    <h4 className="font-weight-bold card-title d-flex flex-wrap align-content-end"></h4>
                  </div>

                  <p className="card-text"></p>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-sm-12">
                <div className="container">
                  <h5 className="fw-bolder display-6 card-title pt-3 mb-0">
                    Alumnos Antiguos
                  </h5>
                  <hr className="" />
                  <p className="card-text ml-1 justificado me-2">
                    Los alumnos antiguos para postular al FONDO DE BECAS IPLA
                    deberán cumplir los siguientes requisitos:
                  </p>

                  <div className="row">
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Tener un rendimiento académico promedio semestral no
                          inferior a 5.0 puntos.
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Aprobar el 100% de las asignaturas inscritas al
                          principio del semestre.
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          No registrar atraso en sus compromisos financieros con
                          el Instituto (pago al día de su arancel).
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Condiciones socioeconómicas del alumno y su grupo
                          familiar que ameriten la necesidad de este beneficio
                          de beca.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="row">
                    <h5 className="fw-bolder display-7 card-title py-3 mb-0">
                      Variables a Considerar
                    </h5>
                  </div>

                  <div className="row">
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Promedio de Notas.</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Situación Jefe del Hogar.</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Ingresos Familiares.</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Hermanos Estudiando.</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Familiar Enfermo.</span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Cumplimiento Académico (Menos).
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">
                          Cumplimiento Financiero (Menos).
                        </span>
                      </li>
                    </ul>
                    <ul className="list-group list-group-flush mx-2">
                      <li className="list-group-item py-0">
                        <i className="far fa-check-square mr-2 fa-xs"></i>
                        <span className="ms-2">Sociedad Organizadora.</span>
                      </li>
                    </ul>
                  </div>

                  <div className="row">
                    <p className="justificado pt-3 titulo fw-bolder">
                      Ver los detalles en el Reglamento Financiero vigente.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BecasIplas;
