import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

const IniciacionAcademica = () => {
  const [show, setShow] = useState(false); //asi se llama al modal

  const [formValues, setFormValues] = useState({
    Names: "",
    Apellidos: "",
    Telefono: "",
    Rut: "",
    Email: "",
  });

  //estado del boton, por defecto true
  const [habilitado, setHabilitado] = useState("Typing");

  //se optienen el valor de los input
  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  //envio de informacion del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formValues);
    setHabilitado("submitted");
  };
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i class="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-4 text-white fw-bolder mx-5 ">
              Iniciación Académica - PIA
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 py-4">
            <div className="card-body card-body-cascade">
              <div className="row">
                <div className="col-md-6">
                  <div className="row py-2">
                    <h5 className="fw-bolder titulo">
                      Programa de Iniciación Académica (P.I.A.)
                    </h5>
                  </div>
                  <div className="row">
                    <p className="justificado">
                      Es un programa de formación educativa, destinado a todos
                      los estudiantes que deseen estudiar en el Instituto
                      Profesional Libertador de Los Andes. Con la finalidad de
                      refrescar y fortalecer sus conocimientos en las diversas
                      áreas académicas necesarias e indispensables para un
                      inicio efectivo en su nueva carrera Técnica y/o
                      Profesional.
                    </p>
                    <p className="justificado">
                      El Programa de Iniciación Académica a cursar estará
                      adecuado a su futura carrera y deberá ser aprobado como un
                      requisito indispensable para ingresar a la carrera
                      seleccionada. Este programa es obligatorio para
                      estudiantes nuevo ingreso:
                    </p>
                  </div>
                  <div className="row">
                    <p className="justificado mb-0">
                      Módulos a cursar en el P.I.A.:
                    </p>
                  </div>
                  <div className="row">
                    <ul class="list-group list-group-flush mx-2">
                      <li class="list-group-item py-0">
                        <i class="fas fa-check mr-2 subtitulo"></i>
                        <span className="ms-2">Matemáticas</span>
                      </li>
                      <li class="list-group-item py-0">
                        <i class="fas fa-check mr-2 subtitulo"></i>
                        <span className="ms-2">Lenguaje y Comunicación</span>
                      </li>
                      <li class="list-group-item py-0">
                        <i class="fas fa-check mr-2 subtitulo"></i>
                        <span className="ms-2">Ética</span>
                      </li>
                      <li class="list-group-item py-0">
                        <i class="fas fa-check mr-2 subtitulo"></i>
                        <span className="ms-2">Habilidades Sociales</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row py-2">
                    <h5 className="fw-bolder titulo">Composición del P.I.A.</h5>
                  </div>
                  <div className="row">
                    <p className="justificado">
                      El IPLA, tiene un programa de estudio para el P.I.A., el
                      cual consta de 4 módulos en el que se imparte un área de
                      interés según la carrera a cursar. Este programa debe ser
                      aprobado para ser admitido e ingresar a la Escuela de la
                      carrera seleccionada. Un requisito indispensable antes de
                      la inscripción e inicio del primer período académico
                    </p>
                  </div>
                  <div className="row">
                    <p className="justificado">
                      El programa tiene una duración determinada, la modalidad a
                      cursar para cada módulo será 100% Online, bajo el modo:
                    </p>
                  </div>
                  <div className="row">
                    <p className="justificado">
                      <span className="fw-bolder subtitulo">Intensivo:</span>{" "}
                      Tiene un tiempo de duración de 4 semanas, en las cuales se
                      imparten un total de 64 horas, es decir, 16 horas por
                      módulo, en un esquema de 4 horas diarias distribuidas en 4
                      días.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row pt-3">
                <div className="py-2 d-flex justify-content-center">
                  <h5 className="fw-bolder titulo">
                    ¿Cómo ingresar en el P.I.A.?
                  </h5>
                </div>
              </div>

              <div className="row">
                <p className="justificado">
                  Para ingresar en el Programa de Iniciación Académica deberás
                  realizar los siguientes pasos:
                </p>
              </div>
              <div className="row offset-md-1">
                <div className="col-md-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <h6 className="fw-bolder titulo">Paso 1</h6>
                        </div>
                      </div>
                      <div className="row py-3">
                        <div className="d-flex justify-content-center">
                          <i className="fas fa-file-alt fa-3x subtitulo"></i>
                        </div>
                      </div>
                      <div className="row">
                        <p className="justificado">
                          Ingresar y rellenar el formulario en el siguiente
                          link:
                        </p>
                      </div>
                      <div className="row">
                        <NavLink to="/sobre-admision">
                          <i className="fas fa-external-link-alt d-flex justify-content-center">
                            <span className=""> &nbsp;Admision</span>
                          </i>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 align-self-center">
                  <div className="">
                    <i class="fas fa-arrow-right fa-3x titulo"></i>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <h6 className="fw-bolder titulo">Paso 2</h6>
                        </div>
                      </div>
                      <div className="row py-3">
                        <div className="d-flex justify-content-center">
                          <i className="fas fa-envelope-open-text fa-3x subtitulo"></i>
                        </div>
                      </div>
                      <div className="row">
                        <p className="">
                          Al completar el formulario de solicitud, recibirás un
                          correo electrónico con las instrucciones para iniciar
                          el proceso de pago e inscripción en el P.I.A.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-1 align-self-center titulo">
                  <div className="">
                    <i class="fas fa-arrow-right fa-3x"></i>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row">
                        <div className="d-flex justify-content-center">
                          <h6 className="fw-bolder titulo">Paso 3</h6>
                        </div>
                      </div>
                      <div className="row py-3">
                        <div className="d-flex justify-content-center">
                          <i className="fas fa-laptop fa-3x subtitulo"></i>
                        </div>
                      </div>
                      <div className="row">
                        <p className="">
                          Una vez finalizada la inscripción podrás acceder a la
                          plataforma con tu RUT y Clave de acceso Moodle. Al
                          iniciar, debes terminar ya que tienes un mes de plazo.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        centered="true"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title name="example-custom-modal-styling-title">
            Descargar Malla
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="was-validated" onSubmit={handleSubmit}>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Names"
                      className="form-control"
                      placeholder="Nombres"
                      required
                      value={formValues.floatingNames}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Names">Nombres</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Apellidos"
                      className="form-control"
                      placeholder="Apellidos"
                      required
                      value={formValues.floatingApellidos}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Apellidos">Apellidos</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Telefono"
                      className="form-control"
                      placeholder="Telefono"
                      required
                      value={formValues.floatingTelefono}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Telefono">Telefono ej +569</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      name="Rut"
                      className="form-control"
                      placeholder="Rut"
                      required
                      value={formValues.floatingRut}
                      onChange={(e) => handleInput(e)}
                    />
                    <label htmlFor="Rut">Rut</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="form-check form-switch form-check-reverse">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="pasaporteCheck"
                  />
                  <label className="form-check-label" htmlFor="pasaporteCheck">
                    Pasaporte
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    name="Pasaporte"
                    className="form-control"
                    placeholder="Pasaporte"
                    hidden
                  />
                  <label htmlFor="Pasaporte" className="">
                    Pasaporte
                  </label>
                </div>
              </div>

              <div className="row">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    name="Email"
                    className="form-control"
                    placeholder="E-mail"
                    required
                    value={formValues.floatingEmail}
                    onChange={(e) => handleInput(e)}
                  />
                  <label className="ms-2" htmlFor="Email">
                    E-mail
                  </label>
                </div>
              </div>

              <div className="row px-3">
                <div className="alert alert-warning" role="alert">
                  Nos importa tu interés por esta carrera, en breve uno de
                  nuestros ejecutivos de Admisión te estará contactando y te
                  brindará mayor información
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button
                className="btn btn-primary"
                type="submit"
                disabled={
                  formValues.Names.length === 0 ||
                  formValues.Apellidos.length === 0 ||
                  formValues.Telefono.length === 0 ||
                  formValues.Rut.length === 0 ||
                  formValues.Email.length === 0 ||
                  habilitado === "submitted"
                }
              >
                Enviar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default IniciacionAcademica;
