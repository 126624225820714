import React, { useCallback, useRef, useEffect } from "react";
//import { Slideshow, Slide, TextoSlide } from "../Slideshow";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import ImageHeaderLG from "../../assets/images/Banner_Header_LG_small01.jpg";
import ImageHeaderLG2 from "../../assets/images/Banner_Header_LG_small02.jpg";
import ImageHeaderLG3 from "../../assets/images/Banner_Header_LG_small03.jpg";
import ImageHeaderLG4 from "../../assets/images/Banner_Header_LG_small05.jpg";
import ImageHeaderSM from "../../assets/images/Banner_header_sm.jpg";
import "./css/banner.css";

const Banner = () => {
  //const images = [ImageHeaderLG, ImageHeaderLG2, ImageHeaderLG3];
  //const imagesSm = [ImageHeaderSM];
  return (
    <>
      <Slide indicators={true} cssClass="pt-4">
        <div className="each-slide-effect">
          <div
            className=""
            style={{
              backgroundImage: `url(${ImageHeaderLG})`,
              backgroundAttachment: "contain",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
            }}
          >
            <span>
              <div className="d-flex justify-content-between">
                <div className="p-1">
                  <i className="fas fa-phone-square"></i> 34 2 59 56 00
                </div>
                <div className="p-1">
                  <i className="fab fa-whatsapp"></i> +56 9 9680 8457
                </div>
                <div className="p-1">
                  <i className="fab fa-facebook"></i> IPLA LOS ANDES
                </div>
                <div className="p-1">
                  <i className="fas fa-globe"></i> www.ipla.cl
                </div>
                <div className="p-1">
                  <i className="fab fa-instagram"></i> IPLA LOS ANDES
                </div>
              </div>
            </span>
          </div>
        </div>
        <div className="each-slide-effect">
          <div
            className="imagen_lg"
            style={{
              backgroundImage: `url(${ImageHeaderLG2})`,
              backgroundAttachment: "contain",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
            }}
          >
            <span>
              <div className="d-flex justify-content-between">
                <div className="p-1">
                  <i className="fas fa-phone-square"></i> 34 2 59 56 00
                </div>
                <div className="p-1">
                  <i className="fab fa-whatsapp"></i> +56 9 9680 8457
                </div>
                <div className="p-1">
                  <i className="fab fa-facebook"></i> IPLA LOS ANDES
                </div>
                <div className="p-1">
                  <i className="fas fa-globe"></i> www.ipla.cl
                </div>
                <div className="p-1">
                  <i className="fab fa-instagram"></i> IPLA LOS ANDES
                </div>
              </div>
            </span>
          </div>
        </div>
        <div className="each-slide-effect">
          <div
            className="imagen_lg"
            style={{
              backgroundImage: `url(${ImageHeaderLG3})`,
              backgroundAttachment: "contain",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
            }}
          >
            <span>
              <div className="d-flex justify-content-between">
                <div className="p-1">
                  <i className="fas fa-phone-square"></i> 34 2 59 56 00
                </div>
                <div className="p-1">
                  <i className="fab fa-whatsapp"></i> +56 9 9680 8457
                </div>
                <div className="p-1">
                  <i className="fab fa-facebook"></i> IPLA LOS ANDES
                </div>
                <div className="p-1">
                  <i className="fas fa-globe"></i> www.ipla.cl
                </div>
                <div className="p-1">
                  <i className="fab fa-instagram"></i> IPLA LOS ANDES
                </div>
              </div>
            </span>
          </div>
        </div>
        {/* <div className="each-slide-effect">
          <div
            className="imagen_lg"
            style={{
              backgroundImage: `url(${ImageHeaderLG4})`,
              backgroundAttachment: "contain",
              backgroundRepeat: "no-repeat",
              objectFit: "cover",
            }}
          >
            <span>
              <div className="d-flex justify-content-between">
                <div className="p-1">
                  <i className="fas fa-phone-square"></i> 34 2 59 56 00
                </div>
                <div className="p-1">
                  <i className="fab fa-whatsapp"></i> +56 9 9680 8457
                </div>
                <div className="p-1">
                  <i className="fab fa-facebook"></i> IPLA LOS ANDES
                </div>
                <div className="p-1">
                  <i className="fas fa-globe"></i> www.ipla.cl
                </div>
                <div className="p-1">
                  <i className="fab fa-instagram"></i> IPLA LOS ANDES
                </div>
              </div>
            </span>
          </div>
        </div> */}
      </Slide>
      {/* <Slideshow infinite={true} controles={true}>
        <div className=""></div>
        <Slide>
          <img className="imagen_lg" src={ImageHeaderLG} alt="" />
          <TextoSlide>
            <div className="d-flex justify-content-around">
              <div className="p-1">
                <i className="fas fa-phone-square"></i> 34 2 59 56 00
              </div>
              <div className="p-1">
                <i className="fab fa-whatsapp"></i> +56 9 9680 8457
              </div>
              <div className="p-1">
                <i className="fab fa-facebook"></i> IPLA LOS ANDES
              </div>
              <div className="p-1">
                <i className="fas fa-globe"></i> www.ipla.cl
              </div>
              <div className="p-1">
                <i className="fab fa-instagram"></i> IPLA LOS ANDES
              </div>
            </div>
          </TextoSlide>
        </Slide>
        <Slide>
          <img className="imagen_lg" src={ImageHeaderLG2} alt="" />
          <TextoSlide>
            <div className="d-flex justify-content-around">
              <div className="p-2 font-weight-bold">
                <i className="fas fa-phone-square"></i> 34 2 59 56 00
              </div>
              <div className="p-2 font-weight-bold">
                <i className="fab fa-whatsapp"></i> +56 9 9680 8457
              </div>
              <div className="p-2 font-weight-bold">
                <i className="fab fa-facebook"></i> IPLA LOS ANDES
              </div>
              <div className="p-2 font-weight-bold">
                <i className="fas fa-globe"></i> www.ipla.cl
              </div>
              <div className="p-2 font-weight-bold">
                <i className="fab fa-instagram"></i> IPLA LOS ANDES
              </div>
            </div>
          </TextoSlide>
        </Slide>
      </Slideshow> */}
    </>
  );
};

export default Banner;
