export const colaboradores = [
  {
    id: 1,
    empresa: "Dentimagen",
    contacto: [
      {
        id: "001",
        nombre: "Dra. Karen Fuentealba V",
        cargo: "Gerente de Ventas",
        correo: "callcenter@dentimagen.cl",
        telefono: "24105500",
        whatsapp: "+56979790310",
      },
    ],
    oportunidad: "Servicios",
    beneficio: "BENEFICIOS ODONTOLÓGICOS Y BENEFICIARIOS",
    paginaweb: "www.dentimagen.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Los beneficiarios del Convenio de Atención Odontológica tendrán derecho a un descuento de hasta un 60% del valor del arancel referencial de DENTIMAGEN de las prestaciones odontológicas en la forma y condiciones estipuladas en el presente Contrato. Los beneficios se concederán desde el día siguiente de la fecha de suscripción del presente contrato.            ",
      },
      {
        id: "002",
        parrafo:
          "Se consideran beneficiarios de este Convenio todos los integrantes la entidad INSTITUTO PROFESIONAL LIBERTADOR DE LOS ANDES y sus cargas familiares. Podrán también solicitar los beneficios del Convenio, otros parientes de los beneficiarios contando con el consentimiento de estos.            ",
      },
      {
        id: "003",
        parrafo:
          "El presente Convenio Colectivo contempla atenciones en Urgencias, Operatoria, Endodoncia, Odontopediatría, Periodoncia, Cirugía, Prótesis Fija, Prótesis Removible, Ortodoncia, Implantología y todas las prestaciones entregadas por todas las sucursales de la Red de clínicas DENTIMAGEN, cuyos precios serán actualizados de acuerdo con los cambios económicos que se deriven de la Ley y de la situación país (ver anexo 1).            ",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "" }],
    imagen: "dentimagen.png",
  },
  {
    id: 2,
    empresa: "Calle Larga",
    contacto: [
      {
        id: "001",
        nombre: "Dina González Alfaro",
        cargo: "Alcalde",
        correo: "callcenter@callelarga.cl",
        telefono: "(34) 243 62 00",
        whatsapp: "",
      },
    ],
    oportunidad: "Pasantía",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "www.callelarga.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Los comparecientes acuerdan en el presente acto establecer vínculos de colaboración e intercambio en programas y actividades de: Gestión asistencial docente, de asistencia técnica, de capacitación, de pasantías y prácticas profesionales, recreativas y de extensión, en las áreas que tengan interés en fomentar, fortalecer o desarrollar y que se relacionen con el quehacer de sus respectivas instituciones, en que la participación conjunta de estos dos organismos permitirá una mejor formulación de actividades específicas de cooperación, y que sean de mutuo interés para las partes interesadas.",
      },
      {
        id: "002",
        parrafo:
          "La Municipalidad de Calle Larga autoriza a los alumnos de las diferentes carreras del Instituto, a realizar prácticas curriculares, en las dependencias del: CENTRO DE SALUD FAMILIAR JOSÉ JOAQUIN AGUIRRE, ESCUELAS, JARDINES Y LICEOS MUNICIPALES DE LA ZONA, como también en las oficinas administrativas de la Municipalidad de la comuna de Calle Larga. Los alumnos a los cuales hace referencia el presente convenio son de las siguientes carreras: Psicopedagogía, TNS en Educación de párvulo, Servicio social, TNS en administración en RRHH, ING en administración en Gestión en RRHH, y TNS e ING en prevención de Riesgos y medio ambiente, y aquellas que se estén dictando en la sede de la ciudad de Los Andes del Instituto Profesional Libertador de los Andes.",
      },
      {
        id: "003",
        parrafo:
          "La Municipalidad de Calle Larga previa coordinación con los encargados y responsables respectivos, facilitará al Instituto el acceso y uso de las dependencias municipales, centro de salud familiar (CESFAM), o de establecimientos educacionales de administración municipal para actividades de: difusión, prácticas profesionales, extensión y vinculación con el medio.",
      },
      {
        id: "004",
        parrafo:
          "Estas actividades deberán ser coordinadas por el encargado de vinculación (del IPLA o de la Municipalidad), y podrán tener carácter formativo-educativo, deportivo, artístico, cultural, y tendrá como objetivo principal fomentar la vinculación del Instituto con el medio en que se encuentra inserta.",
      },
      {
        id: "005",
        parrafo:
          "La Municipalidad de Calle Larga otorgará amplias facilidades de difusión de las carreras que imparte el Instituto, entre sus estudiantes, apoderados, funcionarios y cargas legales, para efectos de promover y explicar los Beneficios Becarios, y responder consultas sobre actividades académicas. Asimismo, el Instituto otorgará amplias facilidades a la l. Municipalidad de Calle Larga para que difunda entre sus alumnos, docentes y funcionarios, los beneficios sociales y económicos que mantiene con el Instituto.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "" }],
    imagen: "calle_larga.png",
  },
  {
    id: 3,
    empresa: "Universidad Santander",
    contacto: [
      {
        id: "001",
        nombre: "Eduardo Bittner Morbiducci",
        cargo: "Representante",
        correo: "ebittner@unisant.cl",
        telefono: "",
        whatsapp: "+56 964 140 775",
      },
      {
        id: "002",
        nombre: "Gabriel Alberto Morales Bello",
        cargo: "Representante",
        correo: "gmorales@unisant.cl",
        telefono: "",
        whatsapp: "+56 964 140 775",
      },
    ],
    oportunidad: "Beneficio de Estudios y continuidad de estudios",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://chile.unisant.net/",
    informacion: [
      {
        id: "001",
        parrafo:
          "El presente Convenio tiene por objetivo establecer los términos y alcances de la colaboración entre las partes, en el ámbito de sus respectivas competencias y de acuerdo con sus políticas institucionales, con la finalidad de efectuar actividades conjuntas orientadas a fortalecer su función social, a través del intercambio de información, conocimientos, experiencias, apoyo técnico, investigación, asesorías y capacitación, según sean las necesidades y prioridades de cada una de las partes, priorizando aquellas actividades que se orienten a las actividades de vinculación con el medio. Con este propósito, las partes evaluarán y definirán formas concretas o específicas de colaboración, las que podrán ser, entre otras, las siguientes:",
      },
      {
        id: "002",
        parrafo:
          "a)	Docencia y Capacitación: Organización, dictación y participación en programas de Educación Ejecutiva como Diplomados, Magíster, Master, Doctorados y Post Doctorados, Cursos, Seminarios, Congresos, Foros, Talleres y/o reuniones profesionales y/o científicas;",
      },
      {
        id: "003",
        parrafo:
          "b)	Investigación: Estudio, desarrollo, cooperación e implementación de proyectos o programas específicos de investigación;",
      },
      {
        id: "004",
        parrafo: "c)	Asesoría: Cooperación y asistencia técnica recíproca.",
      },
      {
        id: "005",
        parrafo:
          "d)	Intercambio de información relativa a cursos, conferencias, seminarios, y demás actividades de naturaleza académica y científica ofrecidas por ambas instituciones.",
      },
      {
        id: "006",
        parrafo:
          "e)	Invitación a técnicos nacionales o extranjeros, a participar en cursos, seminarios, conferencias, investigaciones o proyectos especiales que se realicen",
      },
      {
        id: "007",
        parrafo:
          "f)	Intercambio de publicaciones e información científica y técnica.",
      },
      {
        id: "008",
        parrafo:
          "g)	Dobles grados académicos internacionales con claustros mixtos o como de común acuerdo las partes definan mediante convenios específicos",
      },
      {
        id: "009",
        parrafo:
          "h)	Cualquier otra modalidad de cooperación que sea considerada de interés por ambas partes",
      },
      {
        id: "010",
        parrafo:
          "Por su parte la Universidad Santander pone a disposición, la oferta de programas académicos conducentes a Diplomados, Maestrías, Doctorados y Post Doctorados, además de pasantías internacionales en Cancún México y Madrid, España, para la comunidad de docentes, familiares de los directivo y docentes, así como para alumnos egresados. Dichos postgrados corresponden principalmente a los siguientes:",
      },
      {
        id: "011",
        parrafo: `1.	Postítulo conducente al grado de Licenciatura en Educación`,
      },
      {
        id: "012",
        parrafo:
          "2.	Licenciaturas en áreas de Negocios, cosmética y Proyectos Ambientales",
      },
      {
        id: "013",
        parrafo: "3.	Post Doctorados en Educación con menciones ",
      },
      {
        id: "014",
        parrafo:
          "4.	Maestría en Educación Estadística, Docencia e Investigación",
      },
      {
        id: "015",
        parrafo:
          "5.	Maestría en Educación en el área de Docencia e Investigación",
      },
      {
        id: "016",
        parrafo: "6.	Doctorado en Ciencias de la Educación",
      },
      {
        id: "017",
        parrafo:
          "7.	Doctorado en Ciencias de la educación enfoque en Investigación",
      },
      {
        id: "018",
        parrafo: "8.	Doctorado en Ciencias de la Educación y Estadística",
      },
      {
        id: "019",
        parrafo: "9.	Maestría en Administración de Empresas Cooperativas",
      },
      {
        id: "020",
        parrafo: "10.	Maestría en Administración y Dirección de Empresas",
      },
      {
        id: "021",
        parrafo: "11.	Doctorado en Ciencias de la Administración y Negocios",
      },
      {
        id: "022",
        parrafo:
          "12.	Programa de Continuidad de Estudios conducente al Doctorado en Ciencias de la Educación (para candidatos a Doctor o con procesos inconclusos)",
      },
      {
        id: "023",
        parrafo:
          "13.	Programa de Continuidad de Estudios conducente al Doctorado en Ciencias de la Administración y Negocios (para candidatos a Doctor o con procesos inconclusos)",
      },
      {
        id: "024",
        parrafo: "14.	Diplomado en Gestión Cultural",
      },
      {
        id: "025",
        parrafo: "15.	Diplomado en Planificación y Control de Proyectos",
      },
      {
        id: "027",
        parrafo: "16.	Diplomado en Gestión de Proyectos de Energía Solar",
      },
      {
        id: "028",
        parrafo:
          "Los beneficiarios deberán cumplir los requisitos generales que les permitan ser seleccionados para ingresar a la Universidad Santander y aprovechar todos los beneficios y servicios.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "" }],
    imagen: "uni_santander2.png",
  },
  {
    id: 4,
    empresa: "Hotel Plaza",
    contacto: [
      {
        id: "001",
        nombre: "Francisco Perinetti Marinovic",
        cargo: "Representante Legal del Hotel Plaza Los Andes",
        correo: "",
        telefono: "",
        whatsapp: "+56 34 240 2157",
      },
    ],
    oportunidad: "Beneficio de Alimentación y Esparcimiento",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb:
      "http://www.facebook.com/Restaurant-Hotel-Plaza-Los-Andes-210494015681211/",
    informacion: [
      {
        id: "001",
        parrafo:
          'Que el presente convenio cooperación entre el INSTITUTO y HOTEL PLAZA, tiene por objetivo el establecer un "Sistema de Descuento en el Consumo", que realicen los funcionarios y estudiantes del INSTITUTO, que posean la Credencial o Tarjeta del Instituto Profesional Libertador de Los Andes - IPLA, el cual consistente en un descuento especial del 10%, presentando la credencial, aplicable en los siguientes productos y/o servicios:',
      },
      {
        id: "002",
        parrafo: "1. Alimentación.",
      },
      {
        id: "003",
        parrafo: "2. Uso de salones.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "Calle Manuel Rodríguez #368, Los Andes" }],
    imagen: "hotel_plaza.png",
  },
  {
    id: 5,
    empresa: "Sindicato de Trabajadores",
    contacto: [
      {
        id: "001",
        nombre: "Orlando Javier López León",
        cargo: "Presidente del Sindicato",
        correo: "sut@codelco.cl",
        telefono: "+56 34 2 426648",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio para miembros y familiares del Sindicato. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb:
      "https://www.sut.cl/el-sindicato-de-trabajadores-de-empresa-siemens-codelco-andina-aprobo-la-ultima-oferta/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "002",
        parrafo:
          "El Sindicato, además, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
      {
        id: "003",
        parrafo:
          "El Instituto se compromete a otorgar en forma semestral: una beca de un 50%, dos de un 40% del arancel de las Carreras, a los funcionarios y familiares directos, que proponga El Sindicato. ",
      },
      {
        id: "004",
        parrafo:
          "Con esta finalidad El Sindicato hará llegar al Instituto una nómina de los becarios que haya seleccionado en las fechas siguientes: 30 de Julio y 30 de Diciembre de cada año. Las becas podrán ser utilizadas por El Sindicato solo para carreras de régimen presencial y que esté impartiendo el Instituto al momento que se postulen, beneficio que se mantendrá mientras el estudiante cumpla con los requisitos de rendimiento académico que se le exige para que le sea renovada por un nuevo periodo",
      },
      {
        id: "005",
        parrafo:
          "Todo otro postulante no becario de El Sindicato tendrá como beneficio una rebaja única de arancel de un 30% de la carrera que optará, para efectos se remitirá la nómina de los postulantes al instituto antes del 30 de Julio y 30 Diciembre de cada año.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "" }],
    imagen: "sindicato_trabajadores.png",
  },
  {
    id: 6,
    empresa: "Kadmiel",
    contacto: [
      {
        id: "001",
        nombre: "Christian Jofre Valle",
        cargo: "Representantes Legar de KADMIEL Los Andes",
        correo: "info@unisant.cl",
        telefono: " 34 2 408068",
        whatsapp: "+56 9 9584 9318",
      },
    ],
    oportunidad: "Beneficios y Servicios Odontológicos",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.facebook.com/CentroClinicoKadmiel/",
    informacion: [
      {
        id: "001",
        parrafo:
          "KADMIEL, otorgará un beneficio a la comunidad IPLA en todos los servicios asociados al área dental detallados en el presente convenio comercial y se extiende a todos los colaboradores, estudiantes, egresados, titulados y familiares directos de estos (conyugue e hijos), este beneficio corresponde a un 15% de descuento, sobre los precios establecidos en KADMIEL, en el área de Odontología General. Adicionalmente KADMIEL, otorgará un beneficio de un 5% de descuento en las áreas de Especialidades Odontológicas. KADMIEL se compromete a dar prioridad en atención, realizar evaluaciones gratis en Odontología y Medicina estética, tarifas y promociones preferenciales en toda el área de odontología y demás servicios de KADMIEL, los descuentos no son acumulables.",
      },
      {
        id: "002",
        parrafo:
          "Principales Servicios: Odontología, Radiología, Psicología, Medicina complementaria, Medicina estética, Medicina general, Fonoaudiología y Nutrición",
      },
      {
        id: "003",
        parrafo:
          "Estos servicios se prestarán en instalaciones de KADMIEL y en los horarios de lunes a viernes horario continuado de 09:30 a 20:30 horas, previa cita. El beneficiado, para hacer efectivos los descuentos correspondientes, deberá comunicarse con área de Vinculación con el Medio del INSTITUTO al correo de María Pía Vilches Navia (mvilches@ipla.cl), enviando sus antecedentes como nombre completo, Rut, fono de contacto y el perfil al que corresponde el beneficiado (colaborador, estudiante, egresado, titulado o familiar directo). Posteriormente el INSTITUTO se comunicará con KADMIEL, para dar visto bueno y solicitar que se aplique los descuentos respectivos al beneficiado. ",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "Los Andes, Esmeralda 246-B" }],
    imagen: "kadmiel.png",
  },
  {
    id: 7,
    empresa: "Centro de Desarrollo Social (CEDESOC)",
    contacto: [
      {
        id: "001",
        nombre: "",
        cargo: "Director/ra",
        correo: "",
        telefono: "",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios, Investigación, Otros",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "",
    informacion: [
      {
        id: "001",
        parrafo:
          "CEDESOC es un Centro que desarrolla proyectos de investigación y actividades de difusión de la temática de Gestión Pública y Trabajo Social: Migración, lnterculturalidad, Territorios, entre otros.",
      },
      {
        id: "002",
        parrafo:
          "El Presente convenio, tiene por objeto enmarcar y proveer de un contexto general de las actividades conjuntas que acuerden realizar las partes con la finalidad de establecer vínculos de cooperación con miras a la elaboración, planificación y desarrollo de proyectos, operación y desarrollo de proyectos, actividades de difusión en materia de inclusión de personas con ceguera y baja visión.",
      },
      {
        id: "003",
        parrafo: "Las materias del convenio serán las siguientes:",
      },
      {
        id: "004",
        parrafo:
          "a)	Intercambio de experiencias profesionales entre las partes.",
      },
      {
        id: "005",
        parrafo:
          "b)	Planificación y ejecución de proyectos conjuntos en los ámbitos de aplicación en materia de Gestión Pública y Trabajo Social",
      },
      {
        id: "006",
        parrafo:
          "c)	Aporte de información técnica a los propósitos del convenio.",
      },
      {
        id: "007",
        parrafo:
          "d)	Organización y/o participación en seminarios, congresos y otros.",
      },
      {
        id: "008",
        parrafo: "e)	Transferencia de resultados de investigación aplicada.",
      },
      {
        id: "009",
        parrafo: "f)	Difusión de resultados de investigación aplicada.",
      },
      {
        id: "010",
        parrafo: "g)	Asesoría y asistencia técnica conjunta en proyectos.",
      },
      {
        id: "011",
        parrafo:
          "h)	Desarrollo de cualquier otra actividad que las partes consideren adecuadas y pertinentes a los objetivos generales del presente convenio.",
      },
      {
        id: "012",
        parrafo:
          "i)	Fortalecer y contribuir a la bidireccionalidad en las actividades propias de este convenio. Esto último, con el fin de garantizar la Vinculación con el Medio entre las partes.",
      },
      {
        id: "013",
        parrafo:
          "Para materializar, a cualquiera de los objetivos propuestos en el presente convenio, mediante la realización de los proyectos o actividades de forma conjunta, cualquiera sea su naturaleza, tipología, monto, valor, estructura económica, tecnológica o jurídica, las partes detallarán y celebrarán respectivos convenios específicos. En dichos instrumentos se detallarán de manera precisa los proyectos o actividades, las obligaciones y derechos de cada una de las partes intervinientes y los procedimientos a seguir en el desarrollo y ejecución de estas. Las partes podrán desarrollar proyectos y actividades de forma conjunta o complementaria sobre problemas de interés común, así como también convenir asistencia técnica en aquellas áreas relevantes en cada una de ellas.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "" }],
    imagen: "cedesoc.png",
  },
  {
    id: 8,
    empresa: "Fgt Servicio",
    contacto: [
      {
        id: "001",
        nombre: "Fernando Javier Gahona Tapia ",
        cargo: "Representantes Legar",
        correo: "fgaho@hotmail.com",
        telefono: "",
        whatsapp: "+56 9 7872 1118",
      },
    ],
    oportunidad: "Beneficio de Publicidad y Marketing Digital",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "",
    informacion: [
      {
        id: "001",
        parrafo:
          "El objetivo fundamental de FGT SERVICIOS, es entregar soluciones integrales en el área de la Publicidad, Diseño, Impresiones y otros, con un equipo de trabajo de excelencia, preocupándose en otorgar solución integral a los requerimientos de cada cliente. FGT SERVICIOS cuenta con equipos de impresión de alta tecnología y un equipo profesional de diseño, con vasta experiencia.",
      },
      {
        id: "002",
        parrafo:
          "FGT SERVICIOS, otorgará un beneficio a la comunidad del INSTITUTO, en todos los servicios asociados: PUBLICIDAD, DISEÑO, IMPRESIONES Y OTROS detallados en el presente convenio comercial y se extiende a todos los colaboradores, estudiantes, egresados, titulados y familiares directos de estos, mencionando que pertenece al INSTITUTO. Este beneficio corresponde a un 15% de descuento, sobre los precios establecidos en la EMPRESA. ",
      },
      {
        id: "003",
        parrafo:
          "El horario de atención de FGT SERVICIOS es de lunes a viernes de 09:00 a 13:00 hora y de 15:00 a 20:00 horas, vía mail fgaho@hotmail.com o al fono: +56978721118.",
      },
    ],
    estado: "Vigente",
    direccion: [{ id: "001", lugar: "Los Andes, Esmeralda 246-B" }],
    imagen: "fgt_servicios.png",
  },
  {
    id: 9,
    empresa: "Gobernación de los Andes",
    contacto: [
      {
        id: "001",
        nombre: "Sr. Felipe Salinas ",
        cargo:
          "Presidente de la Asociación de Funcionarios Gobernación de Los Andes",
        correo: "",
        telefono: "34-2431700 ",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios y continuidad de estudios",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "http://www.gobernacionlosandes.gob.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "El objetivo del presente convenio busca entregar beneficios para funcionarios de la Gobernación de Los Andes y familiares directos, a través de becas IPLA Directas y descuentos preferenciales en carreras técnicas y profesionales en modalidad presencial. Para hacer efectivo la beca debe comunicarse con el Presidente de la Asociación de Funcionarios de la Gobernación de Los Andes.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar:
          "Edificio Patrimonial Gobernación Provincial de Los Andes, Calle Esmeralda N° 387.",
      },
    ],
    imagen: "gobernacion_losandes.png",
  },
  {
    id: 10,
    empresa: "Anglo American",
    contacto: [
      {
        id: "001",
        nombre: "Paulina Silva Jara",
        cargo: "Encargada de Fortalecimiento Instituciones de Educación",
        correo: "",
        telefono: "600 655 05 00 ",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios y continuidad de estudios",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb:
      "https://chile.angloamerican.com/programas-sociales/programa-empleat.aspx",
    informacion: [
      {
        id: "001",
        parrafo:
          "El PROGRAMA se realizará en conjunto con el Banco Interamericano de Desarrollo BID y será ejecutado por Fundación TechnoServe Chile.",
      },
      {
        id: "002",
        parrafo:
          "1.	EL INSTITUTO, se compromete a realizar difusión del PROGRAMA, entre sus estudiantes, egresados y titulados, a través de los canales que habitualmente utiliza para difundir diversas iniciativas, o bien, a través de nuevas estrategias de difusión que sean definidas y establecidas en el marco de esta colaboración.",
      },
      {
        id: "003",
        parrafo:
          "2.	Comunicaciones: coordinar la aprobación de cada publicación en canales externos acerca del PROGRAMA, previo acuerdo con los encargados del PROGRAMA.",
      },
      {
        id: "004",
        parrafo:
          "3.	Facilitar sus instalaciones para concretar la realización de charlas de difusión, talleres y/o asesorías del PROGRAMA.",
      },
      {
        id: "005",
        parrafo:
          "4.	Aportar con su conocimiento de la realidad local/territorial.",
      },
      {
        id: "006",
        parrafo:
          "5.	Participar activamente en las actividades que el PROGRAMA, coordine en la zona/comuna.",
      },
      {
        id: "007",
        parrafo:
          "El Programa EmpleaT de Anglo American, realizado en alianza con BID LAB y Fundación TechnoServe Chile, nace ante la necesidad de fortalecer y vincular a los distintos actores del ecosistema laboral, de manera de contribuir a aumentar el impacto que cada uno logra por separado, junto con entregar mejores oportunidades laborales a los residentes de las comunas vecinas a las operaciones de la compañía, conectándolos con un ecosistema más robusto. Existen dos formas de intervención dentro del programa: Intervención Individual: Fortalecimiento de competencias; e Intervención Sistémica: Fortalecimiento del ecosistema laboral.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "",
      },
    ],
    imagen: "anglo_america.png",
  },
  {
    id: 11,
    empresa: "Apura",
    contacto: [
      {
        id: "001",
        nombre: "Ignacio Omar Aldana Riquelme ",
        cargo: "Presidente de APURA",
        correo: "",
        telefono: "",
        whatsapp: "+56 9 9717 9086",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación.",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.facebook.com/losandesNOsomostodosoficial",
    informacion: [
      {
        id: "001",
        parrafo:
          "LA AGRUPACIÓN y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas organizacionales y estrechar vínculos entre ambos y además con la Comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales y pasantías, actividades de extensión, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades. La AGRUPACIÓN, además, se compromete a otorgar al INSTITUTO las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "",
      },
    ],
    imagen: "apura.png",
  },
  {
    id: 12,
    empresa: "Los Andes",
    contacto: [
      {
        id: "001",
        nombre: "Manuel Rivera Martínez ",
        cargo: "Alcalde",
        correo: "contacto@munilosandes.cl",
        telefono: "+56 342507 7700 ",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación.",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "http://www.losandes.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Los comparecientes acuerdan en el presente acto establecer vínculos de colaboración e intercambio en programas y actividades de: Gestión asistencial docente, de asistencia técnica, de capacitación, de pasantías y prácticas profesionales, recreativas y de extensión, en las áreas que tengan interés en fomentar, fortalecer o desarrollar y que se relacionen con el quehacer de sus respectivas instituciones, en que la participación conjunta de estos dos organismos permitirá una mejor formulación de actividades específicas de cooperación, y que sean de mutuo interés para las partes interesadas.",
      },
      {
        id: "002",
        parrafo:
          "La Municipalidad de Los Andes autoriza a los alumnos de las diferentes carreras del Instituto, a realizar prácticas curriculares, en las dependencias del: CENTRO DE SALUD FAMILIAR CENTENARIO, CECOF JUAN PABLO SEGUNDO, LA POSTA RURAL DE RÍO BLANCO, ESCUELAS Y LICEOS MUNICIPALES DE LA ZONA, como también en las oficinas administrativas de la Municipalidad de la comuna de Los Andes.",
      },
      {
        id: "003",
        parrafo:
          "Los alumnos a los cuales hace referencia el presente convenio son de las siguientes carreras: Psicopedagogía, TNS en Educación de párvulo, Servicio social, TNS en administración en RRHH, ING en administración en Gestión en RRHH, y TNS e ING en prevención de Riesgos y medio ambiente, dictadas en la sede de la ciudad de Los Andes del Instituto Profesional Libertador de los Andes.",
      },
      {
        id: "004",
        parrafo:
          "La Municipalidad de Los Andes previa coordinación con los encargados y responsables respectivos, facilitará al Instituto el acceso y uso de las dependencias municipales, centro de salud familiar (CESFAM), para actividades de: difusión, prácticas profesionales, extensión y vinculación con el medio. Estas actividades deberán ser coordinadas por el encargado de vinculación (del IPLA o de la Municipalidad), y podrán tener carácter formativo-educativo, deportivo, artístico, cultural, y tendrá como objetivo principal fomentar la vinculación del Instituto con el medio en que se encuentra inserta.",
      },
      {
        id: "005",
        parrafo:
          "La Municipalidad de Los Andes otorgará amplias facilidades de difusión de las carreras que imparte el Instituto, entre sus estudiantes, apoderados, funcionarios y cargas legales, para efectos de promover y explicar los Beneficios Becarios, y responder consultas sobre actividades académicas. Asimismo, el Instituto otorgará amplias facilidades a la l. Municipalidad de Los Andes para que difunda entre sus alumnos, docentes y funcionarios, los beneficios sociales y económicos que mantiene con el Instituto. Se deja establecido, que en el caso de Educación se deberá ajustar a los tiempos y actividades propias de los Establecimientos.",
      },
      {
        id: "006",
        parrafo:
          "En el caso de los funcionarios que accedan a una carrera ya sea Técnica o Profesional o en continuidad de estudios, la Municipalidad se compromete a efectuar el descuento del arancel mensual directo de su liquidación de remuneraciones y pagar este descuento dentro de los 1 O días del mes siguiente. Para estos efectos, el Municipio podrá efectuar dicho descuento siempre y cuanto el funcionario no sobrepase el % de descuentos que estipula la Ley.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Esmeralda 536, Los Andes, Quinta Región de Valparaíso",
      },
    ],
    imagen: "los_andes.png",
  },
  {
    id: 13,
    empresa: "Centro de Cartografía Táctil (CECAT)",
    contacto: [
      {
        id: "001",
        nombre: "Enrique Pérez de Prada",
        cargo: "Director(ra) del Centro de Cartografía Táctil (CECAT)",
        correo: "",
        telefono: "+56 342507 7700 ",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios, Investigación, Otros",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "",
    informacion: [
      {
        id: "001",
        parrafo:
          "El Presente convenio, tiene por objeto enmarcar y proveer de un contexto general de las actividades conjuntas que acuerden realizar las partes con la finalidad de establecer vínculos de cooperación con miras a la elaboración, planificación y desarrollo de proyectos, operación y desarrollo de proyectos, actividades de difusión en materia de inclusión de personas con ceguera y baja visión.",
      },
      {
        id: "002",
        parrafo: "Las materias del convenio serán las siguientes:",
      },
      {
        id: "003",
        parrafo:
          "a)	Intercambio de experiencias profesionales entre las partes.",
      },
      {
        id: "004",
        parrafo:
          "b)	Planificación y ejecución de proyectos conjuntos en los ámbitos de aplicación en materia de inclusión de personas con ceguera y baja visión.",
      },
      {
        id: "005",
        parrafo:
          "c)	Aporte de información técnica a los propósitos del convenio.",
      },
      {
        id: "006",
        parrafo:
          "d)	Organización y/o participación en seminarios, congresos y otros.",
      },
      {
        id: "007",
        parrafo: "e)	Transferencia de resultados de investigación aplicada.",
      },
      {
        id: "008",
        parrafo: "f)	Difusión de resultados de investigación aplicada.",
      },
      {
        id: "009",
        parrafo: "g)	Asesoría y asistencia técnica conjunta en proyectos.",
      },
      {
        id: "010",
        parrafo:
          "h)	Desarrollo de cualquier otra actividad que las partes consideren adecuadas y pertinentes a los objetivos generales del presente convenio",
      },
      {
        id: "011",
        parrafo:
          "i)	Fortalecer y contribuir a la bidireccionalidad en las actividades propias de este convenio. Esto último, con el fin de garantizar la Vinculación con el Medio entre las partes.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Esmeralda 536, Los Andes, Quinta Región de Valparaíso",
      },
    ],
    imagen: "cartografia_tactil.png",
  },
  {
    id: 14,
    empresa: "SERBANC",
    contacto: [
      {
        id: "001",
        nombre: "Claudio Caldera Cofré",
        cargo: "Representante Legal",
        correo: "contacto@serbanc.cl",
        telefono: "+56 2 2751 7500",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación.",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.serbanc.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "SERBANC y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la Comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "004",
        parrafo:
          "SERBANC, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
      {
        id: "005",
        parrafo:
          "SERBANC, además, se compromete a coordinar la comunicación de ofertas laborales y/o prácticas profesionales, pasantías, con la Encargada de Vinculación con el Medio del Instituto, para que ella pueda difundir y promover entre la comunidad del Instituto, las distintas vacantes laborales/prácticas profesionales disponibles en SERBANC, por todos los medios que sean posibles.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "",
      },
    ],
    imagen: "serbanc.png",
  },
  {
    id: 15,
    empresa: "Gontec Spa",
    contacto: [
      {
        id: "001",
        nombre: "Manuel González",
        cargo: "Representante Legal",
        correo: "Mgonzalez@gontec.cl",
        telefono: "",
        whatsapp: "+56 9 5736 9922",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación.",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.facebook.com/Gontecspa/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Gontec SPA Empresa de construcción y servicios de ingeniería. Maestranza confección e estructuras metálicas y memorias de cálculo.",
      },
      {
        id: "002",
        parrafo:
          "GONTEC y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la Comunidad.",
      },
      {
        id: "003",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "004",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "005",
        parrafo:
          "GONTEC, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
      {
        id: "006",
        parrafo:
          "GONTEC, además, se compromete a coordinar la comunicación de ofertas laborales y/o prácticas profesionales, pasantías, con la Encargada de Vinculación con el Medio del Instituto, para que ella pueda difundir entre la comunidad del Instituto, las distintas vacantes disponibles, por los medios que sean posibles.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Pascual Baburizza Parc #7 Calle Larga",
      },
    ],
    imagen: "gontec_spa.png",
  },
  {
    id: 15,
    empresa: "Conifos",
    contacto: [
      {
        id: "001",
        nombre: "Carola Delgado Ureta ",
        cargo: "Encargada de Asesorías Comunicacionales",
        correo: "",
        telefono: "+56 22 6990786 – 22 6990570",
        whatsapp: "+56 9 5736 9922",
      },
    ],
    oportunidad:
      "Beneficio de Colaboración y Asesorías. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://conifos.cl/wp/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Asociación Gremial orientada a la búsqueda de la excelencia académica a través de los vínculos de quienes participan. Esta sinergia parte de la asociatividad de Institutos Profesionales y Centros de Formación Técnica, lo que se traduce en fortalezas concretas que permiten velar por una educación de calidad. Consejo Nacional de Instituciones de Educación Superior CFT/IP.",
      },
      {
        id: "002",
        parrafo:
          "Con el espíritu de reafirmar su compromiso de colaboración mutua, para aportar al desarrollo sustentable, las partes interesadas por medio de este documento ratifican su buena voluntad de colaborar con el Consejo Nacional de Instituciones Privadas de Educación Superior, para crear valor mutuo a través de una alianza de largo plazo.",
      },
      {
        id: "003",
        parrafo:
          "Particularmente, las partes se comprometen a trabajar colaborativamente en el marco del Consejo Nacional de Instituciones Privadas de Educación Superior, para crear valor mutuo a través de una alianza de largo plazo.",
      },
      {
        id: "004",
        parrafo:
          "EL INSTITUTO, se compromete a realizar difusión de CONIFOS, entre sus estudiantes, egresados y titulados, a través de los canales que habitualmente utiliza para difundir diversas iniciativas, o bien, a través de nuevas estrategias de difusión que sean definidas y establecidas en el marco de esta colaboración.",
      },
      {
        id: "005",
        parrafo:
          "EL INSTITUTO, se compromete a las Comunicaciones: coordinar la aprobación de cada publicación en canales externos acerca de CONIFOS, previo acuerdo con los encargados de CONIFOS.",
      },
      {
        id: "006",
        parrafo:
          "Facilitar sus instalaciones para concretar la realización de charlas de difusión, talleres y/o asesorías de CONIFOS.",
      },
      {
        id: "007",
        parrafo:
          "Aportar con su conocimiento de la realidad local/territorial, regional y nacional.",
      },
      {
        id: "008",
        parrafo:
          "Participar activamente en las actividades que CONIFOS, coordine a nivel territorial, regional o nacional.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar:
          "Pasaje Príncipe de Gales 69, Santiago Región Metropolitana, Chile",
      },
    ],
    imagen: "conifos.png",
  },
  {
    id: 16,
    empresa: "Talk Chile",
    contacto: [
      {
        id: "001",
        nombre: "Virginia lbáñez",
        cargo: "Encargada Comercial",
        correo: "informaciones@talkchile.cl",
        telefono: "+56 2 3339 8835",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios y Desarrollo Profesional",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://talkchile.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "El objetivo fundamental de TALKCHILE ser un innovador y tecnológico centro académico de capacitación en idiomas que ofrece un modelo mixto, con clases presenciales y sesiones online. Todos los cursos son con tecnología DLP (Digital Learning Platform), una moderna multiplataforma de pantallas digitales interactivas, dirigida por un docente con contenidos y herramientas que facilitan el aprendizaje. Este método canadiense es utilizado en más de 300 instituciones a nivel mundial. En TalkChile la clave del éxito en el dominio de un idioma, está en ofrecer a los alumnos una experiencia de aprendizaje, en un ambiente social, de confianza y cómodo, que se complementa con la interacción permanente entre el alumno y el profesor, logrando que el proceso se dé naturalmente. Además, la metodología utilizada va siempre acorde al ritmo de cada estudiante con absoluta flexibilidad de horario y forma. TalkChile cuenta con una alianza con Harvard Student Agencie para preparar el TOEFL con toda confianza, con un Staff de profesores certificados.",
      },
      {
        id: "002",
        parrafo:
          "TALKCHILE, otorgará un beneficio a la comunidad del INSTITUTO, en todos los servicios asociados: online grupal, full flexible, online privado y kids detallados en el presente convenio comercial y se extiende a todos los colaboradores, estudiantes, egresados, titulados y familiares directos de estos, mencionando que pertenece al INSTITUTO. Este beneficio corresponde a un 15% de descuento, sobre los valores establecidos en TALKCHILE.",
      },
      {
        id: "003",
        parrafo:
          "Los servicios de capacitación en idiomas, que se enmarcan el convenio, tienen las siguientes características: ",
      },
      {
        id: "004",
        parrafo: "https://www.talkchile.cl/ingles-online-grupal/ ",
      },
      {
        id: "005",
        parrafo: "https://www.talkchile.cl/ingles-presencial-flexible/ ",
      },
      {
        id: "006",
        parrafo: "https://www.talkchile.cl/live-mode/ ",
      },
      {
        id: "007",
        parrafo: "https://www.talkchile.cl/talk-kids/",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar:
          "Casa Matriz: Cruz del Sur 180, Las Condes. Metro Escuela Militar",
      },
    ],
    imagen: "talk_chile.png",
  },
  {
    id: 17,
    empresa: "Los Heroes",
    contacto: [
      {
        id: "001",
        nombre: "Luis Ahumada",
        cargo: "Responsable Sucursal",
        correo: "",
        telefono: "600 400 3030",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.losheroes.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Aportamos valor a través de un círculo virtuoso de contribución social y generación de excedentes que se sostiene sobre una estrategia de rentabilización a través de servicios y transacciones eficientes y gestión del riesgo del crédito.",
      },
      {
        id: "002",
        parrafo:
          "Como institución sin fines de lucro, en Los Héroes nos esforzamos por destinar nuestros recursos – principalmente los excedentes de la gestión – a brindar beneficios sociales a nuestros(as) afiliados(as) y a reinvertirlos para crecer y tener aún más afiliados(as). Los excedentes provienen en gran parte de la alta eficiencia de nuestros servicios y transacciones, a la que se suma una gestión integral de los riesgos. Todo ello conduce a la contribución social, que es el objetivo por el cual existimos.",
      },
      {
        id: "003",
        parrafo:
          "Además, ocupamos todas nuestras capacidades para entender en profundidad qué necesitan nuestros(as) afiliados(as) – pensionados(as) y trabajadores(as) –, de modo de ofrecerles beneficios y soluciones en salud, educación y recreación.",
      },
      {
        id: "004",
        parrafo: "Principales Servicios",
      },
      {
        id: "005",
        parrafo:
          "Pago beneficios IPS y sociales, Pago pensiones AFP y Compañía Seguros, Tarjeta Prepago, Beneficios Los Héroes, Crédito Social, Afiliación, Desafiliación, Asignación Familiar, Licencia Médicas.",
      },
      {
        id: "006",
        parrafo:
          "El presente convenio, brinda este beneficio exclusivo para Afiliados/as a Los Héroes.",
      },
      {
        id: "007",
        parrafo:
          "30% de descuento en programas de estudios para carreras técnicas y profesionales y 25% de descuento en programas de continuidad de estudios, de pregrado online y vespertino.",
      },
      {
        id: "008",
        parrafo:
          "Se deja expresa constancia que el presente convenio está sujeto a una cantidad limitada de cupos definidos anualmente por el Instituto.",
      },
      {
        id: "009",
        parrafo:
          "El descuento antes indicado será aplicable exclusivamente a aquellos postulantes que se matriculen en el programa académico en calidad de alumnos nuevos. Para mantener el beneficio antes indicado durante los períodos académicos siguientes, los beneficiarios, al momento de renovar su matrícula, deberán dar cumplimiento a los requisitos antes indicados.",
      },
      {
        id: "010",
        parrafo:
          "Se deja constancia que los descuentos indicados no son acumulables con otros beneficios otorgados por el Instituto.",
      },
      {
        id: "011",
        parrafo: "¿Cómo puedo acceder al beneficio online? ",
      },
      {
        id: "012",
        parrafo:
          "Afiliado/a deberá enviar un correo a mvilches@ipla.cl detallando la solicitud de beneficio, además de adjuntar copia digital vigente del Rut o Cédula de Identidad.",
      },
      {
        id: "013",
        parrafo: "¿Cómo acceder al beneficio presencial? ",
      },
      {
        id: "014",
        parrafo: "Beneficio exclusivo presentando cédula de identidad.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Maipú N° 559, Los Andes",
      },
    ],
    imagen: "los_heroes.png",
  },
  {
    id: 18,
    empresa: "Fenats",
    contacto: [
      {
        id: "001",
        nombre: "Luis Ahumada",
        cargo: "Responsable Sucursal",
        correo: "confederacionfenatsnacional@gmail.com",
        telefono: "+56 2 2672 7821 ",
        whatsapp: "",
      },
    ],
    oportunidad: "No Disponible",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://fenatsnacional.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "La organización gremial denominada como Confederación FENATS Nacional se fundó el 17 de octubre del 2012, en la comuna de El Quisco de la región de Valparaíso, con el objetivo de vincular a trabajadoras y trabajadores de la salud que trabajen en establecimientos dependientes del Ministerio de Salud. Ese mismo día, la Confederación de Trabajadores de la Salud estableció la ciudad de Santiago de Chile como su domicilio y que su jurisdicción abarcaría todo el territorio nacional, teniendo al día de hoy, más de 20.000 afiliadas y afiliados en 11 regiones del país.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Anibal Letelier 742, Santiago de Chile ",
      },
    ],
    imagen: "los_heroes.png",
  },
  {
    id: 19,
    empresa: "Bci",
    contacto: [
      {
        id: "001",
        nombre: "Gina Astete",
        cargo:
          "Subgerente de Convenios y Alianzas Banco de Crédito e Inversiones",
        correo: "",
        telefono: "+56 2 2692 7000 o 600 692 8000",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficios Financieros",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.bci.cl/personas",
    informacion: [
      {
        id: "001",
        parrafo:
          "Por la presente formalizamos a usted, el interés del Banco de Crédito e Inversiones, en adelante también “el Banco”, en proporcionar a los colaboradores, alumnos y titulados del INSTITUTO PROFESIONAL LIBERTADOR DE LOS ANDES, en adelante también “la Empresa”, una alternativa integral para sus múltiples necesidades financieras (“Programa de Beneficios”), con las siguientes características y condiciones especiales: ",
      },
      {
        id: "002",
        parrafo:
          "El Banco otorgará una atención personal y diferenciada a todo el personal de la Empresa que laboren en forma permanente y en calidad de dependientes de ella, como también a los estudiantes que estén matriculados o se hayan titulado en el INSTITUTO PROFESIONAL LIBERTADOR DE LOS ANDES",
      },
      {
        id: "003",
        parrafo:
          "Los beneficios a los que podrá acceder el personal y alumnos de la Empresa son los que se indican en la presentación que, junto a esta carta, se les entrega y que, para todos los efectos, se entiende formar parte integrante de la misma. Sin perjuicio de lo anterior, la información actualizada de las ofertas y promociones vigentes, les serán informadas periódicamente por nuestro Ejecutivo de Convenios asignado a su empresa, a través de comunicaciones enviadas a su dirección de correo electrónico. ",
      },
      {
        id: "004",
        parrafo:
          "Los beneficios y tarifas promocionales que el Banco podrá aplicar a los colaboradores y alumnos del INSTITUTO PROFESIONAL LIBERTADOR DE LOS ANDES, se mantendrán vigentes mientras dichos trabajadores y alumnos reciban su remuneración mensual por parte de su empleador, a través del depósito de estas en la cuenta corriente contratada y abierta por dicho trabajador y alumno en el Banco Bci. ",
      },
      {
        id: "005",
        parrafo:
          "En el evento que, por cualquier causa, el trabajador y el alumno deje de recibir el depósito de su remuneración en su cuenta corriente BCI, se le aplicarán las tarifas pizarra del Banco, es decir, sin la promoción indicada, las que siempre podrán ser consultadas con el Ejecutivo de Convenios asignado a su empresa o el respectivo ejecutivo de cuentas del cliente;",
      },
      {
        id: "006",
        parrafo:
          "El personal y el alumnado del Instituto que se interese en esta oferta formal de productos y servicios financieros BCI podrá presentar individualmente su solicitud de incorporación a este “Programa de Beneficios” ofrecidos por el Banco y, en virtud de éste, solicitar uno o más productos, los que previa evaluación y análisis conforme a las normas y políticas de riesgo vigentes de BCI, podrá ser aprobada o rechazada.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Av. El Golf 125, Las Condes, Santiago",
      },
    ],
    imagen: "bci.png",
  },
  {
    id: 20,
    empresa: "Andescoop",
    contacto: [
      {
        id: "001",
        nombre: "Christian Aránguiz Garrido",
        cargo: "Jefe comercial",
        correo: "andescoop@andescoop.cl",
        telefono: "+56 342 59 58 00",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://andescoop.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "ANDESCOOP Ltda. y el Instituto, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como, charlas de profesionales, seminarios, talleres, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "004",
        parrafo:
          "ANDESCOOP Ltda., generará los espacios para facilitar pasantías y prácticas, en los establecimientos bajo su jurisdicción y en las materias relativas a su giro comercial.",
      },
      {
        id: "005",
        parrafo:
          "ANDESCOOP Ltda., además, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles, el apoyo brindado por el Instituto.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Las Heras #358 Los Andes, Chile ",
      },
    ],
    imagen: "andescoop.png",
  },
  {
    id: 21,
    empresa: "Sanfecoop",
    contacto: [
      {
        id: "001",
        nombre: "Yasmin Del Carmen Bravo Muñoz",
        cargo: "Representante Comercial de SANFECOOP",
        correo: "contacto@sanfecoop.cl",
        telefono: "+56 342 59 51 00 ó 342 58 93 06 ",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://sanfecoop.cl/web/",
    informacion: [
      {
        id: "001",
        parrafo:
          "SANFECOOP y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la Comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "004",
        parrafo:
          "SANFECOOP generará los espacios para facilitar pasantías y prácticas, en los establecimientos bajo su jurisdicción.",
      },
      {
        id: "005",
        parrafo:
          "SANFECOOP, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
      {
        id: "006",
        parrafo:
          "SANFECOOP, se compromete a otorgar beneficios comerciales en productos y servicios a los colaboradores, estudiantes, egresados y titulados del INSTITUTO.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Maipú # 599, Los Andes ",
      },
    ],
    imagen: "sanfecoop.png",
  },
  {
    id: 22,
    empresa: "Universidad Tecnologica Metropolitana",
    contacto: [
      {
        id: "001",
        nombre: "Lorena Campos Vadell",
        cargo: "",
        correo: "lorena.campos@utem.cl",
        telefono: "+56 2787 7500",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.utem.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "La Universidad Tecnológica Metropolitana es una Institución de Educación Superior que tiene como objetivo fundamental ocuparse, en un nivel avanzado, de la creación, cultivo y transmisión de conocimiento por medio de la investigación básica y aplicada, la docencia y la extensión en tecnología, y de formación académica, científica, profesional y técnica orientada preferentemente al quehacer tecnológico.",
      },
      {
        id: "002",
        parrafo:
          "El deseo común de los suscribientes es coordinar esfuerzos y eventualmente recursos para el mejor logro de acciones y comunicaciones. En este contexto las partes en suscribir el siguiente convenio de colaboración.",
      },
      {
        id: "003",
        parrafo:
          "El Presente convenio, tiene por objeto enmarcar y proveer de un contexto general de las actividades conjuntas que acuerden realizar las partes con la finalidad de establecer vínculos de cooperación con miras a la elaboración, planificación y desarrollo de proyectos, operación y desarrollo de programas, actividades de educación, científico-técnicas y extensión. ",
      },
      {
        id: "004",
        parrafo: "Las materias del convenio serán las siguientes:",
      },
      {
        id: "005",
        parrafo:
          "a)	Intercambio de experiencias profesionales entre las partes.",
      },
      {
        id: "006",
        parrafo:
          "b)	Planificación y ejecución de proyectos conjuntos en los ámbitos de aplicación de la Gestión Pública y Privada.",
      },
      {
        id: "007",
        parrafo:
          "c)	Aporte de información técnica a los propósitos del convenio.",
      },
      {
        id: "008",
        parrafo:
          "d)	Organización y/o participación en seminarios, congresos y otros.",
      },
      {
        id: "009",
        parrafo: "f)	Difusión de resultados de investigación aplicada.",
      },
      {
        id: "010",
        parrafo: "g)	Asesoría y asistencia técnica conjunta en proyectos.",
      },
      {
        id: "011",
        parrafo:
          "h)	Desarrollo de cualquier otra actividad que las partes consideren adecuadas y pertinentes a los objetivos generales del presente convenio.",
      },
      {
        id: "012",
        parrafo:
          "i)	Fortalecer y contribuir a la bidireccionalidad en las actividades propias de este convenio. Esto último, con el fin de garantizar la Vinculación con el Medio entre las partes",
      },
      {
        id: "013",
        parrafo:
          "No obstante, lo anterior, las partes podrán desarrollar programas y proyectos conjuntos o complementarios sobre problemas de interés común, así como también convenir asistencia técnica en aquellas áreas relevantes en cada una de ellas.",
      },
    ],
    estado: "En Renovación",
    direccion: [
      {
        id: "001",
        lugar: "Dieciocho 161 - Santiago, Chile. Metro Moneda",
      },
    ],
    imagen: "unitecme.png",
  },
  {
    id: 23,
    empresa: "Injuv",
    contacto: [
      {
        id: "001",
        nombre: "Paulina Valdivia Altamirano ",
        cargo: "DIRECTORA REGIONAL",
        correo: "",
        telefono: "+56 2 26204700 ",
        whatsapp: "",
      },
    ],
    oportunidad: "Beneficio de Estudios y Tarjeta Jóven",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.injuv.gob.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Organismo de servicio público encargado de colaborar con el Poder Ejecutivo en el diseño, planificación y coordinación de las políticas relativas a los asuntos juveniles. El INJUV orienta su trabajo a los y las jóvenes entre 15 y 29 años, coordinando las políticas públicas de juventud que se originan en el Estado. Asimismo, genera programas que fomentan la inclusión y participación social, el respeto de sus derechos y su capacidad de propuesta, poder de decisión y responsabilidad.",
      },
      {
        id: "002",
        parrafo: "CONSIDERANDO:",
      },
      {
        id: "003",
        parrafo:
          "1º Que el Instituto Nacional de la Juventud se encuentra desarrollando un programa de Empoderamiento e inclusión de jóvenes, denominado Tarjeta Joven, que entrega beneficios a juvenes de nuestro pais que adquieran la tarjeta joven del Instituto Nacional de la Juventud.",
      },
      {
        id: "004",
        parrafo:
          "2º Que, en el marco del desarrollo del programa citado, se suscribió el Convenio de Cooperación que estableció una serie de obligaciones entre el Instituto Nacional de la Juventud e INSTITUTO PROFESIONAL LIBERTADOR DE LOS ANDES.",
      },
      {
        id: "005",
        parrafo:
          '3º Que mediante la Resolución Exenta Nº 1.110 de 29 de Octubre de 2021 el Instituto Nacional de la Juventud aprobó las cláusulas de aplicación general para este tipo de Convenios, denominadas "Condiciones y Términos Generales para Convenios Tarjeta Joven", las cuales se entienden incorporadas al convenio suscrito por las partes.',
      },
      {
        id: "006",
        parrafo:
          "4º Que, mediante la Resolución Exenta Nº 942 de 21 de Diciembre de 2020, de este Servicio, se aprobó el nuevo Manual de Procedimiento Administrativo Actualizado de la iniciativa social Tarjeta joven del Departartamento de Coordinación Programática del Instituto Nacional de la Juventud.",
      },
      {
        id: "007",
        parrafo:
          "5º Que, estando dentro de las facultades que me otorga la Ley 19.042, que crea el Instituto Nacional de la Juventud, el D.F.L Nº 01 del 30 de enero de 1992 del Ministerio Social y Familia, que fijala estructura interna del servicio y la Resolución RA Nº 423/156/2021 del Instituto Nacional de la Juventud.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Nueva York #9, Metro Universidad de Chile, Santiago Centro",
      },
    ],
    imagen: "injuv.png",
  },
  {
    id: 24,
    empresa: "Condomino Don Aurelio Calle Larga",
    contacto: [
      {
        id: "001",
        nombre: "Carlos Álamos Guerra ",
        cargo: "Presidente",
        correo: "",
        telefono: "No disponible ",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "No disponible",
    informacion: [
      {
        id: "001",
        parrafo:
          "El CONDOMINIO y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas organizaciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la Comunidad. ",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como ofertas laborales, prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "004",
        parrafo:
          "El CONDOMINIO, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
      {
        id: "005",
        parrafo:
          "El CONDOMINIO. además. se compromete a coordinar la comunicación de ofertas 'aborales y/o prácticas profesionales, pasantías, con la Encargada de Vinculación con el Medio del INSTITUO, para que ella pueda difundir entre la comunidad del Instituto, las distintas vacantes laborales disponibles, por los medios que sean posibles.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Calle Larga, Valparaíso",
      },
    ],
    imagen: "cond_don_aurelio_cl.png",
  },
  {
    id: 25,
    empresa: "ACONCAGUANEWS",
    contacto: [
      {
        id: "001",
        nombre: "Cristhian Ramiro Reinoso Espinoza ",
        cargo: "Representante Comercial de Aconcagua News",
        correo: "",
        telefono: "+56 342 42 84 53 y +56 9 8239 5721",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://aconcaguanews.cl/portal/",
    informacion: [
      {
        id: "001",
        parrafo:
          "ACONCAGUANEWS y el INSTITUTO, de acuerdo con sus objetivos de desarrollo, vienen en acordar suscribir el presente Convenio de Colaboración, que permitirá a ambas instituciones apoyarse mutuamente en la realización de actividades que faciliten el logro de sus metas institucionales y estrechar vínculos entre ambos y además con la Comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Que, en el presente convenio de cooperación, se establecen las bases para proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales y pasantías, tendientes a facilitar y promover el desarrollo y formación de los miembros de sus comunidades de intervención.",
      },
      {
        id: "003",
        parrafo:
          "Por medio de este Convenio, las partes acuerdan proyectar, coordinar y desarrollar diversas actividades, tales como prácticas profesionales, charlas de profesionales, pasantías tendientes a facilitar y promover el desarrollo y formación de los miembros de sus respectivas comunidades.",
      },
      {
        id: "004",
        parrafo:
          "CONCAGUANEWS, otorgará una prestación de servicios al INSTITUTO, en todos los servicios asociados a publicidad, cuyo valor asociado es acordado entre las partes y reajustable según corresponda.",
      },
      {
        id: "005",
        parrafo:
          "ACONCAGUANEWS generará los espacios para facilitar pasantías y prácticas, en los establecimientos bajo su jurisdicción.",
      },
      {
        id: "006",
        parrafo:
          "CONCAGUANEWS, se compromete a otorgar al Instituto las facilidades para difundir y promocionar sus carreras, capacitaciones y acciones de extensión dentro de sus dependencias y usuarios, asimismo promover en las diversas actividades que desarrolle y por los medios que sean posibles.",
      },
    ],
    estado: "EN PROCESO DE FIRMA",
    direccion: [
      {
        id: "001",
        lugar: "Yerbas Buenas #214, Los Andes",
      },
    ],
    imagen: "aconcagua_news.png",
  },
  {
    id: 26,
    empresa: "Banigualdad",
    contacto: [
      {
        id: "001",
        nombre: "No disponible",
        cargo: "",
        correo: "contacto@banigualdad.cl",
        telefono: "+56 2 2450 5000  ",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://banigualdad.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Banigualdad surgió el 2006 en la región Metropolitana como una alternativa de apoyo para quienes emprenden por necesidad dentro de situaciones de vida vulnerables, sin oportunidades y sin acceso a financiamiento en la banca. Inspirándose en el modelo del “Banco de los Pobres” de Muhammad Yunus, Banigualdad inició la entrega de pequeños préstamos o microcréditos junto a un programa de capacitación a grupos de emprendedores como una forma de entregar herramientas de superación, a cientos de personas que necesitan salir adelante a través del emprendimiento.",
      },
      {
        id: "002",
        parrafo: "En revisión.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Orrego Luco 0140, Providencia ",
      },
    ],
    imagen: "banigualdad.png",
  },
  {
    id: 27,
    empresa: "San Esteban",
    contacto: [
      {
        id: "001",
        nombre: "No disponible",
        cargo: "",
        correo: "contacto@banigualdad.cl",
        telefono: "+56 34 2210190 ó +56 34 2210191",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://munisanesteban.cl/home",
    informacion: [
      {
        id: "001",
        parrafo:
          "La Municipalidad de San Esteban entrega a sus vecinos las oportunidades y garantías de vivir en una comuna siempre atenta a sus necesidades, realizando proyectos que permitan un mejor desarrollo de la calidad de vida y el territorio, así como en las diversas áreas del quehacer comunal.",
      },
      {
        id: "002",
        parrafo: "En revisión.",
      },
    ],
    estado: "Vigente",
    direccion: [
      {
        id: "001",
        lugar: "Municipalidad de San Esteban Avda. 26 de Diciembre 654",
      },
    ],
    imagen: "san_esteban.png",
  },
  {
    id: 28,
    empresa: "Gendarmería",
    contacto: [
      {
        id: "001",
        nombre: "Claudio Andrés Menares Cortés ",
        cargo: "Suboficial Mayor Gendarmería de Chile, CET Putaendo",
        correo: "sara.delgado@gendarmeria.cl",
        telefono: "+56 2 916 3000 ó 342 50 10 46 ",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.gendarmeria.gob.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Por el presente instrumento, la Gendarmería de Chile a través del CENTRO DE EDUCACIÓN Y TRABAJO – CET de la Comuna de Putaendo, de acuerdo con sus objetivos de desarrollo, acuerda con el Instituto Profesional Libertador de Los Andes – IPLA llevar a cabo el presente Convenio de Colaboración, que permitirá a ambas instituciones recibir apoyo en la realización de sus metas y estrechar vínculos con la comunidad.",
      },
      {
        id: "002",
        parrafo:
          "Por medio de este convenio, la Gendarmería de Chile a través del CENTRO DE EDUCACIÓN Y TRABAJO – CET de la Comuna de Putaendo, en conjunto con el IPLA, acuerdan proyectar, coordinar y desarrollar actividades tendientes a facilitar, promover el desarrollo y formación de los miembros de ambas instituciones.",
      },
      {
        id: "003",
        parrafo:
          "El IPLA fomentará en el establecimiento del Centro de Educación y Trabajo – CET de la Gendarmería de Chile de la Comuna de Putaendo, un proceso de articulación curricular en las carreras técnicas afines, soporte-técnico académico, orientación vocacional y competencias técnicas a los funcionarios y colaboradores en general.",
      },
      {
        id: "004",
        parrafo:
          "La Gendarmería de Chile a través del CENTRO DE EDUCACIÓN Y TRABAJO – CET de la Comuna de Putaendo, facilitará el ingreso de los estudiantes del IPLA, para la realización de actividades y trabajos académicos, como investigaciones para los Seminarios y Proyectos de Títulos, pasantías y prácticas profesionales. Por su parte, la Gendarmería de Chile a través del CENTRO DE EDUCACIÓN Y TRABAJO – CET de la Comuna de Putaendo, garantizará la protección y seguridad necesaria en todo momento a los estudiantes IPLA se encuentren dentro del recinto y/o espacio definido para el cumplimiento de sus actividades y trabajos académicos diarios y en el plazo estipulado para alcanzar los objetivos planteados.",
      },
      {
        id: "005",
        parrafo:
          "El IPLA, podrá convenir con, la Gendarmería de Chile a través del CENTRO DE EDUCACIÓN Y TRABAJO – CET de la Comuna de Putaendo, dictar Cursos y Talleres relacionados con las áreas de acción y ámbitos de influencia que se definen en el ejercicio de sus funciones, acordando el objetivo que persigue y a un costo razonable por participante.",
      },
      {
        id: "006",
        parrafo: "En Revisión",
      },
    ],
    estado: "EN PROCESO DE FIRMA",
    direccion: [
      {
        id: "001",
        lugar:
          "Littré Quiroga Carvajal (ex Rosas) 1264, Santiago de Chile, Dirección de Zona: San Martín 786, Putaendo",
      },
    ],
    imagen: "gendarmeria.png",
  },
  {
    id: 29,
    empresa: "Gendarmería",
    contacto: [
      {
        id: "001",
        nombre: "Clodomiro Vásquez Ahumada",
        cargo: "Presidente",
        correo: "",
        telefono: "+56 2 2725 5066 ó +56 2 232 58 934",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios. Adicional, los estudiantes podrán hacer Prácticas profesionales y Proyectos o Seminarios de Titulación",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://suplant.cl/wp/",
    informacion: [
      {
        id: "001",
        parrafo:
          "El presente Convenio tiene por objetivo establecer los términos y alcances de la colaboración entre las partes, en el ámbito de sus respectivas competencias y de acuerdo con sus políticas institucionales, con la finalidad de efectuar actividades conjuntas orientadas a fortalecer su función social, a través del intercambio de información, conocimientos, experiencias, apoyo técnico, investigación, asesorías y capacitación, según sean las necesidades y prioridades de cada una de las partes, priorizando aquellas actividades que se orienten a las actividades de vinculación con el medio. Con este propósito, las partes evaluarán y definirán formas concretas o específicas de colaboración, las que podrán ser, entre otras, las siguientes: (alcance general para los miembros de “SUPLAT” y sus familiares)",
      },
      {
        id: "002",
        parrafo:
          "a)	Docencia y Capacitación: Organización, dictación y participación en capacitaciones, cursos, talleres y programas de Educación Ejecutiva como formación de Técnico de Nivel Superior, ingeniería y otras carreras profesionales que ofrece y tiene disponibles el “IPLA”",
      },
      {
        id: "003",
        parrafo:
          "b)	Descuentos en matriculas y aranceles, de acuerdo con lo establecido en el reglamento financiero interno “IPLA”, los cuales oscilan desde un 10% hasta un 25%. Otros descuentos especiales aplican bajo condiciones excepcionales que determina el “IPLA” con los estudiantes;",
      },
      {
        id: "004",
        parrafo:
          "c)	Las partes podrán desarrollar mesas de trabajo a fin de poder promover actividades, programas y/o proyectos de colaboración para fortalecer relaciones y generar oportunidades de beneficio mutuo.",
      },
      {
        id: "005",
        parrafo:
          "d)	Oportunidades de crecimiento en la formación profesional: Organización, dictación y participación en programas de Educación Ejecutiva como Diplomados, Magíster, Master, Doctorados y Post Doctorados, Cursos, Seminarios, Congresos, Foros, Talleres y/o reuniones profesionales y/o científicas, estipulados en otros convenios vigentes que tiene el “IPLA” para su comunidad educativa;",
      },
      {
        id: "006",
        parrafo:
          "e)	Investigación: Estudio, desarrollo, cooperación e implementación de proyectos o programas específicos de investigación",
      },
      {
        id: "007",
        parrafo: "f)	Asesoría: Cooperación y asistencia técnica recíproca",
      },
      {
        id: "008",
        parrafo:
          "g)	Oportunidad para los estudiantes regulares y vigentes “IPLA” para el desarrollo de pasantías y práctica profesional;",
      },
      {
        id: "009",
        parrafo:
          "h)	Intercambio de información relativa a cursos, conferencias, seminarios, y demás actividades de naturaleza académica ofrecidas por ambas instituciones;",
      },
      {
        id: "010",
        parrafo:
          "i)	Extensión y alcance sobre las invitaciones que recibe el “IPLA” para técnicos nacionales o extranjeros de “SUPLANT” y sus familiares, a participar en cursos, seminarios, conferencias, investigaciones o proyectos especiales que se realicen, derivados de otros convenios vigentes que tiene el “IPLA” con otras instituciones de Educación Superior;",
      },
      {
        id: "011",
        parrafo:
          "j)	Uso y disposición de la infraestructura “IPLA”, bajo los acuerdo y condiciones que se deriven de la negociación, con precios y atenciones de convenio vigentes en “IPLA”;",
      },
      {
        id: "012",
        parrafo:
          "k)	Las partes, podrán compartirse información de interés para generar campañas y/o publicidad de los productos y servicios que las instituciones consideren y ofrezcan como parte de los beneficios derivados del presente convenio (número telefónicos, correos electrónicos, otros). Asimismo, las partes se comprometen al uso racional, resguardo y privacidad de esta;",
      },
      {
        id: "013",
        parrafo:
          "l)	Cualquier otra modalidad de cooperación que sea considerada de interés por ambas partes",
      },
      {
        id: "014",
        parrafo:
          "Las partes decidirán la forma más eficiente de seleccionar los proyectos o programas a realizar y los participantes de estos, con el objeto de garantizar su éxito y continuidad. En cualquier caso, ambas partes se comprometen a difundir ampliamente, ya sea en conjunto o individualmente estos programas y/o proyectos a través de los distintos medios de comunicación que se consideren previo acuerdo.",
      },
      {
        id: "015",
        parrafo:
          "Las partes celebrarán convenios específicos para desarrollar las actividades, programas y/o proyectos de colaboración que se acuerden en el marco de este convenio. Los convenios específicos deberán establecer la descripción de cada proyecto o programa señalando los objetivos, las unidades académicas responsables del mismo, el financiamiento, el plazo de ejecución y en general todas las estipulaciones que se consideren necesarias para su adecuada realización.",
      },
    ],
    estado: "EN PROCESO DE FIRMA",
    direccion: [
      {
        id: "001",
        lugar: "Rancagua 215, 2100731 Los Andes, Valparaíso",
      },
    ],
    imagen: "suplant.png",
  },
  {
    id: 30,
    empresa: "Universidad Viña del Mar",
    contacto: [
      {
        id: "001",
        nombre: "Paulina Zamorano Varea ",
        cargo: "Representantes de la Universidad Viña del Mar",
        correo: "",
        telefono: "800 37 4100",
        whatsapp: "",
      },
      {
        id: "002",
        nombre: "María Francisca Huber ",
        cargo: "Representantes de la Universidad Viña del Mar",
        correo: "",
        telefono: "800 37 4100",
        whatsapp: "",
      },
    ],
    oportunidad:
      "Beneficio de Estudios y continuidad de estudios para egresados en Carreras Técnicas",
    beneficio: "BENEFICIOS Y BENEFICIARIOS",
    paginaweb: "https://www.uvm.cl/",
    informacion: [
      {
        id: "001",
        parrafo:
          "Las instituciones que suscriben el presente Convenio Específico, con la intención de fortalecer la educación terciaria en la región de Valparaíso y valorar las trayectorias formativas de los potenciales interesados en la continuidad de estudios entre los niveles educativos que representan ambas casas de estudios, han acordado implementar un proceso de reconocimiento automático del título de Técnico de Nivel Superior emitido por Instituto Profesional Libertador de Los Andes, permitiendo la articulación y continuidad de estudios de sus titulados en carreras que imparta la Universidad en todas aquellas especialidades que sean afines y cuyos términos se expresan a continuación:",
      },
      {
        id: "002",
        parrafo:
          "El objeto del presente Convenio es el reconocimiento automático de los estudios que posean los titulados de las distintas carreras que imparta el Instituto Profesional Libertador de Los Andes y que tengan desarrollos afines en carreras que imparta la Universidad.",
      },
      {
        id: "003",
        parrafo:
          "Se entenderá por reconocimiento automático a la validación del Título de Técnico de Nivel Superior por parte de la Universidad a aquellos estudiantes titulados de alguna carrera impartida por el Instituto Profesional Libertador de los Andes y cuyos programas guarden una correspondencia disciplinar con carreras impartidas por la Universidad, conforme al análisis de contenidos curriculares previamente realizado. ",
      },
      {
        id: "004",
        parrafo:
          "Este reconocimiento permitirá al titulado del Instituto Profesional libertador de Los Andes ingresar como alumno regular a un programa de continuidad de estudios en aquellas carreras que cuenten con dicha modalidad en la Universidad. ",
      },
      {
        id: "005",
        parrafo:
          "Para facilitar este proceso, la institución de origen Instituto Profesional Libertador de Los Andes entregará a UVM la nómina de estudiantes titulados que potencialmente pudieran acceder a este reconocimiento automático. ",
      },
      {
        id: "006",
        parrafo:
          "Las carreras susceptibles de conceder reconocimiento automático del título de Técnico de Nivel Superior serán determinadas por ambas partes de manera anual.",
      },
    ],
    estado: "EN PROCESO DE FIRMA",
    direccion: [
      {
        id: "001",
        lugar: "Agua Santa 7055, Viña del Mar",
      },
    ],
    imagen: "uni_viña_mar.png",
  },
];
