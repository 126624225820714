import React from "react";
import docente1 from "../../../assets/images/andrea_zuniga.jpg";
import docente2 from "../../../assets/images/barbara_henriquez.jpg";
import docente3 from "../../../assets/images/elizabeth_varas.jpg";
import docente4 from "../../../assets/images/daniel_bartch.jpg";
import docente5 from "../../../assets/images/margarita_jimenez.jpg";
import docente6 from "../../../assets/images/maria_covarrubias.jpg";
import docente7 from "../../../assets/images/maria_paz.jpg";
import docente8 from "../../../assets/images/maria_vilches.jpg";
import docente9 from "../../../assets/images/maribel_diaz.jpg";
import docente10 from "../../../assets/images/sandra_santander.jpg";
import docente11 from "../../../assets/images/segundo_veas.jpg";
import docente12 from "../../../assets/images/marlene_leyton.jpg";
import docente13 from "../../../assets/images/paola_valdivia.jpg";
import docente14 from "../../../assets/images/gabriel_diaz.jpg";

const NuestrosDocentes = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Nuestra Gente
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="row py-3">
            <h2 className="display-6 fw-bolder titulo">Nuestra Gente</h2>
          </div>

          <div className="row py-3">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente1}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente2}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente3}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente4}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente5}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente6}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente7}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente8}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente9}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente10}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente11}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente12}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3">
            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente13}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 pb-sm-3">
              <div className="card card-cascade narrower">
                <div className="view view-cascade overlay">
                  <img
                    className="card-img-top"
                    src={docente14}
                    alt="Card image cap"
                  />
                  <a>
                    <div className="mask waves-effect waves-light rgba-white-slight"></div>
                  </a>
                </div>
                <div className="card-body card-body-cascade">
                  <div className="d-flex justify-content-end">
                    <a type="button" className="btn-floating teal btn-primary">
                      <i
                        className="activator fas fa-angle-double-up"
                        aria-hidden="true"
                        title="Ver Mas"
                      ></i>
                    </a>
                  </div>
                </div>
                <div className="card-reveal white overlay">
                  {/*<!-- Content -->*/}
                  <div className="content text-center">
                    <h4 className="card-title font-weight-bold my-4">
                      <i className="fas fa-times text-muted"></i>
                      <small>Perfil</small>
                    </h4>
                    <hr />
                    <p className="card-text justificado px-3 pt-3">
                      Perfil del Docente
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NuestrosDocentes;
