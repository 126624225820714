import React from "react";
import bussinesMan from "../../../assets/images/bussines_man.svg";
import bussinesWonam from "../../../assets/images/bussines_woman.svg";

const NuestraGente = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Nuestra Gente
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container">
          <div className="card card-cascade wider mb-3 py-4">
            <div className="card-body card-body-cascade">
              <div className="row py-2">
                <h4 className="fw-bolder">Nuestra Gente</h4>
                <p>Conoce a quienes cuidan y gestionan nuestro instituto</p>
              </div>
              <div className="row">
                <h4 className="fw-bolder">Directorio</h4>
              </div>

              <div className="row py-1">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            José Luengo Sepúlveda
                          </h6>
                          <p>Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Ignacio Luengo Troncoso
                          </h6>
                          <p>Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Gabriel Díaz Campos
                          </h6>
                          <p>Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Francisco Enrique Aliro Rodríguez Poblette
                          </h6>
                          <p>Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Ricardo Iván Correa Soto
                          </h6>
                          <p>Director</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Rectoría</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Iskander Porra Almeida
                          </h6>
                          <p>Rector</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Vice Rectoría Académica</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Vice Rector</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Secretaría General y Académica</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Marlene Leyton Lazcano
                          </h6>
                          <p>Secretaría General</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Secretaria General</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Dirección de Escuelas</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            María Paz Otárola Figueroa
                          </h6>
                          <p className="mb-0">
                            Director de Escuela de Educación y Ciencias Sociales
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Andrea Zúñiga Roco
                          </h6>
                          <p className="mb-0">
                            Director de Escuela de Administración y Comercio
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Angelina Aragón Rozas
                          </h6>
                          <p className="mb-0">
                            Director de Escuela de Procesos Industriales
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Director de Tecnologia Aplicada</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Jefes de Carreras</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Elizabeth Varas Gallardo
                          </h6>
                          <p>Jefes de Carreras</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Daniel Bartch Sepúlveda (S)
                          </h6>
                          <p>Jefes de Carreras</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Jefes de Carreras</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Jefes de Carreras</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">
                  Administrativos y Unidades de Apoyo
                </h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Maribel Díaz Ayala
                          </h6>
                          <p className="mb-0">
                            Apoyo Administrativo y Financiero
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Sandra Santander Contreras
                          </h6>
                          <p className="mb-0">
                            Apoyo Administrativo y Financiero
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            María Pía Vilches Navia
                          </h6>
                          <p>Adminsión y Vinculación con el Medio</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Vacante
                          </h6>
                          <p>Apoyo Administrativo y Financiero</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row pt-2">
                <h4 className="fw-bolder">Auxiliar</h4>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesMan}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Segundo Veas Ahumada
                          </h6>
                          <p>Auxiliar</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            María Eugenia Covarrubias Fuentes
                          </h6>
                          <p>Auxiliar</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Barbara Henríquez Páez
                          </h6>
                          <p>Auxiliares</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row py-2">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="row">
                      <div className="col-3">
                        <figure className="persona_oficina d-flex justify-content-center mt-3 ms-3">
                          <img
                            className="img-fluid h-100"
                            src={bussinesWonam}
                            alt=""
                          />
                        </figure>
                      </div>
                      <div className="col-9 d-flex justify-content-center px-0">
                        <div className="card__info">
                          <h6 className="card__info--title mb-0 fw-bolder">
                            Margarita Jiménez López
                          </h6>
                          <p>Auxiliar</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NuestraGente;
