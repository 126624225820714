import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Inicio from "../components/Inicio";
import HeaderNav from "../components/layout/HeaderNav";
import Footer from "../components/layout/Footer";
import SobreNosotros from "../components/pages/sobreNosotros/SobreNosotros";
import NuestraHistoria from "../components/pages/sobreNosotros/NuestraHistoria";
import NuestrosDocentes from "../components/pages/sobreNosotros/NuestrosDocentes";
import MisionVisionValores from "../components/pages/sobreNosotros/MisionVisionValores";
import NuestraGente from "../components/pages/sobreNosotros/NuestraGente";
import EscuelaEducacion from "../components/pages/escuelas/EscuelaEducacion";
import EscuelaProcesos from "../components/pages/escuelas/EscuelaProcesos";
import EscuelaAdministracion from "../components/pages/escuelas/EscuelaAdministracion";
import EscuelaTecnologia from "../components/pages/escuelas/EscuelaTecnologia";
import AsuntosEstudiantiles from "../components/pages/estudiantes/AsuntosEstudiantiles";
import GestionRRHH from "../components/pages/escuelas/EscuelaAdministracion/GestionRRHH";
import RedAsociados from "../components/pages/mas/RedAsociados";
import IniciacionAcademica from "../components/pages/estudiantes/IniciacionAcademica";
import SobreAdmision from "../components/pages/admision/SobreAdmision";
import Psicopedagogia from "../components/pages/escuelas/EscuelaEducacion/Psicopedagogia";
import ServicioSocial from "../components/pages/escuelas/EscuelaEducacion/ServicioSocial";
import ContadorAuditor from "../components/pages/escuelas/EscuelaAdministracion/ContadorAuditor";
import PrevencionMedioAmbiente from "../components/pages/escuelas/EscuelaProcesos/PrevencionMedioAmbiente";
import IngComercioInternacional from "../components/pages/escuelas/EscuelaAdministracion/IngComercioInternacional";
import IngAdminRecursosHumanos from "../components/pages/escuelas/EscuelaAdministracion/IngAdminRecursosHumanos";
import TnsEducacionParvularia from "../components/pages/escuelas/EscuelaEducacion/TnsEducacionParvularia";
import TnsTrabajoSocial from "../components/pages/escuelas/EscuelaEducacion/TnsTrabajoSocial";
import TnsAsistEducAdminstracion from "../components/pages/escuelas/EscuelaEducacion/TnsAsistEducAdminstracion";
import TnsCalidadMedioAmbiente from "../components/pages/escuelas/EscuelaProcesos/TnsCalidadMedioAmbiente";
import TnsAdministracionPublica from "../components/pages/escuelas/EscuelaAdministracion/TnsAdministracionPublica";
import TnsPrevenRiesgoMedioAmbiente from "../components/pages/escuelas/EscuelaProcesos/TnsPrevenRiesgoMedioAmbiente";
import TnsComercioInternacional from "../components/pages/escuelas/EscuelaAdministracion/TnsComercioInternacional";
import TnsAdminRecursosHumanos from "../components/pages/escuelas/EscuelaAdministracion/TnsAdminRecursosHumanos";
import PreInscripcion from "../components/pages/admision/PreInscripcion";
import RedEgresados from "../components/pages/estudiantes/RedEgresados";
import BecasIplas from "../components/pages/admision/BecasIplas";
import PlanCovid from "../components/pages/mas/PlanCovid";
import Empleos from "../components/pages/mas/Empleos";
import ActualizarDatosEgresados from "../components/pages/estudiantes/ActualizarDatosEgresados";
import AseguramientoCalidad from "../components/pages/sobreNosotros/AseguramientoCalidad";
import Error404 from "../components/pages/Error404";

export const RouterPrincipal = () => {
  return (
    <BrowserRouter>
      {/* HEADER Y NAVEGACION */}
      <HeaderNav />
      {/* CONTENIDO CENTRAL DE LA WEB */}
      <Routes>
        <Route path="/" element={<Inicio />} />
        {/* enlaces del menu para instituto anterios sobre nosotros */}

        <Route path="/sobre-nosotros" element={<SobreNosotros />} />
        <Route path="/nuestra-historia" element={<NuestraHistoria />} />
        <Route path="/nuestros-docentes" element={<NuestrosDocentes />} />
        <Route
          path="/mision-vision-valores"
          element={<MisionVisionValores />}
        />
        <Route path="/nuestra-gente" element={<NuestraGente />} />
        <Route
          path="/aseguramiento-de-la-calidad"
          element={<AseguramientoCalidad />}
        />

        {/* enlaces del menu escuelas */}
        <Route path="/escuela-educacion" element={<EscuelaEducacion />} />
        <Route
          path="/escuela-procesos-industriales"
          element={<EscuelaProcesos />}
        />

        {/* enlaces del menu estudiantes */}
        <Route
          path="/asuntos-estudiantiles"
          element={<AsuntosEstudiantiles />}
        />
        <Route path="/red-egresados" element={<RedEgresados />} />
        <Route
          path="/red-egresados/actualizacion"
          element={<ActualizarDatosEgresados />}
        />

        {/* enlaces de las escuelas de procesos industriales  */}
        <Route
          path="/escuela-procesos-industriales/prevencion-medio-ambiente"
          element={<PrevencionMedioAmbiente />}
        />
        <Route
          path="/escuela-procesos-industriales/calidad-medio-ambiente"
          element={<TnsCalidadMedioAmbiente />}
        />

        <Route
          path="/escuela-procesos-industriales/prevencion-riesgo-medio-ambiente"
          element={<TnsPrevenRiesgoMedioAmbiente />}
        />

        <Route
          path="/escuela-administracion"
          element={<EscuelaAdministracion />}
        />
        <Route path="/escuela-tecnologia" element={<EscuelaTecnologia />} />

        {/* enlaces de las escuelas de administracion  */}
        <Route
          path="/escuela-administracion/admin-empresas-rrhh"
          element={<GestionRRHH />}
        />
        <Route
          path="/escuela-administracion/contador-auditor"
          element={<ContadorAuditor />}
        />
        <Route
          path="/escuela-administracion/tns-administracion-empresas-rrhh"
          element={<TnsAdminRecursosHumanos />}
        />
        <Route
          path="/escuela-administracion/comercio-internacional"
          element={<IngComercioInternacional />}
        />
        <Route
          path="/escuela-administracion/ing-admin-gestion-rrhh"
          element={<IngAdminRecursosHumanos />}
        />
        <Route
          path="/escuela-administracion/administracion-publica"
          element={<TnsAdministracionPublica />}
        />
        <Route
          path="/escuela-administracion/tns-comercio-internacional"
          element={<TnsComercioInternacional />}
        />
        <Route path="/red-asociados" element={<RedAsociados />} />
        <Route path="/iniciacion-academica" element={<IniciacionAcademica />} />

        {/* Enlaces del Menu Admision */}
        <Route path="/sobre-admision" element={<SobreAdmision />} />
        <Route
          path="/sobre-admision/preinscripcion"
          element={<PreInscripcion />}
        />
        <Route path="/sobre-admision/becas-ipla" element={<BecasIplas />} />

        {/* enlaces de las escuelas de educacion */}
        <Route
          path="/escuela-educacion/Psicopedagogia"
          element={<Psicopedagogia />}
        />
        <Route
          path="/escuela-educacion/Servicio-Social"
          element={<ServicioSocial />}
        />
        <Route
          path="/escuela-educacion/TNS-educacion-parvularia"
          element={<TnsEducacionParvularia />}
        />
        <Route
          path="/escuela-educacion/TNS-trabajo-social"
          element={<TnsTrabajoSocial />}
        />
        <Route
          path="/escuela-educacion/TNS-asistente-educacion-administracion"
          element={<TnsAsistEducAdminstracion />}
        />

        {/* enlaces para menu Mas */}
        <Route path="/covid19" element={<PlanCovid />} />
        <Route path="/empleos" element={<Empleos />} />

        <Route path="*" element={<Error404 />} />
      </Routes>

      {/* FOOTER */}

      <Footer />
    </BrowserRouter>
  );
};
