import React from "react";

const Empleos = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-2 text-white fw-bolder mx-5 ">
              Trabaja con Nosotros
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-4">
        <div class="card card-cascade wider reverse py-4">
          <div class="card-body card-body-cascade text-center">
            <h2 class="fw-bolder mb-3 text-center pt-md-3 titulo">
              <strong translate="carrer_title7">
                Oportunidades de Trabajo
              </strong>
            </h2>
            <div class="row">
              <h4 class="fw-bold mb-3 mx-md-3 mx-2 justificado pt-md-5 subtitulo">
                SSO-2024-001 Profesor de Psicología
              </h4>
            </div>

            <div class="row">
              <p class="mb-3 mx-md-3 mx-2 justificado">
                IPLA – Los Andes, Región Valparaíso, Chile
              </p>
            </div>

            <div class="row ">
              <div class="col-md-10 ps-4">
                <p class="justificado">Fecha de Publicación: 02/01/2024</p>
              </div>
              <div class="col-md-2">
                <a href="" type="button" className="btn boton_personalizado">
                  Aplicar
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Empleos;
