import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import JefeCarrera from "../../../../assets/images/andrea_zuniga.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Swal from "sweetalert2";

const TnsAdministracionPublica = () => {
  const baseURL = "http://51.81.213.64:3005/email";

  const navigate = useNavigate();
  const [show, setShow] = useState(false); //asi se llama al modal

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const enviar = handleSubmit(async (data) => {
    console.log(data);
    try {
      await axios
        .get(`${baseURL}`, {
          params: {
            data,
            subject: "TNS en Administración Pública",
          },
        })
        .finally((response) => {
          //console.log(response);
          Swal.fire({
            icon: "success",
            title: "Descargando Malla. Gracias por Preferirnos",
            showConfirmButton: false,
            timer: 2000,
          });
          window.open(
            "/data/mallas/Carrera_TNS_ADM_PÚBLICA_2023.pdf",
            "_blank"
          );
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error,
      });
    }
  });
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-center">
            <div className=" display-4 text-white fw-bolder mx-5 ">
              Administración Pública
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row pb-3">
            <h2 className="display-6 fw-bolder titulo">
              TNS en Administración Pública
            </h2>
          </div>

          <div className="row pt-3">
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Modalidad</strong>
                </div>
                <div className="card-body align-self-center d-flex align-items-center px-0">
                  <div className="row">
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-chalkboard-teacher fa-2x"></i>
                    </div>
                    <div className="col-md-5 px-0 d-flex align-items-center">
                      <a
                        className="text-decoration-none text-dark px-0 small"
                        href=""
                      >
                        <span>Presencial / Virtual</span>
                      </a>
                    </div>
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-desktop fa-2x "></i>
                    </div>
                    <div className="col-md-3 d-flex align-items-center px-0">
                      <a
                        className="text-decoration-none text-dark px-0 small"
                        href=""
                      >
                        <span>Online</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Duración / Horarios</strong>
                </div>
                <div className="card-body align-self-center d-flex align-items-center px-0">
                  <div className="row">
                    <div className="col-md-3 align-self-center d-flex justify-content-center">
                      <i className="fas fa-calendar-alt fa-2x"></i>
                      <i className="far fa-clock fa-2x"></i>
                    </div>
                    <div className="col-md-9 d-flex align-items-center px-0">
                      <a
                        className="text-decoration-none text-dark px-0"
                        href=""
                      >
                        <span className="small">
                          Duración 5 Semestres (2,5 Años) <br />
                          <span
                            className="text-decoration-none text-dark px-0"
                            href=""
                          >
                            Horario de 19:00 Hrs a 22:00 Hrs
                          </span>{" "}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Requisitos</strong>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-2 align-self-center d-flex justify-content-center">
                      <i className="fas fa-clipboard-list fa-2x"></i>
                    </div>
                    <div className="col-md-10 d-flex align-items-center px-0">
                      <ul className="list-group list-group-flush small">
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 ">Licencia de 4to Medio</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2">Notas Certificadas</span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2 color_hover">
                            Cédula de Identidad (ambos lados)
                          </span>
                        </li>
                        <li className="list-group-item py-0">
                          <i className="fas fa-check mr-2"></i>
                          <span className="ms-2">
                            2 Fotografías tamaño carnet
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-4">
            <div className="col-md-6">
              <div className="card card_sombra h-100">
                <div className="card-header text-center">
                  <strong className="subtitulo">Perfil Profesional</strong>
                </div>
                <div className="card-body">
                  <p className="justificado">
                    Los egresados de esta carrera desarrollarán competencias,
                    habilidades y aptitudes que los transformarán en Técnicos de
                    Nivel Superior competentes en el dominio de técnicas aptas
                    para apoyar tanto las actividades relacionadas con las
                    funciones propias del profesional responsable de la
                    planificación, organización, dirección, control, evaluación,
                    retroalimentación de los diversos procesos administrativos,
                    que se ejecutan en la Administración Pública así como
                    aquellas funciones más directamente relacionadas con el
                    desempeño del personal y de los funcionarios públicos.
                  </p>
                  <p className="justificado">
                    El objetivo de esta carrera es entregar conocimientos en los
                    variados ámbitos que conforman la Administración Pública,
                    proporcionando a los alumnos herramientas prácticas que les
                    permitan participar en la ejecución de políticas públicas y
                    en el ejercicio eficiente de las tareas propias de la
                    especialidad, con énfasis en aspectos administrativos y
                    presupuestarios.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="card card_sombra h-100 px-0">
                  <div className="card-header text-center">
                    <strong className="subtitulo">Campo Laboral</strong>
                  </div>
                  <div className="card-body">
                    <p className="justificado">
                      Podrá Trabajar en diferentes tipos de organizaciones:
                      <br />
                      Puestos administrativos en toda la gama de estamentos
                      públicos como Gobernaciones, Intendencias, Alcaldías, ONG,
                      Municipalidades, Gobierno Central, Secretarías Regionales
                      Ministeriales, Ministerios. En el ámbito privado, este
                      profesional será un apoyo importante a la gestión interna
                      de las empresas.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <div className="card card_sombra h-100 px-0">
                  <div className="card-header text-center">
                    <strong className="subtitulo">Malla Curricular</strong>
                  </div>
                  <div className="card-body">
                    <p className="justificado">
                      Los estudiantes IPLA, deben solicitar la malla indicando
                      tu número RUT y formato de solicitud al correo
                      electrónico: mdiaz@ipla.cl.
                    </p>
                    <div className="row">
                      <div className="col-md-1 align-self-center d-flex justify-content-center">
                        <i className="fas fa-users fa-2x"></i>
                      </div>
                      <div className="col-md-9 d-flex align-items-center align-self-center">
                        <p className="justificado py-0 my-0">
                          Docentes de la Carrera
                        </p>
                      </div>
                      <div className="col-md-2">
                        <button
                          className="btn btn-primary"
                          onClick={() => setShow(true)}
                        >
                          <i className="fas fa-eye"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-3">
                <h3 className="justificado fw-bolder">
                  Certificados Intermedios:
                </h3>
                <ul className="list-group list-group-flush small">
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2 ">
                      Certificado Intermedio en Remuneraciones y Previsión
                    </span>
                  </li>
                  <li className="list-group-item py-0">
                    <i className="fas fa-check mr-2"></i>
                    <span className="ms-2">
                      Certificado Intermedio en Gestión Estratégica del Capital
                      Humano
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="parallax_docentes_administracion_comercio"></section>

      <section>
        <div className="container">
          <div className="row pt-5">
            <h2 className="display-5 fw-bolder titulo">
              Docentes de la Carrera
            </h2>
          </div>
          <div className="row pb-3">
            <div className="col-md-4">
              <ul className="list-group list-group-flush mx-2">
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Catalina Soto Bustos</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Claudia Andrea Sepúlveda Mella</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Darinka Makarena Zamora Caro</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Francisco Javier Ossa Miranda</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Javiera Andrea Carmona Ortega</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Judith Conillie Ortiz Urbina</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Marcela Paz Orellana González</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">
                    Paola Patricia Valdivia Rodríguez
                  </span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">
                    Patricio Alejandro Moreno Bustamente
                  </span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Roberto Alejandro Mura Ávila</span>
                </li>
                <li className="list-group-item">
                  <i className="fas fa-check mr-2"></i>
                  <span className="ms-2">Rocío Trinidad Fuentes Quiroz</span>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <div className="row py-4">
                <div className="container">
                  <div className="card card-sombra py-4">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="view overlay rounded z-depth-2 mb-lg-0 mb-4">
                            <img
                              className="img-fluid w-100"
                              src={JefeCarrera}
                              alt="Sample image"
                            />
                            <a>
                              <div className="mask rgba-white-slight"></div>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <a className="justificado">
                            <h6 className="font-weight-bold mb-3">
                              <span className="fw-bolder titulo">
                                {" "}
                                Jefe de Carrera
                              </span>
                            </h6>
                          </a>
                          <h3 className="fw-bolder mb-3 justificado">
                            <strong className="subtitulo">
                              Andrea Zúniga Roco
                            </strong>
                          </h3>
                          <p className="justificado">
                            Ingeniero en Administración de Empresas
                          </p>
                          <p className="justificado">
                            Técnico en Administración de Empresas
                          </p>
                          <p className="justificado">
                            Mención Recursos Humanos
                          </p>
                          <p className="justificado">
                            Profesor de Educación Media
                          </p>
                          <p className="justificado">Contador General</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        centered="true"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title name="example-custom-modal-styling-title">
            Descargar Malla
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={enviar}>
            <div className="container">
              <div className="row pb-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="Names">Nombres</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("Names", { required: true })}
                      placeholder="Nombres"
                    />
                    {errors.Names && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="Apellidos">Apellidos</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("Apellidos", { required: true })}
                      placeholder="Nombres"
                    />
                    {errors.Apellidos && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pb-3">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="Telefono">Telefono</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("Telefono", { required: true })}
                      placeholder="Telefono"
                    />
                    {errors.Telefono && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="Documento">Documento</label>
                    <input
                      type="text"
                      className="form-control"
                      {...register("Documento", { required: true })}
                      placeholder="Documento"
                    />
                    {errors.Documento && (
                      <span className="text-danger text-bold">
                        Campo requerido
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="row pb-1">
                <div className="form-check d-flex justify-content-end">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="pasaporte"
                    {...register("pasaporte")}
                  />
                  <label className="form-check-label" htmlFor="pasaporte">
                    ¿Pasaporte?
                  </label>
                </div>
              </div>

              <div className="row pb-3">
                <div className="form-group">
                  <label htmlFor="Documento">Correo Electronico</label>
                  <input
                    type="text"
                    className="form-control"
                    {...register("Email", { required: true })}
                    placeholder="Email"
                  />
                  {errors.Email && (
                    <span className="text-danger text-bold">
                      Campo requerido
                    </span>
                  )}
                </div>
              </div>

              <div className="row px-3">
                <div className="alert alert-warning" role="alert">
                  Nos importa tu interés por esta carrera, en breve uno de
                  nuestros ejecutivos de Admisión te estará contactando y te
                  brindará mayor información
                </div>
              </div>
            </div>

            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Solicitar
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TnsAdministracionPublica;
