import React from "react";
import Valores from "../../../assets/images/valores.jpg";
import Vision from "../../../assets/images/vision.jpg";
import Mision from "../../../assets/images/mision.jpg";

const MisionVisionValores = () => {
  return (
    <>
      <section className="d-flex banner_pages banner_nuestro_instituto">
        <div className="row">
          <div className="col-md-2 align-self-lg-center">
            <i className="fas fa-shapes fa-4x text-white mx-5 "></i>
          </div>
          <div className="col-md-10 align-self-lg-center">
            <div className=" display-2 h2-sm text-white fw-bolder mx-lg-5 ms-sm-2">
              Misión, Visión, Valores
            </div>
          </div>
        </div>
        <div className="row"></div>
      </section>

      <section>
        <div className="container py-4">
          <div className="card card-cascade wider mb-3">
            <div className="card-body card-body-cascade">
              <div className="row mx-3 justificado">
                <p>
                  Desde 1982, el{" "}
                  <span className="fw-bolder">
                    Instituto Profesional Libertador de Los Andes
                  </span>{" "}
                  se preocupa por formar y contribuir al bienestar de las
                  personas en el Valle de Aconcagua, aportando experiencia y
                  oportunidades a toda su comunidad. Nuestra esencia se
                  convierte en un creciente compromiso ofreciendo educación
                  superior a personas que tienen el interés por mejorar su
                  calidad de vida, su desarrollo personal y profesional optando
                  por nuestras carreras técnicas o profesional.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-4">
        <div className="container">
          <div className="card card-cascade wider">
            <div className="card-body card-body-cascade">
              <div className="row">
                <div className="col-lg-4">
                  <div className="view rounded sombra_img_card">
                    <img src={Mision} alt="" className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-lg-8">
                  <h6 className=" mb-3">
                    <i className="fas fa-city pr-2"></i>
                    <span> Instituto Profesional Libertador de Los Andes </span>
                  </h6>
                  <h3 className="font-weight-bold mb-3 text-justify sombra_texto2">
                    <strong>Misión</strong>
                  </h3>
                  <p className="justificado">
                    “Formar Profesionales y Técnicos de Nivel Superior, a través
                    del Modelo Educativo basado en competencias, que respondan a
                    las exigencias del mundo laboral y a las áreas del
                    conocimiento comprometidos en el entorno social, siendo
                    preparados en una metodología constructivista en habilidades
                    cognitivas, sociales, tecnológicas y en valores y que
                    contribuyan a su integración, compromiso, tolerancia,
                    libertad de expresión y pensamiento crítico.
                    <br />
                    Dirigido a estudiantes trabajadores de las provincias de
                    Aconcagua, en las áreas de Administración y Comercio,
                    Ciencias Sociales, Educación y Tecnología, en la modalidad
                    presencial y a distancia para las jornadas diurna y
                    vespertina”
                  </p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-8">
                  <h6 className=" mb-3">
                    <i className="fas fa-city pr-2"></i>
                    <span> Instituto Profesional Libertador de Los Andes </span>
                  </h6>
                  <h3 className="font-weight-bold mb-3 text-justify sombra_texto2">
                    <strong>Visión</strong>
                  </h3>
                  <p className="justificado">
                    “Ser una institución reconocida por su excelencia en la
                    formación académica, así mismo por su pertinencia en los
                    requerimientos del mundo laboral de los profesionales y
                    técnicos de nivel superior”
                  </p>
                </div>
                <div className="col-lg-4">
                  <div className="view rounded sombra_img_card">
                    <img src={Vision} alt="" className="img-fluid w-100" />
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-4">
                  <div className="view rounded sombra_img_card">
                    <img src={Valores} alt="" className="img-fluid w-100" />
                  </div>
                </div>
                <div className="col-lg-8">
                  <h6 className=" mb-3">
                    <i className="fas fa-city pr-2"></i>
                    <span> Instituto Profesional Libertador de Los Andes </span>
                  </h6>
                  <h3 className="font-weight-bold mb-3 text-justify sombra_texto2">
                    <strong>Valores</strong>
                  </h3>
                  <p className="justificado">
                    <span className="fw-bolder">Compromiso: </span>En el IPLA
                    definimos el compromiso como la capacidad de las personas de
                    ser conscientes del rol que cumple dentro de la institución
                    y de la importancia que tiene el cumplir con las tareas y
                    funciones que le corresponden, actuando siempre en
                    concordancia con las responsabilidades asumidas y estar
                    siempre dispuestos a satisfacer las necesidades de las
                    personas y la institución en las labores cotidianas. El
                    compromiso para nosotros implica un esfuerzo constante hacia
                    el logro de un objetivo, superando los que interfieran. En
                    IPLA cumplimos lo que ofrecemos.
                  </p>
                  <p className="justificado">
                    <span className="fw-bolder">
                      Libertad de expresión y de pensamiento:{" "}
                    </span>
                    En IPLA existe una permanente preocupación por dar espacios
                    a la libre manifestación de las ideas y opiniones, siempre
                    en el marco del respeto por el otro y a los derechos de las
                    personas. Trabajamos siempre inspirados en el principio de
                    protección a la libertad de toda la comunidad institucional
                    de expresarse y desarrollar libremente sus ideas y
                    opiniones, defendiendo el libre desarrollo del pensamiento
                    en espacios de diálogo en un escenario de tolerancia a la
                    diversidad y contrarios a la violencia. En IPLA todas las
                    ideas tienen cabida y son respetadas.
                  </p>
                </div>
              </div>
              <div className="row">
                <p className="justificado">
                  <span className="fw-bolder">Integridad: </span>En el IPLA
                  entendemos el actuar con integridad a la rectitud que rige las
                  acciones, conductas y decisiones de los miembros de la
                  comunidad institucional. Se propicia que cada integrante de
                  nuestra institución actúe con coherencia, inspirado en la
                  verdad, la transparencia, la rectitud e imparcialidad,
                  garantizando los derechos de las personas y el bien común. En
                  IPLA tomamos decisiones con rectitud, honradez, asumiendo las
                  consecuencias de ello.
                </p>
                <p className="justificado">
                  <span className="fw-bolder">Tolerancia: </span>En el IPLA
                  entendemos por tolerancia la capacidad de las personas de
                  convivir y relacionarse con los demás en donde se valore y
                  respete la diferencia como una condición natural de los seres
                  humanos. Aceptando a los demás sin importar su forma de ser,
                  actuar, pensar o sentir, aunque no lo compartamos. Desde la
                  tolerancia propiciamos en entendimiento entre todos los
                  integrantes de la comunidad educativa, buscando siempre una
                  convivencia sana y enriquecedora para todos. En el IPLA
                  respetamos las opiniones, ideas o actitudes de los demás,
                  aunque no coincidan con las propias.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MisionVisionValores;
