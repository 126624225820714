import React from "react";
import Dentimagen from "../assets/images/dentimagen.png";
import LosAndes from "../assets/images/los_andes.png";
import UniSantander from "../assets/images/uni_santander2.png";
import CalleLarga from "../assets/images/calle_larga.png";
import HotelPlaza from "../assets/images/hotel_plaza.png";
import SindicatoT from "../assets/images/sindicato_trabajadores.png";
import Kadmiel from "../assets/images/kadmiel.png";
import bci from "../assets/images/bci.png";
import Aconcagua from "../assets/images/aconcagua_news.png";
import Andescoop from "../assets/images/andescoop.png";
import AngloAmerica from "../assets/images/anglo_america.png";
import BanIgualdad from "../assets/images/banigualdad.png";
import { NavLink } from "react-router-dom";
import "../assets/css/asociados.css";

const Asociados = () => {
  return (
    <>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <dir className="display-6 fw-bolder">NUESTROS ASOCIADOS</dir>
            </div>
            <div className="row py-2">
              <p className="justificado">
                Parte de nuestros aportes al proceso de formación de nuestros
                estudiantes y bienestar para todos los colaboradores IPLA,
                considera la entrega de las herramientas necesarias en pro de su
                crecimiento integral. En este sentido, contamos con una red de
                empresas e instituciones asociadas que contribuyen al beneficio
                y bienestar con alcance al todo el núcleo familiar. A
                continuación, los diversos convenios a los cuales se puede
                aplicar:
              </p>
              <p>
                <small className="justificado fw-bolder">
                  NOTA: Los detalles de cada convenio podrás solicitarlos y
                  revisarlos en las oficinas de Secretaría Académica IPLA.
                </small>
              </p>
            </div>
            <div className="row py-2">
              <div className="col-md-4">
                <NavLink to="/red-asociados">
                  <button type="button" className="btn boton_personalizado">
                    Ver mas
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="logos">
                <div className="logos-slide">
                  <img src={LosAndes} alt="" />
                  <img src={Dentimagen} alt="" />
                  <img src={UniSantander} alt="" />
                  <img src={CalleLarga} alt="" />
                  <img src={HotelPlaza} alt="" />
                  <img src={SindicatoT} alt="" />
                  <img src={Kadmiel} alt="" />
                  <img src={bci} alt="" />
                  <img src={Aconcagua} alt="" />
                  <img src={Andescoop} alt="" />
                  <img src={AngloAmerica} alt="" />
                  <img src={BanIgualdad} alt="" />
                </div>
                <div className="logos-slide">
                  <img src={LosAndes} alt="" />
                  <img src={Dentimagen} alt="" />
                  <img src={UniSantander} alt="" />
                  <img src={CalleLarga} alt="" />
                  <img src={HotelPlaza} alt="" />
                  <img src={SindicatoT} alt="" />
                  <img src={Kadmiel} alt="" />
                  <img src={bci} alt="" />
                  <img src={Aconcagua} alt="" />
                  <img src={Andescoop} alt="" />
                  <img src={AngloAmerica} alt="" />
                  <img src={BanIgualdad} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Asociados;
