import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Logo from "../assets/images/cierre_coyhaique.jpg";

const Testimonios = () => {
  const options = {
    margin: 10,
    responsiveClass: true,
    loop: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  const verInformacion = () => {
    window.open(
      "/data/COMUNICADO_N°006-2024_INFORMA_OFICIALMENTE_CIERRE_SEDE_COYHAIQUE.pdf",
      "_blank"
    );
  };

  return (
    <>
      <div className="row text-center py-5">
        <dir className="display-6 fw-bolder">TESTIMONIOS Y EVENTOS</dir>
      </div>

      <section>
        <div className="container">
          {/*<div className="col-md-4">
            <iframe
              width="600"
              height="500"
              src="https://www.youtube.com/embed/VWVEbI1_fio?si=TQ2o032BDpyVkWgs"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>*/}

          <div className="row">
            <OwlCarousel items={4} className="owl-theme" {...options}>
              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/HChLgHflqzA?si=36AaF_UVyJiR7gOS"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title fw-bolder titulo">
                    Aniversario Nº 42 IPLA
                  </h5>
                  <p class="card-text">
                    <small class="fw-bolder subtitulo">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid02VoaHF1TeuMznuz8kPpnrYDJQANgwY9gbzoKDb7LvQrGBPxUP8oXb6wmsqyZDVnRvl&show_text=true&width=500"
                  title="Video Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title fw-bolder titulo">
                    Cumpleaños de nuestro Rector
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="fw-bolder subtitulo">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid0esCn9fu6aWvygpJGiPN9HX8octXyaj3FkYH2Lz7WhdRKu99Cz1dSKV8AZw8XoMhcl&show_text=true&width=500"
                  title="Video Short"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title fw-bolder titulo">
                    Aniversario Nº 42 IPLA
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/VWVEbI1_fio?si=TQ2o032BDpyVkWgs"
                  title="Video Short"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Testimonios de Nuestros Alumnos
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cwFbxqFBHHU?si=YDYJE52ALwvLYV3S"
                  title="Video youtube"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Carrera TNS Prevención IPLA
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Pb15naem49Y?si=TbAJRgfvyeDKT-jP"
                  title="Video youtube"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Carreras IPLA 2024
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandess%2Fposts%2Fpfbid0ZrvWeyp8yR8ZPh4ENNst1n1oUK6Fyiih59v8kB8DzstDjHEBA2CucB2HhbkzhUjNl&show_text=true&width=500"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Ceremonia de Investidura
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  width="560"
                  height="315"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid02yxC5uXwfzFkP89963C2a78rbrKb3tQaYW3PXvSRePafgBS5ScSvQT7APa83cq5Azl&show_text=true&width=500&is_preview=true"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Feria de Emprendedores
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <img
                  className="card-img-top"
                  src={Logo}
                  width="560"
                  height="315"
                />
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Cierre Sede IPLA Coyhaique
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <span class="subtitulo fw-bolder">
                      Mas informacion click{" "}
                    </span>
                    <a
                      onClick={verInformacion}
                      className="fas fa-external-link-alt fa-xs titulo"
                    >
                      &nbsp;Aqui
                    </a>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandes%2Fposts%2Fpfbid0GeaE6ZSqa3mqpTpwHZGENVoo3hKCqzrYfkj3FHWFNSsXkimZCYBLWgArTF4Tred2l&show_text=true&width=500&is_preview=true"
                  width="560"
                  height="315"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    WEBINAR - Inscripciones Gratuitas
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>

              <div class="card h-100">
                <iframe
                  className="card-img-top"
                  src="https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Fiplalosandess%2Fvideos%2F1232329711092494%2F&show_text=false&width=476&t=0"
                  width="560"
                  height="315"
                  title="Noticia de Facebook"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="true"
                ></iframe>
                <div class="card-body">
                  <h5 class="card-title titulo fw-bolder">
                    Convenio JM / IPLA
                  </h5>
                  <p class="card-text"></p>
                  <p class="card-text">
                    <small class="subtitulo fw-bolder">
                      Last updated 3 mins ago
                    </small>
                  </p>
                </div>
              </div>
            </OwlCarousel>
          </div>
        </div>

        <div className="video-facebook"></div>
      </section>
    </>
  );
};

export default Testimonios;
